import ApiConstants from 'invision-core/src/api/constants';
import {ThunkHelper} from 'invision-core';

export const RETRIEVE_PRODUCTS_CONSTANTS = {
    BEGIN: 'RETRIEVE_PRODUCTS_BEGIN',
    SUCCESS: 'RETRIEVE_PRODUCTS_SUCCESS',
    FAILURE: 'RETRIEVE_PRODUCTS_FAILURE'
};

const RETRIEVE_PRODUCTS_EVENTS = [
    RETRIEVE_PRODUCTS_CONSTANTS.BEGIN,
    RETRIEVE_PRODUCTS_CONSTANTS.SUCCESS,
    RETRIEVE_PRODUCTS_CONSTANTS.FAILURE
];

export const retrieveProductsPromise = (dispatch, customerId, additionalOpts) => {
    const defaultData = {
        SortBy: 2,
        SortDirection: 2
    };
    const requestData = Object.assign(defaultData, additionalOpts);

    if (requestData && requestData.SearchString) {
        //Multiple hyphens will cause the service to throw a 500 error
        requestData.SearchString = requestData.SearchString.replace(/-+/g, '-');
    }

    return ThunkHelper(dispatch, RETRIEVE_PRODUCTS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SearchLocker',
        data: requestData,
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const retrieveProducts = (customerId, additionalOpts = {}) => {
    return (dispatch) => {
        return retrieveProductsPromise(dispatch, customerId, additionalOpts);
    };
};

export const SET_CURRENT_PAGE = 'SET_PRODUCTS_CURRENT_PAGE';
export const setCurrentPage = (page) => {
    return {
        type: SET_CURRENT_PAGE,
        payload: page
    };
};

export const SET_INCLUDED_EXPIRED_FILTER = 'SET_PRODUCTS_INCLUDED_EXPIRED_FILTER';
export const setIncludedExpiredFilter = (includedFilter) => {
    return {
        type: SET_INCLUDED_EXPIRED_FILTER,
        payload: includedFilter
    };
};

export const SET_INCLUDED_REMOVED_FILTER = 'SET_PRODUCTS_INCLUDED_REMOVED_FILTER';
export const setIncludedRemovedFilter = (includedFilter) => {
    return {
        type: SET_INCLUDED_REMOVED_FILTER,
        payload: includedFilter
    };
};

export const SET_INCLUDED_SEARCH_FILTER = 'SET_PRODUCTS_INCLUDE_SEARCH_FILTER';
export const setIncludedSearchFilter = (includedSearch) => {
    return {
        type: SET_INCLUDED_SEARCH_FILTER,
        payload: includedSearch
    };
};

export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';
export const setActiveProduct = (product) => {
    return {
        type: SET_ACTIVE_PRODUCT,
        payload: product
    };
};

export const SET_PRODUCTS_PAGE_SIZE_PREFERENCE = 'SET_PRODUCTS_PAGE_SIZE_PREFERENCE';
export const setPageSizePreference = (pageSize) => {
    return {
        type: SET_PRODUCTS_PAGE_SIZE_PREFERENCE,
        payload: pageSize
    };
};

export const SET_PRODUCTS_CLASSIFICATION = 'SET_PRODUCTS_CLASSIFICATION';
export const setProductsClassificationType = (selection) => {
    return {
        type: SET_PRODUCTS_CLASSIFICATION,
        payload: selection
    };
};

export const EMPTY_PRODUCTS_LIST = 'EMPTY_PRODUCTS_LIST';
export const emptyProductsList = () => {
    return {
        type: EMPTY_PRODUCTS_LIST
    };
};

export const REMOVE_PRODUCTS_FILTER = 'REMOVE_PRODUCTS_FILTER';
export const removeProductsFilter = () => {
    return {
        type: REMOVE_PRODUCTS_FILTER
    };
};

export const REMOVE_PRODUCTS_CONSTANTS = {
    BEGIN: 'REMOVE_PRODUCTS_BEGIN',
    SUCCESS: 'REMOVE_PRODUCTS_SUCCESS',
    FAILURE: 'REMOVE_PRODUCTS_FAILURE'
};

const REMOVE_PRODUCTS_EVENTS = [
    REMOVE_PRODUCTS_CONSTANTS.BEGIN,
    REMOVE_PRODUCTS_CONSTANTS.SUCCESS,
    REMOVE_PRODUCTS_CONSTANTS.FAILURE
];

export const removeProductsPromise = (dispatch, customerId, data) => {
    return ThunkHelper(dispatch, REMOVE_PRODUCTS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SubmitRemoveOrder',
        data: {
            LockerItemIds: data.ItemsToRemove,
            RemoveReasonCode: data.ReasonCode,
            SendNotification: data.SendNotification
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const removeAdHocProductsPromise = (dispatch, customerId, data, executionDate) => {
    return ThunkHelper(dispatch, REMOVE_PRODUCTS_EVENTS, {
        method: 'post',
        url: 'SubscriberManagement/SubmitChangeOfServiceOrder',
        data: {
            ExecutionDate: executionDate || undefined,
            RemoveItems: data.ItemsToRemove,
            RemoveReasonCode: data.ReasonCode,
            SendNotification: data.SendNotification
        },
        headers: {
            [ApiConstants.SUBSCRIBER_ID_HEADER]: customerId
        }
    });
};

export const removeProducts = (customerId, data, isAdHocProduct, executionDate) => {
    return (dispatch) => {
        return (isAdHocProduct) ? removeAdHocProductsPromise(dispatch, customerId, data, executionDate) : removeProductsPromise(dispatch, customerId, data);
    };
};

export const SET_PRODUCTS_LIST_SORT_CRITERIA = 'SET_PRODUCTS_LIST_SORT_CRITERIA';
export const setSortCriteria = (sortCriteria) => {
    return {
        type: SET_PRODUCTS_LIST_SORT_CRITERIA,
        payload: sortCriteria
    };
};

export const SET_REMOVE_PRODUCT_EXECUTION_OPTIONS = 'SET_REMOVE_PRODUCT_EXECUTION_OPTIONS';
export const setRemoveProductExecutionOptions = (executionOptions) => {
    return {
        type: SET_REMOVE_PRODUCT_EXECUTION_OPTIONS,
        payload: executionOptions
    };
};
