import pathOr from 'ramda/src/pathOr';
import compose from 'ramda/src/compose';
import flatten from 'ramda/src/flatten';
import pluck from 'ramda/src/pluck';
import {createSelector} from 'reselect';

import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {OFFER_BEHAVIOR_CONSTANTS} from 'invision-core/src/constants/offer.constants';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import Configurable from 'invision-core/src/components/configurable/configurable';
import {
    IsOfferFeatureEnabled,
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeIntegerSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    IsOfferingMetadataLoadingSelector,
    OfferingMetadataPricingPlansSelector
} from 'invision-core/src/components/metadata/offerings/offerings.selectors';

import {
    CombinedEntitlementTypesSelector,
    ConvergentBillerAccountOfferingsForSelectedAccountSelector,
    ConvergentBillerSubscriberSummarySelector,
    CurrentAccountServicesSelector,
    CurrentAccountSummarySelector,
    IsCurrentAccountPostpaidSelector,
    IsFetchingAccountDetailsSelector,
    IsFetchingSubscriberSummarySelector
} from './customer.convergent.biller.selectors';
import * as ProductsSelectors from '../../reducers/selectors/customer.products.selectors';
import * as SubscriptionsSelectors from '../../reducers/selectors/customer.subscriptions.selectors';
import * as AccountStatusSelectors from './customer.account.status.selectors';
import {IsFetchingInvoicesSelector} from './customer.cases.selectors';
import {
    CustomerTemplateSelector,
    CustomerTemplateWidgetSelector
} from './customer.actions.template.selectors';
import {getFriendlyName} from './billing.payments.invoice.selectors.helper';
import {NA} from '../constants/billing.payments.constants';
import {TREATMENT_STATUS} from '../constants/convergent.biller.constants';
import {
    IsFetchingDeviceFinancingDetailsSelector,
    SubscriberDeviceFinancingDetailsSelector,
} from './customer.devices.selectors';
import {
    filterBulkOfferOptions,
    getOptionsByActiveBriType,
    hasPendingChange as hasOfferPendingChanges,
    isPaymentInstrumentChangeable
} from '../../components/customer/dashboard/dbssDashboard/offering/offering.component.helper';
import {BILLER_RULE_INSTANCE_TYPE} from '../../customercare.constants';
import {
    ChangeOfferEligibilityRulesSelector,
    IsCoolingPeriodEnabledSelector,
} from './customer.offerings.selectors';
import {getMoreMenuItems} from './dashboard.selectors.helper';
import {getUniqueNonGuidServiceIdentifiers} from '../helpers/add.offer.wizard.selectors.helpers';
import {IMMUTABLE_EMPTY_ARRAY} from '../constants/common.constants';
import {IsRenewingOffCycleChargesSelector} from './customer.ewallet.selectors';
import {OFFERING_STATUS_TYPES} from '../../components/customer/offerings/offerings.constants';

const EMPTY_ARRAY = [];
export const AccountOfferingsSelector = createSelector(
    [
        ConvergentBillerAccountOfferingsForSelectedAccountSelector,
        IsCurrentAccountPostpaidSelector,
        MetadataCodeTypeIntegerSelector(CODES.TransitionConfiguration),
        ChangeOfferEligibilityRulesSelector,
        IsCoolingPeriodEnabledSelector,
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        SubscriberDeviceFinancingDetailsSelector,
        OfferingMetadataPricingPlansSelector
    ],
    (dbssOfferings, isCurrentAccountPostpaid, transitionConfiguration, changeOfferEligibilityRules, isCoolingPeriodEnabledForBU, regularExpressionCodeTable, serviceAttributeCodeTable, deviceFinancingDetails, pricingPlanThumbnails) => {
        const dbssOffers = dbssOfferings && (dbssOfferings || []).length ? dbssOfferings.filter((offer) => {
            return !(offer.Status === OFFERING_STATUS_TYPES.REMOVED && !offer.IsCoolingOff);
        }).map((offering) => {
            const serviceAttributes = compose(flatten, pluck('ServiceAttributeValues'))((offering.Options || EMPTY_ARRAY).filter((option) => {
                return option.Status !== OFFERING_STATUS_TYPES.REMOVED;
            }));
            const financingDeviceServiceIdentifiers = deviceFinancingDetails.filter((details) => {
                //PD-523770 show icon only when the financing has not been paid off
                return details.FinanceBalance > 0;
            }).map((details) => {
                return (details.ServiceIdentifier || {}).Value;
            });
            return Object.assign({}, offering, {
                serviceIdentifiers: getUniqueNonGuidServiceIdentifiers(serviceAttributes, serviceAttributeCodeTable, regularExpressionCodeTable),
                isFinancing: (offering.Options || []).some((option) => {
                    return option.ServiceIdentifierValue && financingDeviceServiceIdentifiers.includes(option.ServiceIdentifierValue);
                })
            });
        }) : EMPTY_ARRAY;

        const accountOfferings = dbssOffers || EMPTY_ARRAY;
        const transitionProperties = pathOr(null, ['0', 'AdditionalProperties'], transitionConfiguration, false);
        const transitionFlagFromCodeTable = transitionProperties && transitionProperties.find((item) => {
            return item.Key === 'AllowTransitioning';
        });

        const allowTransitioning = getBoolOrDefault(transitionFlagFromCodeTable ? transitionFlagFromCodeTable.Value: undefined, false);

        return accountOfferings.map((offer) => {
            const briOptions = getOptionsByActiveBriType(offer, PRODUCT_CLASSIFICATIONS.PRODUCT);
            const hasSubscriptions = briOptions[BILLER_RULE_INSTANCE_TYPE.SUBSCRIPTION].length;
            const bulkOffer = filterBulkOfferOptions(offer);
            const canChangePaymentInstrument = isPaymentInstrumentChangeable(offer);
            const isInGracePeriod = offer.Options.some((option) => {
                return option.OffCycleDetail &&
                    option.OffCycleDetail.GracePeriodExpiryDate &&
                    option.OffCycleDetail.CurrentRenewalFailedAttempts;
            });
            return Object.assign({}, offer, {
                moreOptionsMenuItems: getMoreMenuItems(hasSubscriptions, allowTransitioning, offer, bulkOffer, changeOfferEligibilityRules, canChangePaymentInstrument, isInGracePeriod, false, pricingPlanThumbnails, false)
            });
        });
    }
);

export const ShowAccountBalanceWidgetSelector = createSelector(
    [
        AccountOfferingsSelector,
        CurrentAccountSummarySelector,
        IsCurrentAccountPostpaidSelector,
        IsFetchingSubscriberSummarySelector,
        IsFetchingInvoicesSelector
    ],
    (accountOfferings, currentAccountSummary, isCurrentAccountPostpaid, isFetchingSubscriberSummary, isFetchingInvoices) => {
        return !isFetchingSubscriberSummary && !isFetchingInvoices &&
            (!(currentAccountSummary && currentAccountSummary.PrepaidDetails) || // account is not prepaid
            (isCurrentAccountPostpaid || (accountOfferings && accountOfferings.length > 0)));
    }
);

export const ShowTreatmentWidgetSelector = createSelector(
    [
        ShowAccountBalanceWidgetSelector,
        ConvergentBillerSubscriberSummarySelector
    ],
    (showAccountBalanceWidget, subscriberSummary) => {
        const treatmentStatus = pathOr(TREATMENT_STATUS.NOT_IN_TREATMENT, ['TreatmentStatus'], subscriberSummary);
        return showAccountBalanceWidget &&
            treatmentStatus !== TREATMENT_STATUS.NOT_IN_TREATMENT;
    }
);

export const IsLoadingOfferingWidgetsSelector = createSelector(
    [
        IsFetchingAccountDetailsSelector,
        IsOfferingMetadataLoadingSelector
    ],
    (isFetchingAccountDetails, isOfferingMetadataLoadingSelector) => {
        return isFetchingAccountDetails || isOfferingMetadataLoadingSelector;
    }
);

export const ShowOfferingDeprecatedWidgetsSelector = createSelector(
    [
        IsLoadingOfferingWidgetsSelector,
        IsDbss,
        IsOfferFeatureEnabled,
        AccountOfferingsSelector,
    ],
    (isLoadingOfferingWidgets, businessUnitIsDbss, isItvOffersEnabled, accountOfferings) => {
        return !isLoadingOfferingWidgets &&
            (businessUnitIsDbss || isItvOffersEnabled) &&
            accountOfferings && accountOfferings.length;
    }
);

export const ShowServicesDeprecatedWidgetSelector = createSelector(
    [
        MetadataCodeLoadedSelector(CODES.UnitsOfMeasure),
        IsFetchingSubscriberSummarySelector,
        CombinedEntitlementTypesSelector,
        CustomerTemplateSelector
    ],
    (unitsOfMeasureLoaded, fetchingSubscriberSummary, combinedEntitlementTypes, template) => {
        const isServicesWidgetEnabledInConfiguration = (widget) => {
            return Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS.SERVICES_DEPRECATED === widget;
        };

        const enabledInConfiguration = template && template.widgets && template.widgets.some(isServicesWidgetEnabledInConfiguration);
        return Boolean(
            enabledInConfiguration &&
            unitsOfMeasureLoaded && !fetchingSubscriberSummary &&
            combinedEntitlementTypes.some((service) => {
                return (service && service.ServiceIdentifier && getFriendlyName(service.ServiceIdentifier) !== NA)
                // Shared Entitlements
                        || (service && !service.ServiceIdentifier && service.EntitlementBalances.length);
            })
        );
    }
);

export const ShowServicesWidgetSelector = createImmutableSelector(
    [CustomerTemplateSelector],
    (template) => {
        const isServicesWidgetEnabledInConfiguration = (widget) => {
            return Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS.SERVICES === widget;
        };

        return template && template.widgets && template.widgets.some(isServicesWidgetEnabledInConfiguration);
    }
);

export const ShowOfferingsWidgetSelector = createImmutableSelector(
    [CustomerTemplateSelector],
    (template) => {
        const isOfferingsWidgetEnabledInConfiguration = (widget) => {
            return Configurable.CustomerCare.CUSTOMER_SYSTEM_WIDGET_IDS.OFFERINGS === widget;
        };

        return template && template.widgets && template.widgets.some(isOfferingsWidgetEnabledInConfiguration);
    }
);

export const ShowProductsWidgetSelector = createSelector(
    [
        ProductsSelectors.IsFetchingProductsDataSelector,
        ProductsSelectors.CustomerProductsCountSelector
    ],
    (isFetchingProducts, productsCount) => {
        return !isFetchingProducts && productsCount;
    }
);

export const ShowSubscriptionsWidgetSelector = createSelector(
    [
        SubscriptionsSelectors.IsFetchingDataSelector,
        MetadataCodeLoadedSelector(CODES.FeatureConfiguration),
        IsOfferFeatureEnabled,
        SubscriptionsSelectors.RecordCountSelector
    ],
    (isFetchingCustomerSubscriptions, featureConfigurationsLoaded, isItvOffersEnabled, subscriptionsCount) => {
        return !isFetchingCustomerSubscriptions && featureConfigurationsLoaded &&
            !isItvOffersEnabled && subscriptionsCount;
    }
);

export const ShowAccountStatusWidgetSelector = createSelector(
    [
        AccountStatusSelectors.IsFetchingAccountStatusDataSelector
    ],
    (isFetchingAccountStatusData) => {
        return !isFetchingAccountStatusData;
    }
);

export const ShowActiveProductsWidgetSelector = createSelector(
    [
        ProductsSelectors.IsFetchingActiveProductsSelector,
        ProductsSelectors.CustomerActiveProductsSelector
    ],
    (isFetchingActiveProducts, activeProducts) => {
        return !isFetchingActiveProducts && activeProducts && activeProducts.length > 0;
    }
);

export const ShowDeviceFinancingWidgetSelector = createSelector(
    [
        IsFetchingDeviceFinancingDetailsSelector,
        SubscriberDeviceFinancingDetailsSelector
    ],
    (isFetchingDetails, deviceFinanceDetails) => {
        return !isFetchingDetails && deviceFinanceDetails && deviceFinanceDetails.length > 0;
    }
);

export const VisibleWidgetsSelector = createSelector(
    [
        CustomerTemplateWidgetSelector,
        ShowAccountBalanceWidgetSelector,
        ShowOfferingDeprecatedWidgetsSelector,
        ShowServicesWidgetSelector,
        ShowProductsWidgetSelector,
        ShowSubscriptionsWidgetSelector,
        ShowAccountStatusWidgetSelector,
        ShowActiveProductsWidgetSelector
    ],
    (widgets, showAccountBalanceWidget, showOfferingWidgets, showServicesWidget, showProductsWidget, showSubscriptionsWidget, showAccountStatusWidget, showActiveProductsWidget) => {
        return widgets.filter((widget) => {
            if (widget.displayAccountBalanceTreatment) {
                return showAccountBalanceWidget;
            } else if (widget.displayOffering) {
                return showOfferingWidgets;
            } else if (widget.displayServices) {
                return showServicesWidget;
            } else if (widget.displayProducts) {
                return showProductsWidget;
            } else if (widget.displaySubscriptions) {
                return showSubscriptionsWidget;
            } else if (widget.displayAccountStatus) {
                return showAccountStatusWidget;
            } else if (widget.displayActiveProducts) {
                return showActiveProductsWidget;
            } else if (widget.displayRecommendationWidget) {
                return true;
            }
            return true;
        });
    }
);

export const IsFetchingWidgetDataSelector = createSelector(
    [
        IsFetchingSubscriberSummarySelector,
        IsFetchingInvoicesSelector,
        IsLoadingOfferingWidgetsSelector,
        ProductsSelectors.IsFetchingProductsDataSelector,
        SubscriptionsSelectors.IsFetchingDataSelector,
        AccountStatusSelectors.IsFetchingAccountStatusDataSelector,
        MetadataCodeLoadedSelector(CODES.BusinessUnitTemplate),
        IsRenewingOffCycleChargesSelector
    ],
    (isFetchingSubscriberSummary, isFetchingInvoices, isLoadingOfferingWidgets, isFetchingProductsData, isFetchingCustomerSubscriptions, isFetchingAccountStatusData, isBusinessUnitTemplateLoaded, isRenewingOffCycleCharges) => {
        return isFetchingSubscriberSummary ||
            isFetchingInvoices ||
            isLoadingOfferingWidgets ||
            isFetchingProductsData ||
            isFetchingCustomerSubscriptions ||
            isFetchingAccountStatusData ||
            !isBusinessUnitTemplateLoaded ||
            isRenewingOffCycleCharges;
    }
);

export const IsCurrentAccountServicesEmpty = createSelector(
    [
        ConvergentBillerAccountOfferingsForSelectedAccountSelector,
        CurrentAccountServicesSelector
    ],
    (convergentBillerAccountOfferings, currentAccountServices) => {
        return 0 === currentAccountServices.length
            || !(convergentBillerAccountOfferings
                && convergentBillerAccountOfferings.length);
    }
);

export const IsAutoPayConfigured = createSelector(
    [
        CurrentAccountSummarySelector
    ],
    (currentAccountSummary) => {
        return currentAccountSummary && currentAccountSummary.AutoPayConfigured;
    }
);

export const ShowAutoPaySelector = createSelector(
    [
        IsAutoPayConfigured,
        IsCurrentAccountServicesEmpty
    ],
    (isAutoPayConfigured, isServiceEmpty) => {
        return !isServiceEmpty || !!isAutoPayConfigured;
    }
);

export const OfferEligibilityIdsSelector = createImmutableSelector(
    [
        ConvergentBillerAccountOfferingsForSelectedAccountSelector
    ],
    (dbssOfferings) => {
        return (dbssOfferings || IMMUTABLE_EMPTY_ARRAY).filter((offer) => {
            return offer.Status === OFFER_STATUS_INDICATOR_STATUS.ACTIVE
                        && offer.OfferEligibility
                        && offer.OfferEligibility.OfferBehaviorId === OFFER_BEHAVIOR_CONSTANTS.SELECT_ELIGIBILITY_RULE;
        }).map((offer) => {
            return offer.OfferingInstanceId;
        });
    }
);

export const IsInGracePeriodSelector = createImmutableSelector(
    [AccountOfferingsSelector],
    (accountOfferings) => {
        return accountOfferings.some((offering) => {
            return offering.Status === OFFER_STATUS_INDICATOR_STATUS.ACTIVE &&
                offering.Options &&
                offering.Options.some((option) => {
                    return option.OffCycleDetail &&
                        option.OffCycleDetail.GracePeriodExpiryDate &&
                        option.OffCycleDetail.CurrentRenewalFailedAttempts;
                });
        });
    }
);
