import CustomerCareKeys from '../../locales/keys';

export const ALL_STEPS_KEYS = [
    CustomerCareKeys.WIZARD.STEPS.DECISIONS,
    CustomerCareKeys.WIZARD.STEPS.ATTRIBUTES,
    CustomerCareKeys.WIZARD.STEPS.CHECKOUT,
];

export const STATE_OR_NAME = 'index.customercare.customer.orders.editoffer';
export const RECONNECT_OFFER_STATE_NAME = 'index.customercare.customer.orders.reconnectOffer';
