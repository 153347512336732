import {createSelector} from 'reselect';
import {any, append, isEmpty, isNil, find, map, path, pathOr, pipe, pluck, uniq} from 'ramda';
import {
    BoolHelper,
    i18n,
    MetadataConstants,
    MetadataSelectors,
    PermissionService,
    SessionSelectors
} from 'invision-core';
import {
    EMPTY_ARRAY,
    RouteParams,
    SelectedCustomerSelector
} from './customer.selectors';
import {
    CurrentActiveAddressesSelector
} from './customer.addresses.selectors';
import {
    ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT,
    ORDERING_ACCESS,
    SUBSCRIBER_PRODUCT_REMOVE_DISCOUNT_ACCESS,
    SUBSCRIPTION_ACCESS
} from '../../security.attributes';
import LocaleKeys from './../../locales/keys';
import {GENERAL_STATUSES} from '../../components/shared/constants/general.status.constants';
import {ORDER_STATUSES} from '../../components/shared/constants/order.status.constants';
import * as SubscriptionConstants from '../../components/shared/constants/subscriptions.constants';
import {
    constructPricingPlanDictionary,
    doesCurrentSubscriptionHaveItems,
    setSubscriptionData
} from '../helpers/customer.subscriptions.helper';
import {
    hasBulkOfferOptions,
    hasPendingChange
} from '../../components/customer/dashboard/dbssDashboard/offering/offering.component.helper';
import {
    DASHBOARD_ROUTE,
    SUBSCRIPTION_LIST_ROUTE
} from '../constants/dashboard.constants';

const recoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.subscriptions;
};

export const CurrentSubscriptionIdSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.subscriptionId;
    }
);

export const CurrentSubscriptionItemExtensionIdSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.extensionId;
    }
);

export const CurrentOfferIdSelector = createSelector(
    [RouteParams],
    (params) => {
        return params.offerId;
    }
);

// holds the id of individual subscription item from an aligned subscription being changed in modify, remove, & replace flows
export const ChildSubscriptionIdSelector = createSelector(
    [RouteParams],
    (params) => {
        const result = params.childSubscriptionId;

        if (result === '') {
            return null;
        }

        return result;
    }
);

const CurrentSubscription = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.currentSubscription;
    }
);

export const PendingOrderSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.pendingOrder;
    }
);

export const IsRetrievingPendingOrderSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isRetrievingPendingOrder;
    }
);

export const CurrentSubscriptionOfferingDetailsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.currentSubscriptionOffering.details;
    }
);

export const IsCurrentSubscriptionOfferingPendingSelector = createSelector(
    [CurrentSubscriptionOfferingDetailsSelector],
    (selectedSubscriptionOffering) => {
        return hasPendingChange(selectedSubscriptionOffering);
    }
);

export const CurrentRemovalSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.currentRemoval;
    }
);

export const CurrentSubscriptionIdFromStoreSelector = createSelector(
    [CurrentSubscription],
    (currentSubscription) => {
        return pathOr(null, ['Id'], currentSubscription);
    }
);

export const CurrentSubscriptionSelector = createSelector(
    [
        CurrentSubscription,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriberProductPricingPlanChangeSource),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriptionBillingRenewalType),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriptionStateIndicator)
    ],
    (currentSubscription, pricingPlanChangeSources, renewalTypes, subscriptionStateIndicators) => {
        return setSubscriptionData(currentSubscription, pricingPlanChangeSources, renewalTypes, subscriptionStateIndicators);
    }
);

export const RemoveChildSubscriptionSelector = createSelector(
    [
        ChildSubscriptionIdSelector,
        CurrentSubscription,
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriberProductPricingPlanChangeSource),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriptionBillingRenewalType),
        MetadataSelectors.codes.MetadataCodeTypeSelector(MetadataConstants.codes.SubscriptionStateIndicator)
    ],
    (childId, currentSubscription, pricingPlanChangeSources, renewalTypes, subscriptionStateIndicators) => {
        if (isNil(childId)) {
            return null;
        }

        const targetSubscription = isNil(currentSubscription)
            ? null
            : find((item) => {
                return item.Id === childId && item.Id !== currentSubscription.Id;
            },
            currentSubscription.Items);

        const result = isNil(targetSubscription)
            ? null
            : setSubscriptionData(targetSubscription, pricingPlanChangeSources, renewalTypes, subscriptionStateIndicators);

        return result;
    }
);

export const ShowShippingInfoSelector = createSelector(
    [CurrentSubscriptionSelector],
    (currentSubscription) => {
        let showShippingInfo = false;

        if (currentSubscription && currentSubscription.Items) {
            showShippingInfo = currentSubscription.Items.some((item) => {
                return item.Shipped || (item.Children && item.Children.some((childItem) => {
                    return childItem.Shipped;
                }));
            });
        }

        return showShippingInfo;
    }
);

export const IsFetchingDataSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isFetchingData;
    }
);

export const IsFetchingSubscriptionDetailsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isFetchingDetails;
    }
);

export const IsRemovingDiscountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isRemovingDiscount;
    }
);

export const IsRemovingSubscriptionSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isRemovingSubscription;
    }
);

const IsSubscriptionActiveSelector = createSelector(
    [CurrentSubscriptionSelector],
    (currentSubscription) => {
        return currentSubscription && currentSubscription.Status === GENERAL_STATUSES.ACTIVE;
    }
);

const IsSubscriptionSuspendedSelector = createSelector(
    [CurrentSubscriptionSelector],
    (currentSubscription) => {
        return currentSubscription && currentSubscription.Status === GENERAL_STATUSES.SUSPENDED;
    }
);

export const IsUpdatingSubscriptionSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isUpdating;
    }
);

export const IsValidatingCouponSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.isValidatingCoupon;
    }
);

export const PageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageNumber ? uiState.pageNumber : 0;
    }
);

export const SelectedPageSizePreference = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.pageSizePreference;
    }
);

export const IncludeRemovedSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.filterData.includeRemoved;
    }
);

export const RecordCountSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.recordCount ? selectedCustomer.subscriptions.recordCount : 0;
    }
);

export const EndRecordSelector = createSelector(
    [PageNumberSelector, SessionSelectors.PageSizePreferenceSelector, RecordCountSelector],
    (pageNumber, pageSize, recordCount) => {
        const endNumber = (pageNumber * pageSize);

        if (endNumber < recordCount) {
            return endNumber;
        }

        return recordCount;
    }
);

export const SelectedDiscountSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.selectedDiscount.asMutable({
            deep: true
        });
    }
);

export const StartRecordSelector = createSelector(
    [PageNumberSelector, SessionSelectors.PageSizePreferenceSelector, RecordCountSelector],
    (pageNumber, pageSize, recordCount) => {
        if (recordCount <= 0) {
            return 0;
        }

        return ((pageNumber - 1) * pageSize) + 1;
    }
);

export const SubscriptionErrorSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.subscriptionsError;
    }
);

const DEFAULT_ERRORS = [];
export const SubscriptionFormErrorSelector = createSelector(
    [SubscriptionErrorSelector],
    (subscriptionError) => {
        return subscriptionError && subscriptionError.message ? [subscriptionError.message] : DEFAULT_ERRORS;
    }
);

export const SubscriptionsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.data;
    }
);

const createBriefSubscriptionViewModels = (subscription) => {
    const firstSubscriptionItem = subscription.Items ? subscription.Items[0] : {};
    return {
        id: subscription.Id,
        isAligned: (subscription.Items && subscription.Items.length > 1) ? true : false,
        items: subscription.Items,
        name: (subscription.Items && subscription.Items.length > 1) ? `${subscription.PaymentInstrumentTypeName} - ${subscription.PaymentInstrumentName}` : pathOr('', ['Product', 'Name'], firstSubscriptionItem),
        numberItems: subscription.Items ? subscription.Items.length : 0,
        paymentInstrument: {
            name: subscription.PaymentInstrumentName,
            typeName: subscription.PaymentInstrumentTypeName
        },
        pricingPlan: pathOr({}, ['PricingPlan'], firstSubscriptionItem),
        renewal: {
            amount: subscription.RenewAmount,
            billingCycleName: pathOr('', ['PricingPlan', 'SubscriptionBillingCycleName'], firstSubscriptionItem),
            reminderDate: subscription.ReminderDate,
            date: subscription.CycleDate,
            type: i18n.translate(subscription.Type === SubscriptionConstants.SUBSCRIPTION_TYPE.MANUAL ?
                LocaleKeys.SUBSCRIPTIONS.MANUAL_RENEWAL : LocaleKeys.SUBSCRIPTIONS.AUTOMATIC_RENEWAL)
        },
        status: subscription.Status
    };
};


const DEFAULT_BRIEF_SUBSCRIPTIONS = [];
export const BriefSubscriptionsSelector = createSelector(
    [SubscriptionsSelector],
    (subscriptions) => {
        if (subscriptions) {
            return subscriptions.map(createBriefSubscriptionViewModels);
        }
        return DEFAULT_BRIEF_SUBSCRIPTIONS;
    }
);

export const SubscriberHasAnyAlignedSubscriptionsSelector = createSelector(
    [SubscriptionsSelector],
    (subscriptions) => {
        return any((sub) => {
            return sub.Items && sub.Items.length > 1;
        }, subscriptions || []);
    }
);

const getPaymentInstrumentName = (subscription) => {
    return subscription.PaymentInstrumentName;
};
export const SubscriptionsPaymentInstruments = createSelector(
    [SubscriptionsSelector],
    (subscriptions) => {
        return subscriptions ?
            pipe(
                map(getPaymentInstrumentName),
                uniq
            )(subscriptions)
            : [];
    }
);

const DEFAULT_TABLE_DATA = [];
export const TableDataSelector = createSelector(
    [SubscriptionsSelector],
    (subscriptions) => {
        if (subscriptions) {
            const translatedYes = i18n.translate(LocaleKeys.YES);
            const translatedNo = i18n.translate(LocaleKeys.NO);

            return subscriptions.map((subscription) => {
                const renewEval = subscription.Renew;
                return subscription.set('Renew', renewEval ? translatedYes : translatedNo)
                    .set('productNames', subscription.Items.map((item) => {
                        return `${item.Product.Name} - ${item.PricingPlan.Name}`;
                    }).join(', '));
            });
        }

        return DEFAULT_TABLE_DATA;
    }
);

export const TotalPagesSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.pageCount;
    }
);

export const RemoveSubscriptionNameSelector = createSelector(
    [RemoveChildSubscriptionSelector, CurrentSubscriptionSelector],
    (childSubscription, subscription) => {
        if (childSubscription) {
            return `${childSubscription.Product.Name} - ${childSubscription.PricingPlan.Name}`;
        }

        return subscription && subscription.Items ? subscription.Items.map((item) => {
            return `${item.Product.Name} - ${item.PricingPlan.Name}`;
        }).join(', ') : '';
    }
);

export const CurrentSubscriptionHasTerminationFee = createSelector(
    [CurrentSubscriptionSelector],
    (subscription) => {
        return subscription && subscription.TerminationFee ? (Number(subscription.TerminationFee) > 0) : false;
    }
);

export const CurrentSubscriptionHasLateFeeSelector = createSelector(
    [CurrentSubscriptionSelector],
    (subscription) => {
        return subscription && subscription.LateFee ? (Number(subscription.LateFee) > 0) : false;
    }
);

const SubscriptionOrderStatusSelector = createSelector(
    [CurrentSubscriptionSelector],
    (subscription) => {
        return subscription && subscription.OrderStatus;
    }
);

const DEFAULT_FIRST_ITEM = {};
const CurrentSubscriptionFirstItem = createSelector(
    [CurrentSubscriptionSelector],
    (subscription) => {
        return subscription && subscription.Items ? subscription.Items[0] : DEFAULT_FIRST_ITEM;
    }
);

export const EditSubscriptionItemIdSelector = createSelector(
    [SelectedCustomerSelector, ChildSubscriptionIdSelector],
    (selectedCustomer, childSubscriptionIdFromRoute) => {
        return childSubscriptionIdFromRoute ? childSubscriptionIdFromRoute : selectedCustomer.subscriptions.editSubscriptionItemId;
    }
);

export const EditSubscriptionItemSelector = createSelector(
    [EditSubscriptionItemIdSelector, CurrentSubscriptionSelector],
    // This returns the original of the current subsc that is being edited
    //  to get this subsc with its current (edited) changes use ModifiedSubscriptionItemSelector
    (editItemId, subscription) => {
        if (isNil(subscription) || isNil(subscription.Items)) {
            return DEFAULT_FIRST_ITEM;
        }

        return editItemId
            ? find((item) => {
                return item.Id === editItemId;
            }, subscription.Items) || DEFAULT_FIRST_ITEM
            : subscription.Items[0];
    }
);

export const InstancePropertySelector = createSelector(
    [EditSubscriptionItemSelector],
    (subscription) => {
        return subscription.InstanceProperties;
    }
);

export const OrderNumberSelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        return firstItem.OrderNumber;
    }
);

export const OrderIdSelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        return firstItem.OrderId;
    }
);

export const CurrentSubscriptionPricingPlanSelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        return pathOr({}, ['PricingPlan'], firstItem);
    }
);

export const CurrentSubscriptionProductSelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        if (firstItem && firstItem.Product) {
            return firstItem.Product;
        }

        return {};
    }
);

export const CurrencySelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        return pathOr('', ['PricingPlan', 'Currency'], firstItem);
    }
);

export const BrandableCurrencyNameSelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        return pathOr('', ['PricingPlan', 'BrandableCurrencyName'], firstItem);
    }
);

export const BillingCycleSelector = createSelector(
    [CurrentSubscriptionFirstItem],
    (firstItem) => {
        return pathOr('', ['PricingPlan', 'SubscriptionBillingCycleName'], firstItem);
    }
);

const SubscriptionShippingInformation = createSelector(
    [CurrentSubscriptionSelector],
    (currentSubscription) => {
        if (!currentSubscription || !currentSubscription.Items || !currentSubscription.Items.length) {
            return null;
        }

        // use shipping information from first item that has it
        // currently we only support all aligned subscription items having the same address
        return pathOr(null, ['ShippingInformation'], currentSubscription.Items.find((item) => {
            return item.ShippingInformation && !isEmpty(item.ShippingInformation);
        }));
    }
);

export const ShippingAddressSelector = createSelector(
    [SubscriptionShippingInformation],
    (shippingInfo) => {
        return pathOr(null, ['Address'], shippingInfo);
    }
);

export const ShippingMethodNameSelector = createSelector(
    [SubscriptionShippingInformation,
        MetadataSelectors.shippingMethods.ShippingMethodsSelector],
    (shippingInfo, shippingMethodsMetadata) => {
        const shippingMethodId = pathOr(null, ['ShippingMethodId'], shippingInfo);

        if (!shippingMethodsMetadata || !shippingMethodsMetadata.length || !shippingMethodId) {
            return null;
        }

        const shippingMethodConfig = shippingMethodsMetadata.find((method) => {
            return method.Id === shippingMethodId;
        });

        return shippingMethodConfig ? shippingMethodConfig.Name : null;
    }
);

export const ActiveAddressesWithCurrentSubscriptionAddressSelector = createSelector(
    [CurrentActiveAddressesSelector, ShippingAddressSelector],
    (subscriberActiveAddresses, subscriptionShippingAddress) => {
        const activeAddressMatchesIdAndName = subscriberActiveAddresses.find((addr) => {
            return subscriptionShippingAddress && (addr.Id === subscriptionShippingAddress.Id) &&
                (addr.ShipToName === subscriptionShippingAddress.ShipToName);
        });

        const retVal = subscriptionShippingAddress && !activeAddressMatchesIdAndName ? [subscriptionShippingAddress] : [];
        return retVal.concat(subscriberActiveAddresses);
    }
);

// Created to replace ShippingAddressSelector as a workaround
// Because the API is not updating the ShipToName field PD-485248
// Should be used again once the endpoint SubscriberManagement/UpdateSubscription gets fixed
export const ShippingAddressSelectorForSubscriptions = createSelector(
    CurrentActiveAddressesSelector,
    SubscriptionShippingInformation,
    ShippingAddressSelector,
    (addresses, shippingInfo, shippingAddress) => {

        const shippingAddressWithInfo = (addresses || EMPTY_ARRAY).find((addr) => {
            return shippingAddress && shippingAddress.Id === addr.Id;
        });

        const address = pathOr(null, ['Address'], shippingInfo);
        return address && shippingAddressWithInfo
            ? address.set('ShipToName', pathOr(null, ['ShipToName'], shippingAddressWithInfo) )
            : address;
    }
);

export const RenewalUnderEditSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.renewalUnderEdit.asMutable();
    }
);

export const SubscriptionProductIdSelector = createSelector(
    [EditSubscriptionItemSelector],
    (subscription) => {
        return path(['Product', 'Id'], subscription);
    }
);

export const SubscriptionPricingPlanIdSelector = createSelector(
    [EditSubscriptionItemSelector],
    (subscription) => {
        return path(['PricingPlan', 'Id'], subscription);
    }
);

export const SubscriptionCouponCodeSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.couponCode;
    }
);

export const UserHasOrderingPermissionSelector = createSelector(
    [SessionSelectors.UserSecurityAttributesSelector],
    (userSecurityAttributes) => {
        return PermissionService.hasAdminAccess(userSecurityAttributes, ORDERING_ACCESS);
    }
);

export const UserCanRemoveDiscountSelector = createSelector(
    [SessionSelectors.UserSecurityAttributesSelector],
    (userSecurityAttributes) => {
        return PermissionService.hasAccess(userSecurityAttributes, SUBSCRIBER_PRODUCT_REMOVE_DISCOUNT_ACCESS);
    }
);

export const UserCanIssueCreditToAlternatePaymentInstrument = createSelector(
    [SessionSelectors.UserSecurityAttributesSelector],
    (userSecurityAttributes) => {
        return PermissionService.hasAccess(userSecurityAttributes, ISSUE_CREDIT_TO_ALTERNATE_PAYMENT_INSTRUMENT);
    }
);

export const UserCanRemoveSubscriptionSelector = createSelector(
    [
        CurrentSubscriptionSelector,
        IsSubscriptionActiveSelector,
        IsSubscriptionSuspendedSelector,
        SessionSelectors.UserSecurityAttributesSelector,
        SubscriptionOrderStatusSelector
    ],
    (currentSubscription, isActive, isSuspended, userSecurityAttributes, orderStatus) => {
        const flag = path(['Policy', 'Removable'], currentSubscription) === true
                && (isActive || isSuspended)
                && ORDER_STATUSES.OPEN !== orderStatus
                && PermissionService.hasAdminAccess(userSecurityAttributes, SUBSCRIPTION_ACCESS);
        if (pathOr([], ['Items'], currentSubscription).length > 1) { // Aligned Subscription
            return flag;
        } else {
            return flag && (path(['Policy', 'Removable'], currentSubscription) === true);
        }
    }
);

export const UserCanReplaceSubscriptionSelector = createSelector(
    [SubscriptionOrderStatusSelector, IsSubscriptionActiveSelector, UserHasOrderingPermissionSelector],
    (orderStatus, isActive, userHasOrderingPermission) => {
        return orderStatus === ORDER_STATUSES.COMPLETE && isActive && userHasOrderingPermission;
    }
);

const UserCanRenewOrModifySelector = createSelector(
    [IsSubscriptionActiveSelector, UserHasOrderingPermissionSelector, SubscriptionOrderStatusSelector, CurrentSubscriptionSelector],
    (isActive, userHasOrderingPermission, orderStatus, currentSubscription) => {
        return isActive && userHasOrderingPermission && orderStatus !== ORDER_STATUSES.OPEN && !currentSubscription.offeringThumbnail;
    }
);

export const UserCanModifySelector = UserCanRenewOrModifySelector;
export const UserCanRenewSelector = UserCanRenewOrModifySelector;

export const CurrentSubscriptionHasExtensionsSelector = createSelector(
    [CurrentSubscription],
    (currentSubscription) => {
        if (doesCurrentSubscriptionHaveItems(currentSubscription) &&
            currentSubscription.Items[0].SubscriptionExtensions
        ) {
            return currentSubscription.Items[0].SubscriptionExtensions.length ? true : false;
        }
        return false;
    }
);

export const UserCanRemoveExtensionSelector = createSelector(
    [
        CurrentSubscriptionHasExtensionsSelector,
        SessionSelectors.UserSecurityAttributesSelector
    ],
    (hasExtensions, userSecurityAttributes) => {
        return hasExtensions && PermissionService.hasAdminAccess(userSecurityAttributes, SUBSCRIPTION_ACCESS);
    }
);

export const UserCanUpdateSubscriptionSelector = createSelector(
    [IsSubscriptionActiveSelector, UserHasOrderingPermissionSelector],
    (isActive, userHasOrderingPermissionSelector) => {
        return isActive && userHasOrderingPermissionSelector;
    }
);

export const UserCanRestoreSubscriptionSelector = createSelector(
    [CurrentSubscriptionSelector, IsSubscriptionSuspendedSelector, SessionSelectors.UserSecurityAttributesSelector],
    (currentSubscription, isSuspended, userSecurityAttributes) => {
        return path(['Policy', 'Renewable'], currentSubscription) === true
            && isSuspended && PermissionService.hasAdminAccess(userSecurityAttributes, ORDERING_ACCESS);
    }
);

export const subscriptionActionListSelector = createSelector([UserCanRestoreSubscriptionSelector, IsSubscriptionSuspendedSelector], (canRestoreSubscription, isSuspended) => {
    const actionList = [{
        id: 'VIEW',
        category: i18n.translate(LocaleKeys.VIEW)
    }];

    if (canRestoreSubscription && isSuspended) {
        actionList.push({
            id: 'RESTORE',
            category: i18n.translate(LocaleKeys.RESTORE)
        });
    } else {
        actionList.push({
            id: 'MODIFY',
            category: i18n.translate(LocaleKeys.MODIFY)
        });
    }

    actionList.push({
        id: 'REMOVE',
        category: i18n.translate(LocaleKeys.REMOVE)
    });
    return actionList;
});

export const UserCanTransitionOfferSelector = createSelector(
    [
        CurrentSubscriptionOfferingDetailsSelector,
        MetadataSelectors.codes.MetadataCodeTypeIntegerSelector(MetadataConstants.codes.TransitionConfiguration)
    ],
    (currentSubscriptionOfferingDetails, transitionConfiguration) => {
        const allowTransition = BoolHelper.getBoolOrDefault(pathOr(null, [0, 'AdditionalProperties', 0, 'Value'], transitionConfiguration), false);
        return allowTransition && pathOr(false, ['Subscription'], currentSubscriptionOfferingDetails) &&
            !hasBulkOfferOptions(currentSubscriptionOfferingDetails) &&
            !pathOr(false, ['OrderContractInstance'], currentSubscriptionOfferingDetails) && !hasPendingChange(currentSubscriptionOfferingDetails);
    }
);

export const SubscriptionDetailMoreOptionsSelector = createSelector(
    [
        CurrentSubscriptionSelector,
        UserCanRemoveSubscriptionSelector,
        UserCanReplaceSubscriptionSelector,
        UserCanUpdateSubscriptionSelector,
        UserHasOrderingPermissionSelector,
        UserCanModifySelector,
        UserCanRemoveExtensionSelector,
        UserCanTransitionOfferSelector
    ],
    (currentSubscription, userCanRemove, userCanReplace, userCanUpdate, userHasOrderingPermission, userCanModify, userCanRemoveExtension, userCanTransitionOffer) => {
        const moreOptions = [];
        if (currentSubscription && !currentSubscription.offeringThumbnail) {
            if (userCanUpdate) {
                moreOptions.push({
                    id: SubscriptionConstants.APPLY_DISCOUNT_OPTION_ID,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.APPLY_DISCOUNT)
                });
            }

            if (userCanModify && !currentSubscription.isAligned) {
                moreOptions.push({
                    id: SubscriptionConstants.MODIFY_SUBSCRIPTION,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.MODIFY)
                });
            }

            if (userCanRemoveExtension) {
                moreOptions.push({
                    id: SubscriptionConstants.REMOVE_SUBSCRIPTION_EXTENSION_OPTION_ID,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_EXTENSION.REMOVE_EXTENSION)
                });
            }

            if (userCanRemove) {
                moreOptions.push({
                    id: SubscriptionConstants.REMOVE_SUBSCRIPTION_OPTION_ID,
                    title: currentSubscription.isAligned ? i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE_ALL) : i18n.translate(LocaleKeys.SUBSCRIPTIONS.REMOVE_SUBSCRIPTION.REMOVE)
                });
            }

            if (userCanReplace && !currentSubscription.isAligned) {
                moreOptions.push({
                    id: SubscriptionConstants.REPLACE_SUBSCRIPTION_OPTION_ID,
                    title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.REPLACE_SUBSCRIPTION)
                });
            }
        } else if (currentSubscription && currentSubscription.offeringThumbnail && currentSubscription.OrderStatus === ORDER_STATUSES.COMPLETE) {
            if (userCanRemove) {
                moreOptions.push({
                    id: SubscriptionConstants.REMOVE_SUBSCRIPTION_OFFER_OPTION_ID,
                    title: i18n.translate(LocaleKeys.REMOVE_OFFER.REMOVE_OFFER)
                });
            }

            if (userCanUpdate) {
                moreOptions.push({
                    id: SubscriptionConstants.MODIFY_SUBSCRIPTION_OFFER_OPTION_ID,
                    title: i18n.translate(LocaleKeys.EDIT_OFFER.EDIT_OFFER_ORDER)
                });
            }

            if (userCanTransitionOffer) {
                moreOptions.push({
                    id: SubscriptionConstants.CHANGE_SUBSCRIPTION_OFFER_OPTION_ID,
                    title: i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.CHANGE_OFFER)
                });
            }
        }

        if (userHasOrderingPermission) {
            moreOptions.push({
                id: SubscriptionConstants.VIEW_PAYMENT_HISTORY_OPTION_ID,
                title: i18n.translate(LocaleKeys.SUBSCRIPTIONS.VIEW_PAYMENT_HISTORY)
            });
        }

        return moreOptions;
    }
);

export const PricingPlanOptionsSelector = createSelector(
    [
        EditSubscriptionItemSelector,
        SubscriptionPricingPlanIdSelector
    ],
    (subscription, pricingPlanId) => {
        if (subscription && pricingPlanId) {
            return pipe(
                pathOr([], ['ChangeOptions']),
                pluck('PricingPlan'),
                pluck('Id'),
                append(pricingPlanId)
            )(subscription);
        }
        return [];
    }
);

export const ModifyRenewalSubscriptionItemSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriptions.modifyRenewalSubscriptionItem;
    }
);

/*
    Note about Subscription Extensions:
    We are assuming the backend is returning the extensions in the order in which they were respectively purchased.
    Also only active extensions or extensions that will be active in the future get returned in the payload.
    Cancelled subscription extensions should not show up.
*/

export const CurrentSubscriptionExtensionsSelector = createSelector(
    [CurrentSubscription, CurrentSubscriptionHasExtensionsSelector],
    (currentSubscription, hasExtensions) => {
        return hasExtensions ? currentSubscription.Items[0].SubscriptionExtensions : [];
    }
);

/* This is the initial pricing plan for the subscription */
export const OriginalPricingPlanSelector = createSelector(
    [CurrentSubscription],
    (currentSubscription) => {
        if (doesCurrentSubscriptionHaveItems(currentSubscription)) {
            return currentSubscription.Items[0].PricingPlan ? currentSubscription.Items[0].PricingPlan : {};
        }
        return {};
    }
);

export const IsASubscriptionExtensionActive = createSelector(
    [CurrentSubscriptionHasExtensionsSelector, CurrentSubscriptionExtensionsSelector],
    (hasExtensions, extensions) => {
        if (!(hasExtensions && Array.isArray(extensions) && extensions.length > 0)) {
            return false;
        }

        return extensions.some((currentExtension) => {
            return currentExtension.CurrentlyActive;
        });
    }
);

export const ActiveExtensionSelector = createSelector(
    [CurrentSubscriptionHasExtensionsSelector, CurrentSubscriptionExtensionsSelector, IsASubscriptionExtensionActive],
    (hasExtensions, extensions, isExtensionActive) => {
        if (hasExtensions && isExtensionActive) {
            return extensions.find((currentExtension) => {
                return currentExtension.CurrentlyActive;
            });
        } else {
            return {};
        }
    }
);

/*
    The PricingPlanDictionarySelector is only being used to determine the PP name for an
    extension, since the name is not on Subscription Extensions returned from the API.
*/
export const PricingPlanDictionarySelector = createSelector(
    [CurrentSubscription, CurrentSubscriptionHasExtensionsSelector],
    (currentSubscription, hasExtensions) => {
        if (hasExtensions && currentSubscription) {
            return constructPricingPlanDictionary(currentSubscription);
        }

        return {};
    }
);

export const CurrentPricingPlanSelector = createSelector(
    [OriginalPricingPlanSelector, IsASubscriptionExtensionActive, ActiveExtensionSelector, PricingPlanDictionarySelector],
    (originalPP, isAnExtensionActive, activeExtension, pricingPlanDictionary) => {
        if (!isAnExtensionActive) {
            return originalPP;
        } else {
            const currentPP = Object.assign({}, activeExtension);
            currentPP.Name = pricingPlanDictionary[activeExtension.PricingPlanId];
            return currentPP;
        }
    }
);

export const BackButtonRouteForSubscriptionDetailsSelector = createSelector(
    [SessionSelectors.RouteHistorySelector],
    (history) => {
        let currentEntry, foundEntry;

        if (history) {
            for (let i = history.length - 1; i >= 0; i--) {
                currentEntry = history[i];
                if (!foundEntry && (currentEntry.name === DASHBOARD_ROUTE || currentEntry.name === SUBSCRIPTION_LIST_ROUTE)) {
                    foundEntry = currentEntry;
                }
            }
        }
        return foundEntry? foundEntry.name : DASHBOARD_ROUTE;
    }
);
