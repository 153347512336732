import i18n from 'invision-core/src/components/i18n/i18n';
import {OFFER_BEHAVIOR_CONSTANTS} from 'invision-core/src/constants/offer.constants';

import {OFFERING_OPTION_STATUSES} from '../../components/shared/constants/offering.option.status.constants';
import CustomerCareKeys from '../../locales/keys';
import {
    OFFERING_ACTION_TYPE,
    PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE
} from '../../customercare.constants';
import {MENU_ITEMS} from '../../components/customer/dashboard/dbssDashboard/dashboard.constants';

const isActionValid = (offeringActions = [], type) => {
    return offeringActions.some((offeringAction) => {
        return offeringAction === type;
    });
};

export function getMoreMenuItems(hasSubscriptionOptions, allowTransitioning, offering, bulkOffer,
    changeOfferEligibilityRules, canChangePaymentInstrument, isInGracePeriod = false, showViewOfferDetailsMenuItem = false, pricingPlanThumbnails = [], isServiceSection = false, showEditOfferAdditionalProperties = false) {

    const menuItems = [];
    const manageOfferItems = [];

    const currentOfferEligibilityRules = changeOfferEligibilityRules.find((changeOffer) => {
        return changeOffer.OfferingInstanceId === offering.OfferingInstanceId;
    });
    const isEligible = offerIsEligible(offering, currentOfferEligibilityRules);
    const hasSomeSuspendedOptions = offering.Options.some((option) => {
        return option.Status === OFFERING_OPTION_STATUSES.SUSPENDED;
    });
    const hasSomeActiveOptions = offering.Options.some((option) => {
        return option.Status === OFFERING_OPTION_STATUSES.ACTIVE;
    });
    const hasSomeOffCycleOfferScheduledForSuspension = offering.Options.some((option) => {
        return option?.OffCycleDetail?.IsScheduledForSuspensionAtRenewal;
    });

    const canPayRenewalEarly = offering.Options.some((option) => {
        return option?.OffCycleDetail?.CanPayRenewalEarly;
    });

    const ProvidesConsumesPlanTypes = [PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE.PROVIDES, PRICING_PLAN_SERVICE_RELATIONSHIP_TYPE.CONSUMES];
    const hasProvidesConsumesPlans = offering.Options.some((option) => {
        return pricingPlanThumbnails[option.PricingPlanId] && ProvidesConsumesPlanTypes.includes(pricingPlanThumbnails[option.PricingPlanId].ServiceRelationshipType);
    });

    const offeringActions = offering.AllowedActionTypes;

    if (!offering.IsCoolingOff && offering.Status !== OFFERING_OPTION_STATUSES.REMOVED) {
        manageOfferItems.push({
            id: MENU_ITEMS.ADD_ANOTHER_OFFER,
            title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.ADD_ANOTHER_OFFER)
        });
    }

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.REMOVE)) {
        manageOfferItems.push({
            id: hasSubscriptionOptions ? MENU_ITEMS.REMOVE_OFFER : MENU_ITEMS.DISCONNECT_OFFER,
            title: i18n.translate(hasSubscriptionOptions ?
                CustomerCareKeys.REMOVE_OFFER.REMOVE_OFFER :
                CustomerCareKeys.DISCONNECT_OFFERS.REMOVE_OFFER
            )
        });
    }

    if (showEditOfferAdditionalProperties) {
        if (isServiceSection) {
            manageOfferItems.push({
                id: MENU_ITEMS.EDIT_ADDITIONAL_PROPERTIES,
                title: i18n.translate(CustomerCareKeys.EDIT_ADDITIONAL_PROPERTIES)
            });
        } else {
            manageOfferItems.push({
                id: MENU_ITEMS.EDIT_OFFER_ADDITIONAL_PROPERTIES,
                title: i18n.translate(CustomerCareKeys.EDIT_ADDITIONAL_PROPERTIES)
            });
        }
    }

    manageOfferItems.push({
        id: MENU_ITEMS.EDIT_OFFER,
        title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.EDIT_OFFER)
    });

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.TRANSITION) && allowTransitioning) {
        manageOfferItems.push(
            {
                id: MENU_ITEMS.CHANGE_OFFER,
                title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.CHANGE_OFFER),
                isDisabled: !isEligible || hasSomeSuspendedOptions,
                isLocked: !isEligible || hasSomeSuspendedOptions,
                hasTooltip: !isEligible,
                tooltip: {
                    context: !isEligible ? getOfferRulesContext(offering, currentOfferEligibilityRules) : {},
                    template: require('./../../components/customer/dashboard/dbssDashboard/offering/eligibility.tooltip.template.html'),
                    icon: 'lock',
                    zIndex: 2,
                },
            }
        );
    }

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.GROUP_SUSPEND)) {
        manageOfferItems.push({
            id: MENU_ITEMS.SUSPEND_OFFER,
            title: i18n.translate(CustomerCareKeys.SUSPEND_OFFER)
        });
    }

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.GROUP_RESUME)) {
        manageOfferItems.push({
            id: MENU_ITEMS.RESUME_OFFER,
            title: i18n.translate(CustomerCareKeys.RESUME_OFFER)
        });
    }

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.OPT_IN)) {
        manageOfferItems.push({
            id: MENU_ITEMS.ENABLE_AUTO_RENEW,
            title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.ENABLE_AUTO_RENEW)
        });
    }

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.OPT_OUT)) {
        manageOfferItems.push({
            id: MENU_ITEMS.DISABLE_AUTO_RENEW,
            title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.DISABLE_AUTO_RENEW)
        });
    }

    if (hasSomeActiveOptions) {
        manageOfferItems.push({
            id: MENU_ITEMS.SUSPEND_SUBSCRIBER_PRODUCT,
            title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.SUSPEND_SUBSCRIBER_PRODUCTS)
        });
    }

    if (hasSomeSuspendedOptions || hasSomeOffCycleOfferScheduledForSuspension) {
        manageOfferItems.push({
            id: MENU_ITEMS.RESUME_SUBSCRIBER_PRODUCT,
            title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.RESUME_SUBSCRIBER_PRODUCTS)
        });
    }


    if (!offering.IsCoolingOff && offering.Status !== OFFERING_OPTION_STATUSES.REMOVED) {
        if (canChangePaymentInstrument) {
            menuItems.push({
                title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.MANAGE_OFFER),
                subitems: manageOfferItems
            }, {
                title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.MANAGE_PAYMENT_INSTRUMENT),
                subitems: [{
                    id: MENU_ITEMS.EDIT_PAYMENT_INSTRUMENT,
                    title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.EDIT_EXISTING)
                }, {
                    id: MENU_ITEMS.CHANGE_PAYMENT_INSTRUMENT,
                    title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.CHANGE)
                }, {
                    id: MENU_ITEMS.ADD_PAYMENT_INSTRUMENT,
                    title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.ADD_NEW)
                }]
            });

            if (isInGracePeriod) {
                menuItems.push({
                    id: MENU_ITEMS.RETRY_PAYMENT,
                    title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.RETRY_PAYMENT)
                });
            }

            if (hasProvidesConsumesPlans && (!bulkOffer || !bulkOffer.length)) {
                menuItems.push({
                    id: MENU_ITEMS.MANAGE_PLAN_SERVICES,
                    title: i18n.translate(CustomerCareKeys.PRODUCT_SERVICE_RELATIONSHIP.MANAGE_PLAN_SERVICES)
                });
            }
        } else {

            if (showViewOfferDetailsMenuItem) {
                manageOfferItems.push({
                    id: MENU_ITEMS.VIEW_OFFER_DETAILS,
                    title: i18n.translate(CustomerCareKeys.OFFERINGS_PAGE.VIEW_OFFER_DETAILS)
                });
            }
            if (hasProvidesConsumesPlans && (!bulkOffer || !bulkOffer.length)) {
                manageOfferItems.push({
                    id: MENU_ITEMS.MANAGE_PLAN_SERVICES,
                    title: i18n.translate(CustomerCareKeys.PRODUCT_SERVICE_RELATIONSHIP.MANAGE_PLAN_SERVICES)
                });
            }

            return manageOfferItems;
        }
    }

    if (isActionValid(offeringActions, OFFERING_ACTION_TYPE.RECONNECT)) {
        menuItems.push({
            id: MENU_ITEMS.RECONNECT_OFFER,
            title: i18n.translate(CustomerCareKeys.RECONNECT_OFFER)
        });
    }

    if (canPayRenewalEarly) {
        menuItems.push({
            id: MENU_ITEMS.MAKE_EARLY_RENEWAL_PAYMENT,
            title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.RENEW_PLAN_NOW)
        });
    }

    return menuItems;
}

export function offerIsEligible(offering, currentOfferEligibilityRules) {
    if (!offering.OfferEligibility
            || offering.OfferEligibility.OfferBehaviorId === OFFER_BEHAVIOR_CONSTANTS.ALLOW_CHANGE_OFFER) {
        return true;
    }

    if (offering.OfferEligibility.OfferBehaviorId === OFFER_BEHAVIOR_CONSTANTS.CHANGE_OFFER_LOCKED) {
        return false;
    }

    if (offering.OfferEligibility.OfferBehaviorId === OFFER_BEHAVIOR_CONSTANTS.SELECT_ELIGIBILITY_RULE
            && currentOfferEligibilityRules) {
        return currentOfferEligibilityRules.ChangeOfferEligibilityRules.every((offerRule) => {
            return offerRule.IsEligible;
        });
    }
}

export function getOfferRulesContext(offering, currentOfferEligibilityRules) {
    const context = {
        title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.ELIGIBILITY_RULES),
    };

    if (currentOfferEligibilityRules) {
        Object.assign(context, {
            description: currentOfferEligibilityRules.ChangeOfferEligibilityRules[0].Name,
            descriptionList: currentOfferEligibilityRules.ChangeOfferEligibilityRules.map((offerRule) => {
                return offerRule;
            })
        });
    }

    return context;
}
