import {createSelector} from 'reselect';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import groupBy from 'ramda/src/groupBy';
import pathOr from 'ramda/src/pathOr';
import clone from 'ramda/src/clone';
import Immutable from 'seamless-immutable';
import {
    MetadataCodeTypeSelector,
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSansGlobalSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {thunkSelectors as ThunkSelectors} from 'invision-core/src/reducers/thunk.selector';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {RETRIEVE_USAGE_CAPS_PATH} from '../recoverableUiState/usage.caps.reducer';
import {mapUsageCaps} from './usage.caps.selectors.helper';
import {
    CurrentCappedAccountSelector,
    CurrentCappedServicesSelector
} from './customer.convergent.biller.selectors';
import CustomerCareLocaleKeys from '../../locales/keys';
import i18n from 'invision-core/src/components/i18n/i18n';
import moment from 'moment';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {CurrentCustomerSelector} from './customer.selectors';
import {
    USAGE_CAPS_ELECTION_LEVEL
} from '../../components/customer/servicesAndUsage/usageCaps/usage.caps.constants';
import {getFormattedServiceAttributeValue} from './services.list.selectors.helper';
import {SelectedCustomerSelector} from 'invision-core/src/components/customer/customer.selectors';

const EMPTY_ARRAY = [];
const EMPTY_STRING = '';

const UsageCapsRecoverableUIStateSelector = (state) => {
    return state.customercare.recoverableUiState.usageCaps;
};

export const IsUpdatingUsageCapSelector = createSelector(
    [UsageCapsRecoverableUIStateSelector],
    (recoverableUsageCaps) => {
        return recoverableUsageCaps.isUpdatingData;
    }
);

export const CurrentServiceIdSelector = (state) => {
    return state.router.currentParams.serviceId;
};

export const RetrievedUsageCaps = ThunkSelectors(UsageCapsRecoverableUIStateSelector, RETRIEVE_USAGE_CAPS_PATH);

export const AllServicesUsageCapsSelector = createImmutableSelector(
    [RetrievedUsageCaps.dataSelector, MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure)],
    (retrieveUsageCaps, units) => {

        if (retrieveUsageCaps) {
            //Using Object destructuring assignment to get nested property with a default value
            const {AllServicesUsageCaps = EMPTY_ARRAY} = retrieveUsageCaps;
            let usageCaps = [];
            if (AllServicesUsageCaps.length) {
                usageCaps = AllServicesUsageCaps.map(item => {
                    const usageCapItem = clone(item);
                    if (item.ThresholdType && item.ThresholdType.Code) {
                        const unit = Object.values(units).filter(unitItem => {
                            return unitItem.Value === item.ThresholdType.Code.toString();
                        })[0];
                        usageCapItem.ThresholdType.Name = unit.Name;
                    }
                    return usageCapItem;
                });
            }
            return usageCaps;
        }
        return EMPTY_ARRAY;
    }
);

export const UsageCapsElectionsSelector = createSelector(
    [
        RetrievedUsageCaps.dataSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeTypeDictionarySelector(CODES.UnitsOfMeasure)
    ],
    (retrievedUsageCaps, serviceAttributes, regularExpressions, units) => {

        if (retrievedUsageCaps) {
            //Using Object destructuring assignment to get nested property with a default value
            const {UsageCapElections = EMPTY_ARRAY} = retrievedUsageCaps;
            if (UsageCapElections.length) {
                return UsageCapElections.map((usageCapElection) => {
                    const usageCap = clone(usageCapElection);
                    if (usageCap.ServiceIdentifier) {
                        const {Value, ServiceAttributeId} = usageCap.ServiceIdentifier;
                        usageCap.ServiceIdentifier.FormattedValue = getFormattedServiceAttributeValue(ServiceAttributeId, Value, serviceAttributes, regularExpressions);
                    }
                    if (usageCapElection.ThresholdType && usageCapElection.ThresholdType.Code) {
                        const unit = Object.values(units).filter(unitItem => {
                            return unitItem.Value === usageCapElection.ThresholdType.Code.toString();
                        })[0];
                        usageCap.ThresholdType.Name = unit.Name;
                    }
                    return usageCap;
                });
            }
        }
        return Immutable(EMPTY_ARRAY);
    }
);

export const UsageCapsEnabledSelector = createSelector(
    [UsageCapsElectionsSelector],
    (usageCapsElections) => {
        if (usageCapsElections) {
            return usageCapsElections.filter((usageCapsElection) => {
                return usageCapsElection.UsageCapEnabled === true;
            });
        }
    }
);

export const MappedAllUsageNotificationEnabledSelector = createSelector(
    [UsageCapsElectionsSelector],
    (usageCapsElections) => {
        return (usageCapsElections || EMPTY_ARRAY).filter((usageCapsElection) => {
            return (usageCapsElection.UsageNotificationEnabled && !usageCapsElection.UsageCapEnabled);
        });
    }
);

export const MappedUsageCapsSelector = createSelector(
    [
        UsageCapsEnabledSelector,
        CurrentCustomerSelector,
        CurrentServiceIdSelector
    ],
    (usageCapsEnabled, currentCustomer, currentServiceId) => {

        if (usageCapsEnabled && usageCapsEnabled.length && currentCustomer) {
            const findUsageCapsInstances = (usageCap) => {
                if (currentServiceId) {
                    return usageCap.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL && usageCap.ServiceIdentifier.Value === currentServiceId;
                }
                return usageCap.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL;
            };
            const mappedUsageCaps = mapUsageCaps(usageCapsEnabled.filter(findUsageCapsInstances), currentCustomer.SubscriberCurrency);
            //Group all caps by Service
            const groupedUsageCapsByService = groupBy(pathOr(undefined, ['ServiceIdentifier', 'Value']))(mappedUsageCaps);

            return Immutable(groupedUsageCapsByService);
        }
        return Immutable(EMPTY_ARRAY);
    }
);

export const MappedUsageNotificationEnabledSelector = createSelector(
    [
        MappedAllUsageNotificationEnabledSelector,
        CurrentCustomerSelector,
        CurrentServiceIdSelector
    ],
    (mappedAllUsageNotificationEnabled, currentCustomer, currentServiceId) => {

        if (mappedAllUsageNotificationEnabled && mappedAllUsageNotificationEnabled.length && currentCustomer) {
            const findUsageCapsInstances = (usageCap) => {
                if (currentServiceId) {
                    return usageCap.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL && usageCap.ServiceIdentifier.Value === currentServiceId;
                }
                return usageCap.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.SERVICE_LEVEL;
            };
            const mappedUsageCaps = mapUsageCaps(mappedAllUsageNotificationEnabled.filter(findUsageCapsInstances), currentCustomer.SubscriberCurrency);
            //Group all caps by Service
            const groupedUsageCapsByService = groupBy(pathOr(undefined, ['ServiceIdentifier', 'Value']))(mappedUsageCaps);

            return Immutable(groupedUsageCapsByService);
        }
        return Immutable(EMPTY_ARRAY);
    }
);

export const MappedAllServicesUsageCapsSelector = createSelector(
    [
        AllServicesUsageCapsSelector,
        UsageCapsElectionsSelector,
        CurrentCustomerSelector
    ],
    (allServicesUsageCaps, usageCapElections, currentCustomer) => {
        if (allServicesUsageCaps && allServicesUsageCaps.length && currentCustomer) {
            const mappedUsageCaps = mapUsageCaps(allServicesUsageCaps, currentCustomer.SubscriberCurrency, true);
            const determineAllServicesUsageCapsValues = (allServicesUsageCap) => {
                const findUsageCapsInstances = (usageCap) => {
                    return usageCap.Id === allServicesUsageCap.Id && usageCap.ElectionLevel === USAGE_CAPS_ELECTION_LEVEL.CUSTOMER_LEVEL;
                };
                const currentUsageCapsInstances = usageCapElections.filter(findUsageCapsInstances);
                const mostUsedThreshold = currentUsageCapsInstances.length &&
                    allServicesUsageCap.Thresholds && allServicesUsageCap.Thresholds.length ?
                    determineMostUsedThreshold(allServicesUsageCap, currentUsageCapsInstances) : {
                        electedThreshold: allServicesUsageCap.Thresholds && allServicesUsageCap.Thresholds[0] || null
                    };
                const elected = currentUsageCapsInstances.length > 0 && currentUsageCapsInstances.every((usageCap) => {
                    return !!usageCap.Elected;
                });
                const usageCapEnabled = currentUsageCapsInstances.length > 0 && currentUsageCapsInstances.every((usageCap) => {
                    return !!usageCap.UsageCapEnabled;
                });
                const isEnabled = elected && mostUsedThreshold.enabled;

                return Immutable(allServicesUsageCap.merge({
                    Elected: elected && isEnabled,
                    ElectedThreshold: mostUsedThreshold.electedThreshold,
                    selected: mostUsedThreshold.electedThreshold ? 'selected' : undefined,
                    enabled: isEnabled,
                    UsageCapEnabled: usageCapEnabled,
                    UsageNotificationEnabled: currentUsageCapsInstances.length && currentUsageCapsInstances[0].UsageNotificationEnabled,
                    UsageCapUsageNotification: currentUsageCapsInstances.length && currentUsageCapsInstances[0].UsageCapUsageNotification
                }));
            };
            return mappedUsageCaps.map(determineAllServicesUsageCapsValues);
        }
        return Immutable(EMPTY_ARRAY);
    }
);

export const AccountLevelUsageCaps = createSelector(
    [
        MappedAllServicesUsageCapsSelector
    ],
    (allServicesUsageCaps) => {
        return (allServicesUsageCaps || EMPTY_ARRAY).filter((item) => {
            return item.UsageCapEnabled;
        });
    }
);

export const AccountLevelUsageNotification = createSelector(
    [MappedAllServicesUsageCapsSelector, UsageCapsElectionsSelector],
    (allServicesUsageCaps) => {
        return (allServicesUsageCaps || EMPTY_ARRAY).filter((item) => {
            return (item.UsageNotificationEnabled && !item.UsageCapEnabled);
        });
    }
);


export const IsUsageCapsEnabledSelector = createSelector(
    [MetadataCodeTypeSansGlobalSelector(CODES.SubscriberSearchConfig), MappedAllServicesUsageCapsSelector],
    (usageCapSettings, MappedAllServicesUsage) => {
        //Determines if any of the account level usage caps have been activated.
        const electedServices = MappedAllServicesUsage.filter((service) => {
            return service.Elected === true;
        });
        //Determines if the flag on enable customer level usage caps is set to true
        const prop = usageCapSettings.length ? usageCapSettings[0].AdditionalProperties.find((prop) => {
            return prop.Key === 'enable_customer_level_usage_caps';
        }) : {};
        return (prop.Value === 'False') && !electedServices.length  ? false : true;
    }
);

export const IsUsageCapsElectionDisabledSelector = createSelector(
    [MetadataCodeTypeSansGlobalSelector(CODES.SubscriberSearchConfig), MappedAllServicesUsageCapsSelector],
    (usageCapSettings, MappedAllServicesUsage) => {
        //Selector for scenario that Usage Cap Rules are turned off and user has Elected at least one Account level cap
        const electedServices = MappedAllServicesUsage.filter((service) => {
            return service.Elected === true;
        });
        const prop = usageCapSettings.length ? usageCapSettings[0].AdditionalProperties.find((prop) => {
            return prop.Key === 'enable_customer_level_usage_caps';
        }) : {};

        return electedServices.length && (prop.Value === 'False') ? true : false;
    }
);

export const BreachedUsageCapsMessageSelector = createSelector(
    [
        CurrentCappedServicesSelector
    ],
    ({ServiceIdentifiers: serviceIdentifiers, CapResetDate: capResetDate}) => {

        if (serviceIdentifiers && capResetDate) {
            const utcMoment = moment.utc();
            const capResetDateUTC = moment.utc(capResetDate);
            const utcDiffInDays = capResetDateUTC.diff(utcMoment, 'days');
            let resetDate = '';
            if (utcDiffInDays >= 1) {
                resetDate = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.RESET_DATE, {
                    days: utcDiffInDays
                });
            } else {
                resetDate = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.RESET_DATE_TODAY);
            }
            const resetDateMessage = `${i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.RESET_MESSAGE)} ${resetDate}`;
            if (serviceIdentifiers.length > 5) {
                return `${i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.CAPPED_SERVICES_SEVERAL)} ${resetDateMessage}`;
            } else {
                const messageSeparator = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.MESSAGE_SEPARATOR);
                const serviceList = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.CAPPED_SERVICES_LIST, {
                    cappedServicesList: formatServiceList(serviceIdentifiers, messageSeparator)
                });
                return `${serviceList} ${resetDateMessage}`;
            }
        }

        return EMPTY_STRING;
    }
);

export const BreachedAccountLevelUsageCapsMessageSelector = createSelector(
    CurrentCappedAccountSelector,
    (BreachedRules) => {

        const messageSeparator = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CAP_INDICATOR.MESSAGE_SEPARATOR);

        if (!BreachedRules || !BreachedRules.length) {
            return EMPTY_STRING;
        }

        return i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.CAPPED_ACCOUNT, {
            breachedCaps: formatAccountLevelUsageCapsList(BreachedRules, messageSeparator),
            countCapsBreached: BreachedRules.length
        });
    }
);

const formatServiceList = (serviceIdentifiers, messageSeparator) => {
    const numberOfServices = serviceIdentifiers.length;
    if (numberOfServices) {
        const commaSeparatedServices = (formattedString, serviceId, index) => {
            if (index === numberOfServices-1 && numberOfServices > 1) {
                return `${formattedString}${messageSeparator} ${serviceId.Value}`;
            }
            let separator = EMPTY_STRING;
            if (numberOfServices > 1) {
                separator = (index === numberOfServices-2) ? ' ' : ',';
            }

            return `${formattedString} ${serviceId.Value}${separator}`;
        };

        return serviceIdentifiers.reduce(commaSeparatedServices, EMPTY_STRING);
    }
};

const formatAccountLevelUsageCapsList = (serviceIdentifiers, messageSeparator) => {

    const numberOfServices = serviceIdentifiers.length;
    const todayMessage = i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.RESET_DATE_TODAY_ACCOUNT);

    const formattedIdentifiers = serviceIdentifiers.map( (service) => {
        const utcMoment = moment.utc();
        const capResetDateUTC = moment.utc(service['BreachedResetDate']);
        const utcDiffInDays = capResetDateUTC.diff(utcMoment, 'days');
        const resetDate = utcDiffInDays >= 1
            ? i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.BREACHED_CAPS.RESET_DATE_ACCOUNT, {
                days: utcDiffInDays
            })
            : todayMessage;
        return `${service.Name} ${resetDate}`;
    });

    return [
        formattedIdentifiers.slice(0, -1).join(', '), // all elements but last
        formattedIdentifiers.slice(-1)[0]  // only last element
    ].join( numberOfServices < 2 ? ' ' : ` ${messageSeparator} ` );
};

function findAdditionalProperty(additionalProperties, targetProperty) {
    const findPropertyByKey = (property) => {
        return property.Key === targetProperty;
    };
    return additionalProperties.find(findPropertyByKey);
}

const determineMostUsedThreshold = (allServicesThresholds, usageCapsInstances) => {
    let mostUsedThreshold = {};
    let maxCount = 0;
    const mostUsedMap = {};
    const getMaxOcurringThreshold = (cap) => {
        const calculatedElectedThreshold = cap.ElectedThreshold || cap.Thresholds[0];
        mostUsedMap[calculatedElectedThreshold.Id] ? mostUsedMap[calculatedElectedThreshold.Id]++ : mostUsedMap[calculatedElectedThreshold.Id] = 1;
        if (mostUsedMap[calculatedElectedThreshold.Id] > maxCount) {
            maxCount = mostUsedMap[calculatedElectedThreshold.Id];
            mostUsedThreshold = {
                electedThreshold: calculatedElectedThreshold
            };
        }
    };

    usageCapsInstances.forEach(getMaxOcurringThreshold);
    //Threshold Dropdown will be enabled only when all of it's ocurrences have the same value selected
    mostUsedThreshold && (mostUsedThreshold.enabled = Object.keys(mostUsedMap).length === 1);

    return mostUsedThreshold;
};


export const NoUsageCapsMessageSelectors = createSelector(
    [
        MetadataCodeTypeSelector(CODES.UsageCapMenuTitle)
    ],
    (usageCapMenuTitleCodes) => {
        let message = null;
        if (usageCapMenuTitleCodes && usageCapMenuTitleCodes.length) {
            let currentBusinessUnitCodeMessage = undefined;
            let globalMessage = undefined;
            for (const code of usageCapMenuTitleCodes) {
                const isEnabled = getBoolOrDefault(findAdditionalProperty(code.AdditionalProperties, 'enabled').Value, false);
                message = findAdditionalProperty(code.AdditionalProperties, 'no_usage_caps_message');
                if (isEnabled && !(code.Global) && message && message.Value) {
                    currentBusinessUnitCodeMessage = message.Value;
                    break;
                } else if (isEnabled) {
                    globalMessage = message.Value;
                }
            }

            return currentBusinessUnitCodeMessage || globalMessage;
        }
    }
);

export const SelectedAccountLevelSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer ? selectedCustomer.bulkItems.isAccountLevelSelected: false;
    }
);
