import {createSelector} from 'reselect';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import clone from 'ramda/src/clone';
import find from 'ramda/src/find';
import groupBy from 'ramda/src/groupBy';
import map from 'ramda/src/map';
import path from 'ramda/src/path';
import pathOr from 'ramda/src/pathOr';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import values from 'ramda/src/values';
import Immutable from 'seamless-immutable';
import i18n from 'invision-core/src/components/i18n/i18n';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {CustomerInfoSelector, EditCartSelector} from './new.connect.wizard.selectors';
import {
    CurrentCustomerSelector as CustomerSelectorsCurrentCustomerSelector,
    CustomerStoreSelector as CustomerSelectorsCustomerStoreSelector,
    SelectedCustomerSelector as CustomerSelectorsSelectedCustomerSelector
} from 'invision-core/src/components/customer/customer.selectors';
import {
    getSecurityAttributeById,
    hasAccess
} from 'invision-core/src/components/security/permission.service';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {ActiveSubtenantsSelector} from 'invision-core/src/components/subtenants/subtenants.selectors';
import {
    MetadataCodeTypeSelector,
    MetadataCodeTypeDictionarySelector,
    SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
    SubscriberRequirementsForCurrentBusinessUnitOrDefault
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    selectOptionsForCodeNames,
    selectOptionsForCodeNamesWithCustomOption
} from 'invision-core/src/components/metadata/codes/codes.helpers';
import {
    addressStateRegionProvinceValueOptionsForCountry,
    consentsForCountry,
    currencyValuesForCountry,
    currencyValueOptionsForCountry,
    getCustomerAdditionalPropertiesSortedByDisplayOrder,
    getCustomerCurrencyName,
    getCustomerHomeCountryName,
    getCustomerInvoiceDetailStatus,
    getPreferredTaxDefaultCurrency,
    selectOptionsForAlphabetizedCurrencyValues
} from '../helpers/customer.selectors.helpers';
import {
    ADJUSTMENT_LIMIT_AND_ACCESS,
    BILL_CYCLE_CHANGE_ACCESS,
    FINANCE_ADJUSTMENT_LIMIT_AND_ACCESS,
    PII_ACCESS,
    SUBSCRIBER_BYPASS_RESET_PASSWORD,
    UPDATE_PIN_ACCESS
} from '../../security.attributes';
import {
    INVALID_SHOPPING_CART_FAULT_CODE,
    SUBSCRIBER_TYPE_CODES
} from '../../customercare.constants';
import {getCustomerIdentifier} from '../helpers/customer.helper';
import LocaleKeys from '../../locales/keys';
import {CUSTOMER_CATEGORY} from '../../components/createCustomerPopup/create.customer.popup.constants';
import {ShowCustomerAutoPayBannerSelector} from './customer.account.status.selectors';
import {CUSTOMER_STATE} from '../../components/shared/constants/customer.constants';
import {findByKey} from 'invision-core/src/utilities/ramda.helper';
import {getBoolOrDefault} from 'invision-core/src/components/helpers/bool.helper';
import {SubscriberAdditionalPropertiesSelector} from 'invision-core/src/components/metadata/codes/codes.additional.properties.selectors';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {DASHBOARD_ROUTE} from '../constants/dashboard.constants';
import {RouteCurrentStateSelector} from 'invision-core/src/components/router/router.selectors';
import {getChargeTypesForDetailedTaxation} from '../../components/createCustomer/tax.model.helper';
import {FeatureTogglesSelector} from 'invision-core/src/components/featureToggle/featureToggle.selectors';
import {FeatureToggleConstants} from 'invision-core';

const EMPTY_OBJECT = Immutable({});
export const EMPTY_ARRAY = Immutable([]);
export const CustomerStoreSelector = CustomerSelectorsCustomerStoreSelector;
export const SelectedCustomerSelector = CustomerSelectorsSelectedCustomerSelector;
export const CurrentCustomerSelector = CustomerSelectorsCurrentCustomerSelector;

export const RouteParams = (state) => {
    return state.router.currentParams;
};

export const RouteState = (state) => {
    return state.router.currentState;
};

export const RoutePreviousState = (state) => {
    return state.router.prevState;
};

export const CreditCheckConfigurationSelector = (state) => {
    return pathOr(null, ['customercare', 'config', 'creditCheck'], state);
};

export const CurrentCustomerStateSelector = createSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return currentCustomer.State;
    }
);

export const CurrentCustomerReadinessSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return currentCustomer.SubscriberReadiness || EMPTY_OBJECT;
    }
);

export const IsCurrentCustomerReadyForOrdersSelector = createImmutableSelector(
    [CurrentCustomerReadinessSelector, IsDbss],
    (currentCustomerReadiness, isDbss) => {
        return isDbss ? currentCustomerReadiness.ReadyForOrders : true;
    }
);

export const IsCurrentCustomerBlocklisted = createSelector(
    [CurrentCustomerStateSelector],
    (currentCustomerState) => {
        let flag = false;
        if (currentCustomerState) {
            flag = currentCustomerState === CUSTOMER_STATE.BLOCKLISTED.toString();
        }
        return flag;
    }
);

export const SelectedCustomerCreditCheckDetailsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return path(['creditCheck'], selectedCustomer);
    }
);

export const SelectedCustomerIsCreditCheckSkippedSelector = createSelector(
    [SelectedCustomerCreditCheckDetailsSelector],
    (subscriberCreditCheck) => {
        return subscriberCreditCheck.isCreditCheckSkipped;
    }
);

export const CurrentCustomerIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.customerId;
    }
);

export const CurrentOrderIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.orderId;
    }
);

export const CurrentAccountNumberSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.accountNumber;
    }
);

export const CurrentServiceIdSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.serviceId;
    }
);

export const GdprConsentsSelector = createSelector(
    [MetadataCodeTypeSelector(CODES.Consents)],
    (consents) => {
        return consents;
    }
);

export const IsGdprCompliantSelector = createSelector(
    GdprConsentsSelector,
    (consents) => {
        return (consents.length > 0);
    }
);

export const ShowAutoPayBannerSelector = createSelector(
    [RouteParams, ShowCustomerAutoPayBannerSelector],
    (routeParams, showBanner) => {
        return routeParams && routeParams.showAutoPayBanner && routeParams.showAutoPayBanner.enabled && showBanner
            && routeParams.showAutoPayBanner.customerId === parseInt(routeParams.customerId);
    }
);

export const ShowDetailedTaxationPerformanceBannerSelector = createImmutableSelector(
    [
        RouteCurrentStateSelector,
        CurrentCustomerSelector,
        SubscriberRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault
    ],
    (routeCurrentState, currentCustomer, subscriberRequirements, subscriberCategoryRequirements) => {
        const requirements = currentCustomer.Category === CUSTOMER_CATEGORY.RESIDENTIAL ? subscriberRequirements : subscriberCategoryRequirements;
        return routeCurrentState.name === DASHBOARD_ROUTE &&
            currentCustomer.ChargeTypesForDetailedTaxation?.length && requirements.enable_charge_types_for_detailed_taxation;
    }
);

export const PreviousSubscriptionSelector = createSelector(
    [RouteParams],
    (routeParams) => {
        return routeParams.subscription;
    }
);

export const CurrentCustomerConvergentBillerIdSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return pathOr('', ['selectedCustomer', 'data', 'ConvergentBillerId'], customerStore);
    }
);

export const SelectedCustomerCategorySelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.category;
    }
);

export const SelectedCustomerRequirements = createSelector(
    [SelectedCustomerCategorySelector,
        SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberRequirementsForCurrentBusinessUnitOrDefault],
    (customerCategory, customerCategoryRequirements, customerRequirements) => {
        if (customerCategory === CUSTOMER_CATEGORY.COMMERCIAL) {
            return customerCategoryRequirements;
        } else {
            return customerRequirements;
        }
    }
);

export const CustomerPinRegexSelector = createImmutableSelector(
    [
        SelectedCustomerRequirements,
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (customerRequirements, regexCodes) => {
        return path(['AdditionalProperties', 'regex'], Object.values(regexCodes).find((item) => {
            return item.Value === customerRequirements.subscriber_pin_regex;
        }));
    }
);

export const EnableProspectLiteSelector = createImmutableSelector(
    [SelectedCustomerCategorySelector,
        SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberRequirementsForCurrentBusinessUnitOrDefault],
    (customerCategory, commercialCustomerRequirements, residentialCustomerRequirements) => {
        return customerCategory === CUSTOMER_CATEGORY.COMMERCIAL ? commercialCustomerRequirements.enable_prospect_lite === true :
            residentialCustomerRequirements.enable_prospect_lite === true;
    }
);


export const EnableProspectLiteForHouseholdSelector = createImmutableSelector(
    [SelectedCustomerCategorySelector,
        SubscriberCategoryRequirementsForCurrentBusinessUnitOrDefault,
        SubscriberRequirementsForCurrentBusinessUnitOrDefault],
    (customerCategory, commercialCustomerRequirements, residentialCustomerRequirements) => {
        return customerCategory === CUSTOMER_CATEGORY.COMMERCIAL ? commercialCustomerRequirements.enable_prospect_lite_for_household === true :
            residentialCustomerRequirements.enable_prospect_lite_for_household === true;
    }
);

export const DefaultPasswordChallengeOptionsWithCustomValidation = createSelector(
    [MetadataCodeTypeSelector(CODES.DefaultPasswordChallenge), SelectedCustomerRequirements],
    (challenges, subscriberRequirements) => {
        if (!challenges.length) {
            return EMPTY_ARRAY;
        }
        return subscriberRequirements.enable_custom_password_challenge ? selectOptionsForCodeNamesWithCustomOption(challenges) : selectOptionsForCodeNames(challenges);
    }
);

export const SelectedCustomerIsSsnRequired = createSelector(
    [SelectedCustomerRequirements, CreditCheckConfigurationSelector, SelectedCustomerIsCreditCheckSkippedSelector],
    (requirements, creditCheckConfiguration, selectedCustomerIsCreditCheckSkipped) => {
        return requirements.capture_ssn ||
            (creditCheckConfiguration.creditCheckModalStatus && requirements.capture_ssn_for_credit_check && !selectedCustomerIsCreditCheckSkipped);
    }
);

export const SelectedCustomerIsDriversLicenseRequired = createSelector(
    [SelectedCustomerRequirements, CreditCheckConfigurationSelector, SelectedCustomerIsCreditCheckSkippedSelector],
    (requirements, creditCheckConfiguration, selectedCustomerIsCreditCheckSkipped) => {
        return requirements.capture_drivers_license_number
            || (creditCheckConfiguration.creditCheckModalStatus && requirements.capture_drivers_license_number_for_credit_check && !selectedCustomerIsCreditCheckSkipped);
    }
);

export const SelectedCustomerIsDateOfBirthRequired = createSelector(
    [SelectedCustomerRequirements, CreditCheckConfigurationSelector, SelectedCustomerIsCreditCheckSkippedSelector],
    (requirements, creditCheckConfiguration, selectedCustomerIsCreditCheckSkipped) => {
        return requirements.capture_birthdate ||
            (creditCheckConfiguration.creditCheckModalStatus && requirements.capture_birthdate_for_credit_check && !selectedCustomerIsCreditCheckSkipped);
    }
);

export const SelectedCustomerHomeCountrySelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['data', 'HomeCountry'], selectedCustomer);
    }
);

export const SelectedCustomerSubtenantSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr('0', ['data', 'SubtenantId', 'Value'], selectedCustomer);
    }
);

// Returns the subscriber consents as-is provided by the API
export const SelectedCustomerSubscriberConsentsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr([], ['data', 'SubscriberConsents'], selectedCustomer);
    }
);

export const SelectedCustomerAllConsentsSelector = createSelector(
    [GdprConsentsSelector, SelectedCustomerSubscriberConsentsSelector],
    (consents, selectedCustomerSubscriberConsents) => {
        const allBUConsents = consentsForCountry(null, consents);
        if (allBUConsents.length > 0) {
            const customerConsentsMap = {};
            selectedCustomerSubscriberConsents.forEach(c => {
                customerConsentsMap[c.ConfigConsentId] = {
                    isAccepted: c.ConsentAccepted,
                    acceptanceDateTime: c.ConsentDateTime
                };
            });
            return allBUConsents.map(c => {
                if (customerConsentsMap[c.id]) {
                    c.isAccepted = customerConsentsMap[c.id].isAccepted;
                    c.isModifiedAfterAcceptance = !(customerConsentsMap[c.id].acceptanceDateTime);
                }
                return c;
            });
        } else {
            return [];
        }
    }
);

// Returns Consents from the Metadata Store that are configured for the HomeCountry
export const SelectedCustomerConsentsSelector = createSelector(
    [GdprConsentsSelector, SelectedCustomerHomeCountrySelector, SelectedCustomerSubscriberConsentsSelector],
    (consents, selectedCustomerHomeCountry, selectedCustomerSubscriberConsents) => {
        const configuredConsents = consentsForCountry(selectedCustomerHomeCountry, consents);
        if (configuredConsents.length > 0) {
            const customerConsentsMap = {};
            selectedCustomerSubscriberConsents.forEach(c => {
                customerConsentsMap[c.ConfigConsentId] = {
                    isAccepted: c.ConsentAccepted,
                    acceptanceDateTime: c.ConsentDateTime
                };
            });
            return configuredConsents.map(c => {
                if (customerConsentsMap[c.id]) {
                    c.isAccepted = customerConsentsMap[c.id].isAccepted;
                    c.isModifiedAfterAcceptance = !(customerConsentsMap[c.id].acceptanceDateTime);
                }
                return c;
            });
        } else {
            return [];
        }
    }
);

export const SelectedCustomerConsentsMandatorySelector = createSelector(
    [SelectedCustomerConsentsSelector],
    (consents) => {
        return consents.filter(c => {
            return c.isRequired;
        });
    }
);

export const SelectedCustomerConsentsMandatoryAcceptedSelector = createSelector(
    [SelectedCustomerConsentsMandatorySelector],
    (consents) => {
        return consents.filter(c => {
            return c.isAccepted;
        });
    }
);

// Returns the unaccepted mandatory consents for the selected customer. These unaccepted contents are the ones that were added after the Customer last gave his/her consent.
export const SelectedCustomerConsentsMandatoryUnacceptedSelector = createSelector(
    [SelectedCustomerConsentsMandatorySelector],
    (consents) => {
        return consents.filter(c => {
            return !c.isAccepted || c.isModifiedAfterAcceptance;
        });
    }
);

export const SelectedCustomerConsentsOptionalSelector = createSelector(
    [SelectedCustomerConsentsSelector],
    (consents) => {
        return consents.filter(c => {
            return !c.isRequired;
        });
    }
);

export const SelectedCustomerIsConsentTermsSentSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.isConsentTermsSent;
    }
);

export const ShoppingCartSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.shoppingCart;
    }
);

export const StoreOrderSelector = createImmutableSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.storeOrder;
    }
);

export const StoreOrderErrorSelector = createImmutableSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.storeOrderError;
    }
);

export const StoreOrderShoppingCartSelector = createImmutableSelector(
    [StoreOrderSelector],
    (storeOrder) => {
        return storeOrder && storeOrder.ShoppingCart || EMPTY_OBJECT;
    }
);

export const StoreOrderTypeSelector = createImmutableSelector(
    [StoreOrderSelector],
    (storeOrder) => {
        return storeOrder.Action || null;
    }
);

export const InvalidInventoryItemSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.invalidInventory;
    }
);

export const CurrentCustomerBillCycleSelector = createSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return currentCustomer.SubscriberTypeDetails && currentCustomer.SubscriberTypeDetails.BillCycle ?
            currentCustomer.SubscriberTypeDetails.BillCycle : null;
    }
);

export const CurrentCustomerNewBillCycleSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return currentCustomer?.SubscriberTypeDetails?.NewBillCycle ||  null;
    }
);

export const CurrentCustomerSubtenantSelector = createImmutableSelector(
    [CurrentCustomerSelector, ActiveSubtenantsSelector],
    (customer, subtenants) => {
        return subtenants && customer.SubtenantId && (subtenants.find((subtenant) => {
            return subtenant.Id.Value === customer.SubtenantId.Value;
        }) || {}).Name;
    });

export const CurrentCustomerIsExternallyManagedARSelector = createSelector(
    [
        CurrentCustomerSelector,
        MetadataCodeTypeSelector(CODES.SubscriberType)
    ],
    (currentCustomer, subscriberTypes) => {
        const subscriberType = subscriberTypes.find((subscriberType) => {
            return subscriberType.Value === currentCustomer.SubscriberTypeCode;
        });
        return !!(subscriberType && subscriberType.AdditionalProperties.find(propEq('externally_managed_accounts_receivable', 'Key')).Value === 'True');
    }
);

export const HasConvergentBillerIdSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return !!selectedCustomer.data.ConvergentBillerId;
    }
);

export const CurrentCustomerSubscriberAccountsSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.subscriberAccounts;
    }
);

export const CurrentCustomerResponsibilityAccountsItemsSelector = createSelector(
    [CurrentCustomerSelector, CurrentCustomerSubscriberAccountsSelector],
    (currentCustomer, currentCustomerSubscriberAccounts) => {
        if (!currentCustomerSubscriberAccounts) {
            return null;
        }

        const buildGroupItems = (group) => {
            return map((item) => {
                return {
                    title: item.hierarchyNodeName,
                    customerId: item.customerId
                };
            })(group);
        };

        const groups = [];

        let residentialSubscriberAccountsGroup;
        const groupedSubscriberAccounts = groupBy(prop('hierarchyId'))(currentCustomerSubscriberAccounts);
        values(groupedSubscriberAccounts).forEach((subscriberAccountsGroup) => {
            //if (subscriberAccountsGroup[0].hierarchyRootSubscriberId === currentCustomer.Id) {
            // really, we should check that the subscriber is the root of the hierarchy
            // because subscribers are limited to a single hierarchy currently, and the root node data is not made
            // available, comparing hierarchy identifiers is well enough
            if (subscriberAccountsGroup[0].hierarchyId === currentCustomer.HierarchyId && parseInt(currentCustomer.SubscriberTypeCode, 10) === SUBSCRIBER_TYPE_CODES.RESIDENTIAL) {
                // specialize on Subscriber's own hierarchy as "Residential", store for later placement atop the list
                residentialSubscriberAccountsGroup = subscriberAccountsGroup;
                return;
            }

            groups.push({
                title: subscriberAccountsGroup[0].hierarchyName,
                items: buildGroupItems(subscriberAccountsGroup)
            });
        });

        if (residentialSubscriberAccountsGroup) {
            groups.unshift({
                title: i18n.translate(LocaleKeys.RESIDENTIAL),
                items: buildGroupItems(residentialSubscriberAccountsGroup)
            });
        }

        return groups;
    }
);

export const SelectedResponsibilityAccountsItemSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.selectedSubscriberAccount;
    }
);

export const SubtenantOptionsSelector = createSelector(
    [ActiveSubtenantsSelector],
    (subtenants) => {
        return subtenants && subtenants.map((subtenant) => {
            return {
                label: subtenant.Name,
                value: subtenant.Id.Value
            };
        });
    }
);

export const CurrentCustomerExternalAuthorizationsSelector = createSelector(
    [
        SelectedCustomerSelector,
        MetadataCodeTypeSelector(CODES.ExternalAuthorizationProvider)
    ],
    (selectedCustomer, externalAuthorizationProviders) => {
        if (!selectedCustomer || !selectedCustomer.data || !selectedCustomer.data.ExternalAuthorizations) {
            return [];
        }
        return selectedCustomer.data.ExternalAuthorizations.map((externalAuthorization) => {
            if (externalAuthorization.length === 0) {
                return {};
            }
            const tableRow = {};
            tableRow.id = externalAuthorization.Id;
            tableRow.externalSubscriberId = externalAuthorization.SubscriberExternalReference ? externalAuthorization.SubscriberExternalReference : i18n.translate(LocaleKeys.NO_RESULTS);
            tableRow.tokenActive = externalAuthorization.TokenActive ? externalAuthorization.TokenActive : i18n.translate(LocaleKeys.NO_RESULTS);
            tableRow.expirationDate = externalAuthorization.Expiration ? externalAuthorization.Expiration : i18n.translate(LocaleKeys.NO_RESULTS);
            const provider = externalAuthorizationProviders.find((item) => {
                return item.Value === `${externalAuthorization.Provider}`;
            });
            tableRow.name = provider ? provider.Name : i18n.translate(LocaleKeys.NO_RESULTS);
            return tableRow;
        });
    }
);

export const ShoppingCartErrorSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.shoppingCartError;
    }
);

const showExistingCart = (cart, error) => {
    return !!pathOr(0, ['Items', 'length'], cart) ||
        path(['code'], error) === INVALID_SHOPPING_CART_FAULT_CODE;
};

export const IsExistingCartSelector = createSelector(
    [ShoppingCartSelector, ShoppingCartErrorSelector],
    (shoppingCart, shoppingCartError) => {
        return showExistingCart(shoppingCart, shoppingCartError);
    }
);

export const IsTransferCartFromCurrentCustomerSelector = createImmutableSelector(
    [ShoppingCartSelector],
    (shoppingCart) => {
        return shoppingCart && !!shoppingCart.TransferFromSubscriberId;
    }
);

export const IsExistingStoreOrderSelector = createImmutableSelector(
    [StoreOrderShoppingCartSelector, StoreOrderErrorSelector],
    (storeOrderShoppingCart, storeOrderError) => {
        return showExistingCart(storeOrderShoppingCart, storeOrderError);
    }
);

export const IsCreatingCustomerSelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.isCreatingCustomer;
    }
);

export const IsCreatingHierarchySelector = createSelector(
    [CustomerStoreSelector],
    (customerStore) => {
        return customerStore.isCreatingHierarchy;
    }
);

export const CurrentCustomerNameSelector = createSelector(
    [CurrentCustomerSelector],
    (customer = {}) => {
        return getCustomerIdentifier(customer);
    }
);

export const ViewLastAttemptErrorSelector = createSelector(
    [CustomerStoreSelector],
    (customer = {}) => {
        return customer.lastAttemptError;
    }
);

export const SelectedTabServicesAndOffersSelector = createImmutableSelector(
    [CustomerStoreSelector],
    (customer) => {
        return customer.selectedTabServicesAndOffersWidget;
    }
);

export const CreateEditCustomerSelector = createSelector(
    [CustomerStoreSelector],
    (customer = {}) => {
        return customer.createCustomerModel ? customer.createCustomerModel.asMutable({
            deep: true
        }) : {};
    }
);

export const CurrentCustomerIsRootNodeSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return currentCustomer.HierarchyId && !currentCustomer.HierarchyParentSubscriberId;
    }
);

export const CurrentCustomerIsInHierarchySelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return currentCustomer.HierarchyId;
    }
);

export const EditCustomerIsRootNodeSelector = createImmutableSelector(
    [CreateEditCustomerSelector],
    (editCustomer) => {
        return editCustomer.HierarchyId && !editCustomer.HierarchyParentSubscriberId;
    }
);

export const EditCustomerIsInHierarchySelector = createImmutableSelector(
    [CreateEditCustomerSelector],
    (editCustomer) => {
        return editCustomer.HierarchyId;
    }
);

export const IsEditCustomerHierarchyRootOrNotInHierarchySelector = createImmutableSelector(
    [EditCustomerIsInHierarchySelector, EditCustomerIsRootNodeSelector],
    (customerIsInHierarchy, customerIsRootNode) => {
        return !customerIsInHierarchy || customerIsRootNode;
    }
);

export const IsCurrentCustomerHierarchyRootOrNotInHierarchySelector = createImmutableSelector(
    [CurrentCustomerIsInHierarchySelector, CurrentCustomerIsRootNodeSelector],
    (customerIsInHierarchy, customerIsRootNode) => {
        return !customerIsInHierarchy || customerIsRootNode;
    }
);

export const CreateEditCustomerConsentsSelector = createSelector(
    [GdprConsentsSelector, CreateEditCustomerSelector],
    (consents, createEditCustomer) => {
        const homeCountry = createEditCustomer.HomeCountry;
        return consentsForCountry(homeCountry, consents);
    }
);

export const NewConnectCustomerConsentsSelector = createSelector(
    [GdprConsentsSelector, EditCartSelector],
    (consents, createEditCustomer) => {
        const homeCountry = createEditCustomer.customerInfo.HomeCountry;
        return consentsForCountry(homeCountry, consents);
    }
);

export const CreateCustomerConsentsMandatorySelector = createSelector(
    [CreateEditCustomerConsentsSelector],
    (consents) => {
        return consents.filter(c => {
            return c.isRequired;
        });
    }
);

export const CreateEditCustomerConsentsOptionalSelector = createSelector(
    [CreateEditCustomerSelector, CreateEditCustomerConsentsSelector, SelectedCustomerConsentsOptionalSelector],
    (createEditCustomer, consents, selectedCustomerOptionalConsents) => {
        if (createEditCustomer.Id) {
            return consents.filter(c => {
                if (!c.isRequired) {
                    const matchedConsent = find(propEq(c.id, 'id'), selectedCustomerOptionalConsents);
                    if (matchedConsent) {
                        c.isAccepted = matchedConsent.isAccepted;
                    }
                    return true;
                }
                return false;
            });
        } else {
            return consents.filter(c => {
                c.isAccepted = undefined;
                return !c.isRequired;
            });
        }
    }
);

export const CreateChildCustomerSelector = createSelector(
    [CustomerStoreSelector, CurrentCustomerSelector],
    (customer = {}, currentCustomer) => {
        return customer.createCustomerModel ? customer.createCustomerModel.merge({
            HomeCountry: pathOr(null, ['createCustomerModel', 'HomeCountry'], customer) || pathOr(null, ['HomeCountry'], currentCustomer),
            SubscriberCurrency: pathOr(null, ['createCustomerModel', 'SubscriberCurrency'], customer) || pathOr(null, ['SubscriberCurrency'], currentCustomer)
        }, {
            deep: true
        }).asMutable({
            deep: true
        }) : {};
    }
);

export const CreateCustomerStateRegionProvinceValueOptions = createSelector([
    CreateEditCustomerSelector,
    MetadataCodeTypeSelector(CODES.AddressCountry),
    MetadataCodeTypeSelector(CODES.AddressStateProvinceRegion)
], (customer, countryCodes, stateCodes) => {
    return addressStateRegionProvinceValueOptionsForCountry(customer.HomeCountry, countryCodes, stateCodes);
});

export const CustomerIsFetchingDataSelector = createSelector(
    [CustomerStoreSelector],
    (customer) => {
        return customer.isFetchingData;
    }
);

// Returns only those mandatory consents that have not been accepted by the customer.
export const EditCustomerConsentsMandatorySelector = createSelector(
    [CreateEditCustomerConsentsSelector, SelectedCustomerAllConsentsSelector],
    (consents, selectedCustomerAllConsents) => {
        return consents.filter(c => {
            if (c.isRequired) {
                const matchedConsent = find(propEq(c.id, 'id'), selectedCustomerAllConsents);
                return (matchedConsent && matchedConsent.isAccepted && !matchedConsent.isModifiedAfterAcceptance) ? false : true;
            }
            return false;
        });
    }
);

export const IsEditingProfileSelector = createSelector(
    [CustomerStoreSelector],
    (customer) => {
        return customer.isEditingProfile;
    }
);

export const SelectedCustomerAdditionalPropertiesSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (customer) => {
        return customer.AdditionalProperties || EMPTY_ARRAY;
    });

export const IsSelectedCustomerWithPinSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (customer) => {
        return customer.SubscriberPinSetup || false;
    });

export const SelectedCustomerAdditionalPropertiesSortedByDisplayOrderSelector = createImmutableSelector(
    [
        SelectedCustomerAdditionalPropertiesSelector,
        SubscriberAdditionalPropertiesSelector
    ],
    (customerAdditionalProperties, subscriberAdditionalPropertyFields) => {
        return getCustomerAdditionalPropertiesSortedByDisplayOrder(customerAdditionalProperties, subscriberAdditionalPropertyFields);
    });

//This section is for the selectors that return the title for all of the sub-pages in customer care
export const CustomerCareDashboardWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.CUSTOMER_DASHBOARD.ACCOUNT)}`;
        }
    }
);

export const CustomerCareInteractionsWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.INTERACTIONS)}`;
        }
    }
);

export const CustomerCareAddressesWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.ADDRESSES.ADDRESSES)}`;
        }
    }
);

export const CustomerCareRedeemGiftOrderWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.REDEEM_GIFT_ORDER.REDEEM_GIFT_ORDER_TITLE)}`;
        }
    }
);

export const CustomerCareLedgerWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.BILLING_AND_PAYMENTS)}`;
        }
    }
);

export const CustomerCareEWalletWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.E_WALLET.TITLE)}`;
        }
    }
);

export const CustomerCareOfferingsWindowTitleSelector = createImmutableSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.OFFERINGS_PAGE.TITLE)}`;
        }
    }
);

export const CustomerCareTreatmentDetailsWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.TREATMENT_DETAILS.HEADING)}`;
        }
    }
);

export const CustomerCareUsageDetailsWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${i18n.translate(LocaleKeys.USAGE_DETAILS.USAGE_FOR)} ${customerIdentifier}`;
        }
    }
);

export const CustomerCareOrderHistoryWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.ORDER_HISTORY.TITLE)}`;
        }
    }
);

export const CustomerCareCasesWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.CASE.CASES)}`;
        }
    }
);

export const CustomerServicesWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.SERVICES)}`;
        }
    }
);

export const CustomerCareInventoryWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.INVENTORY.HEADING)}`;
        }
    }
);

export const CustomerCareActionsWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.DEMO_ACTIONS.ACTIONS)}`;
        }
    }
);

export const CustomerCareProfileWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.PROFILE)}`;
        }
    }
);

export const CustomerCareHouseholdWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.HOUSEHOLD.HOUSEHOLD)}`;
        }
    }
);

export const CustomerCareAccountHierarchyWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.ACCOUNT_HIERARCHY.ACCOUNT_HIERARCHY)}`;
        }
    }
);

export const CustomerCareCouponsWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.COUPONS.COUPONS)}`;
        }
    }
);

export const CustomerCareGrantCouponsWindowTitleSelector = createSelector(
    [CurrentCustomerNameSelector],
    (customerIdentifier) => {
        if (customerIdentifier) {
            return `${customerIdentifier} - ${i18n.translate(LocaleKeys.COUPONS.GRANT_COUPON)}`;
        }
    }
);

export const CustomerCareNewConnectWizardWindowTitleSelector = (() => {
    return i18n.translate(LocaleKeys.NEW_OFFERING_ORDER);
});

export const CurrentCustomerDevicePinStatusSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer = {}) => {
        return selectedCustomer.devicePinStatus || null;
    }
);

export const CurrentCustomerInvoiceDetailStatus = createImmutableSelector(
    [CurrentCustomerSelector, MetadataCodeTypeSelector(CODES.InvoiceDetailLevel)],
    (customer, invoiceDetailLevels) => {
        return getCustomerInvoiceDetailStatus(customer, invoiceDetailLevels);
    });

export const CreateChildModelCustomerSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr({}, ['accountHierarchy', 'createChildModel', 'Customer'], selectedCustomer);
    }
);

export const CreateCountryCurrencyValueOptions = createSelector([
    CreateEditCustomerSelector,
    CreateChildModelCustomerSelector,
    MetadataCodeTypeSelector(CODES.CountryCurrency)
], (customer, childCustomer, currencies) => {
    return currencyValueOptionsForCountry(customer.HomeCountry || childCustomer.HomeCountry, currencies);
});

export const CustomerPreferredTaxCurrenciesSelector = createSelector(
    [
        CreateEditCustomerSelector,
        CustomerInfoSelector,
        MetadataCodeTypeDictionarySelector(CODES.Currency),
        MetadataCodeTypeSelector(CODES.DefaultPreferredTaxReportingCurrencyConfig)
    ],
    (editCustomer, customerInfo, currencies, defaultTaxCurrencies) => {
        const homeCountry = editCustomer.HomeCountry || customerInfo.HomeCountry;
        const customerId = editCustomer.Id || customerInfo.Id;
        if (Object.values(currencies).length) {
            return selectOptionsForAlphabetizedCurrencyValues(currencies, getPreferredTaxDefaultCurrency(defaultTaxCurrencies, homeCountry), customerId);
        }
        return selectOptionsForAlphabetizedCurrencyValues({}, [], customerId);
    }
);

export const DefaultCountryCurrencySelector = createSelector([
    CreateEditCustomerSelector,
    CreateChildModelCustomerSelector,
    MetadataCodeTypeSelector(CODES.CountryCurrency)
], (customer, childCustomer, currencies) => {
    const countryCurrencies = currencyValuesForCountry(customer.HomeCountry || childCustomer.HomeCountry, currencies) || [];
    return countryCurrencies.find((currencyOption) => {
        return currencyOption.IsDefault;
    });
});

export const CurrentCustomerHomeCountryNameSelector = createImmutableSelector(
    [CurrentCustomerSelector, MetadataCodeTypeSelector(CODES.AddressCountry)],
    (customer, homeCountries) => {
        return getCustomerHomeCountryName(customer, homeCountries);
    });

export const CurrentCustomerCurrencyCodeSelector = createImmutableSelector([
    CurrentCustomerSelector
], (selectedCustomer) => {
    return selectedCustomer.SubscriberCurrency;
});

export const CurrentCustomerSubscriberCurrencyNameSelector = createImmutableSelector(
    [CurrentCustomerSelector, MetadataCodeTypeSelector(CODES.Currency)],
    (customer, subscriberCurrencies) => {
        return getCustomerCurrencyName(customer, subscriberCurrencies);
    });

export const CurrentCustomerSubscriberPreferredTaxReportingCurrencySelector = createImmutableSelector(
    [CurrentCustomerSelector, MetadataCodeTypeSelector(CODES.Currency)],
    (customer, subscriberCurrencies) => {
        const selectedCurrency = subscriberCurrencies.find((currency) => {
            return currency.Value === customer.PreferredTaxReportingCurrency;
        });
        return pathOr(customer.PreferredTaxReportingCurrency, ['Name'], selectedCurrency);
    });

export const CurrentCustomerChargeTypeForDetailedTaxationSelector = createImmutableSelector(
    [
        CurrentCustomerSelector
    ],
    (currentCustomer) => {
        const chargeTypes = getChargeTypesForDetailedTaxation();
        if (currentCustomer.ChargeTypesForDetailedTaxation?.length) {
            const formattedChargeTypes = currentCustomer.ChargeTypesForDetailedTaxation.map((type) => {
                const chargeType = chargeTypes.find((cType) => {
                    return cType.value === type;
                });
                return chargeType.text;
            });
            return formattedChargeTypes.join(', ');
        }
        return null;
    }
);

export const CanUserBypassResetSelector = createSelector([
    UserSecurityAttributesSelector
], (userSecurityAttributes) => {
    return hasAccess(userSecurityAttributes, SUBSCRIBER_BYPASS_RESET_PASSWORD);
});

export const CanUserUpdatePinSelector = createSelector([
    UserSecurityAttributesSelector
], (userSecurityAttributes) => {
    return hasAccess(userSecurityAttributes, UPDATE_PIN_ACCESS);
});

export const CanUserUpdateBillingDetailsSelector = createImmutableSelector([
    UserSecurityAttributesSelector
], (userSecurityAttributes) => {
    return hasAccess(userSecurityAttributes, BILL_CYCLE_CHANGE_ACCESS);
});

export const CanUserPerformMiscellaneousAndItemAdjustmentSelector = createSelector([
    UserSecurityAttributesSelector
], (userSecurityAttributes) => {
    return getSecurityAttributeById(userSecurityAttributes, ADJUSTMENT_LIMIT_AND_ACCESS.id) ? true : false;
});

export const CanUserPerformFinanceAdjustmentSelector = createSelector([
    UserSecurityAttributesSelector
], (userSecurityAttributes) => {
    return getSecurityAttributeById(userSecurityAttributes, FINANCE_ADJUSTMENT_LIMIT_AND_ACCESS.id) ? true : false;
});

export const SubscriberStatesMapSelector = createSelector(
    [
        MetadataCodeTypeSelector(CODES.SubscriberState)
    ],
    (states) => {
        const statesMap = {};
        if (states && states.length) {
            for (const code of states) {
                const subscriberStateProperty = findAdditionalProperty(code.AdditionalProperties, 'subscriber_state_code');
                const subscriberStateCodeValue = subscriberStateProperty && parseInt(subscriberStateProperty.Value, 10);
                //An specific BU value overrides the global ones, which are the default value
                if (Number.isSafeInteger(subscriberStateCodeValue) && (!code.Global || statesMap[subscriberStateCodeValue] === undefined)) {
                    statesMap[subscriberStateCodeValue] = code.Name;
                }
            }
        }
        return Object.keys(statesMap).length
            ? statesMap
            : EMPTY_OBJECT;
    }
);

function findAdditionalProperty(additionalProperties, targetProperty) {
    const findPropertyByKey = (property) => {
        return property.Key === targetProperty;
    };
    return additionalProperties.find(findPropertyByKey);
}

export const IsWrappingCustomerSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.isWrappingCustomer;
    }
);

export const CustomerWrapUpCodesSelector = createSelector(
    [MetadataCodeTypeSelector(CODES.CustomerWrapUp)],
    (customerWrapUpCodes) => {
        return customerWrapUpCodes.length
            ? customerWrapUpCodes
            : EMPTY_ARRAY;
    }
);

export const CustomerEnabledWrapUpCodesSelector = createSelector(
    [CustomerWrapUpCodesSelector],
    (customerWrapUpCodes) => {
        const filterEnabledReasonCodes = (reason) => {
            const enabledProp = findByKey('enabled', reason.AdditionalProperties);
            return getBoolOrDefault(pathOr(null, ['Value'], enabledProp), false);
        };

        return customerWrapUpCodes.length
            ? customerWrapUpCodes.filter(filterEnabledReasonCodes)
            : EMPTY_ARRAY;
    }
);

export const IsSubscriberStateReloadRequiredSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return path(['reloadSubscriberState'], selectedCustomer);
    }
);

export const CurrentCustomerCreditLimitSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return pathOr(null, ['CreditCheckClassification', 'CreditLimit'], currentCustomer);
    }
);

export const SelectedCustomerActiveProductsSelector = createImmutableSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return pathOr(null, ['products', 'activeProducts'], selectedCustomer);
    }
);

export const CustomerContactPreferencesSelector = createSelector(
    [CurrentCustomerSelector],
    (currentCustomer) => {
        return pathOr(EMPTY_ARRAY, ['ContactPreferences'], currentCustomer);
    }
);

// Creates a Dictionary of the current customer contact preferences
export const CustomerContactPreferencesDictionarySelector = createSelector(
    [
        CustomerContactPreferencesSelector
    ],
    (customerContactPreferences) => {
        const createContactPreferencesMap = (current, contactRelationship) => {
            const contactMethodId = contactRelationship.ContactMethod.toString();
            current[contactRelationship.ContactEventType]
                ? current[contactRelationship.ContactEventType].push(contactMethodId)
                : current[contactRelationship.ContactEventType] = [contactMethodId];

            return current;
        };

        return Object.keys(customerContactPreferences).length
            ? customerContactPreferences.reduce(createContactPreferencesMap, {})
            : EMPTY_OBJECT;
    }
);

// Creates a Dictionary of the available contact preferences for the current BUNT.
export const AvailableContactPreferencesDictionarySelector = createImmutableSelector(
    [
        MetadataCodeTypeDictionarySelector(CODES.ContactEvent),
        CustomerContactPreferencesDictionarySelector
    ],
    (contactEventTypes, customerContactPreferencesDictionary) => {
        const contactEventTypesClone = clone(contactEventTypes);
        const typesKeys = Object.keys(contactEventTypesClone);
        const getContactPreferencesMap = () => {
            typesKeys.forEach((typeKey) => {
                contactEventTypesClone[typeKey] = customerContactPreferencesDictionary[typeKey] || [];
            });

            return contactEventTypesClone;
        };

        return typesKeys.length
            ? getContactPreferencesMap()
            : EMPTY_OBJECT;
    }
);

//Returns a Dictionary of the customer preferred methods of contact including a csv description for the contact method.
export const CustomerPreferredMethodOfContactSelector = createImmutableSelector(
    [
        MetadataCodeTypeDictionarySelector(CODES.ContactMethod),
        AvailableContactPreferencesDictionarySelector
    ],
    (contactMethods, contactPreferencesDictionary) => {
        const reduceToCSV = (accum, contactMethod, index) => {
            const contactMethodName = pathOr('', ['Name'], contactMethods[contactMethod]);
            return index
                ? `${accum}, ${contactMethodName}`
                : contactMethodName;
        };

        const contactPreferencesWithDescription = {};
        const emptyContactMethodTranslation = i18n.translate(LocaleKeys.CREATE_CUSTOMER_FORM.CONTACT_PREFERENCES.CONTACT_METHODS_EMPTY_PLACEHOLDER);
        for (const prop in contactPreferencesDictionary) {
            contactPreferencesWithDescription[prop] = contactPreferencesDictionary[prop].reduce(reduceToCSV, '') || emptyContactMethodTranslation;
        }

        return Object.keys(contactPreferencesWithDescription).length
            ? contactPreferencesWithDescription
            : EMPTY_OBJECT;
    }
);

export const ShoppingCartItemsSelector = createImmutableSelector(
    [StoreOrderShoppingCartSelector],
    (shoppingCart) => {
        return shoppingCart.Items;
    }
);


export const OfferingIdSelector = createImmutableSelector(
    [ShoppingCartItemsSelector],
    (shoppingCartItems) => {
        return shoppingCartItems && shoppingCartItems.length && shoppingCartItems[0] ? shoppingCartItems[0].OfferingId : undefined;
    }
);

export const OfferingInstanceIdSelector = createImmutableSelector(
    [ShoppingCartItemsSelector],
    (shoppingCartItems) => {
        return shoppingCartItems && shoppingCartItems.length && shoppingCartItems[0] ? shoppingCartItems[0].OfferingInstanceId : undefined;
    }
);

export const CurrentCustomerAdjustmentDueDaysSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (customer) => {
        return Number.isInteger(customer?.SubscriberTypeDetails?.AdjustmentDueDays) ? customer.SubscriberTypeDetails.AdjustmentDueDays : '';
    }
);

export const CurrentCustomerPaymentDueDaysSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (customer) => {
        return Number.isInteger(customer?.SubscriberTypeDetails?.PaymentDueDays) ? customer.SubscriberTypeDetails.PaymentDueDays : '';
    });

export const CurrentCustomerAccountingMethodSelector = createImmutableSelector(
    [CurrentCustomerSelector],
    (customer) => {
        return customer.SubscriberTypeDetails ? customer.SubscriberTypeDetails.AccountingMethod : null;
    });

export const IsCurrentCustomerDisconnectedSelector = createImmutableSelector(
    [CurrentCustomerStateSelector],
    (currentCustomerState) => {
        return currentCustomerState ? currentCustomerState === CUSTOMER_STATE.DISCONNECTED.toString() : false;
    }
);

export const IsPIIDataAccessEnabledSelector = createImmutableSelector(
    [FeatureTogglesSelector, UserSecurityAttributesSelector],
    (featureToggle, userSecurityAttributes) => {
        return !(featureToggle[FeatureToggleConstants.ENABLE_ACCESS_RESTRICTION_TO_PII_DATA] && !hasAccess(userSecurityAttributes, PII_ACCESS));
    });
