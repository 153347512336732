import pathOr from 'ramda/src/pathOr';
import path from 'ramda/src/path';
import find from 'ramda/src/find';
import propEq from 'ramda/src/propEq';
import {
    BoolHelper,
    MetadataHelpers
} from 'invision-core/index';
import i18n from 'invision-core/src/components/i18n/i18n';
import CoreLocaleKeys from 'invision-core/src/locales/core.locale.keys';

const CONSENT_KEYS = {
    COUNTRIES: 'countries',
    NAME: 'consent_name',
    MODIFIED_DATE: 'mod_date',
    REQUIRED: 'is_required',
    TERMS: 'consent_terms'
};

export const addressStateRegionProvinceValueOptionsForCountry = (country, countryCodes, stateCodes) => {
    const states = country && MetadataHelpers.codes.statesForCountry(country, countryCodes, stateCodes) || [];
    return states.length && MetadataHelpers.codes.selectOptionsForCodeValues(states) || null;
};

export const selectOptionsForAlphabetizedCodeValues = (codes, currencies) => {
    return (codes.map((code) => {
        const currencyCode = currencies[findAdditionalProperty(code.AdditionalProperties, 'default_preferred_tax_reporting_currency').Value];
        return {
            label: currencyCode.Name,
            value: findAdditionalProperty(code.AdditionalProperties, 'default_preferred_tax_reporting_currency').Value
        };
    }));
};

export const selectOptionsForAlphabetizedCurrencyValues = (currencies, defaultCurrency, customerId) => {
    if (!defaultCurrency || customerId) {
        return [{
            label: i18n.translate(CoreLocaleKeys.SELECT),
            value: null
        }].concat((Object.values(currencies).map((code) => {
            return {
                label: code.Name,
                value: code.Value
            };
        })));
    }
    return (Object.values(currencies).map((code) => {
        return {
            label: code.Name,
            value: code.Value
        };
    }));
};

export const getPreferredTaxDefaultCurrency = (currencies, country) => {
    const defaultCurrencyCode = currencies.find((code) => {
        return findAdditionalProperty(code.AdditionalProperties, 'country').Value === country;
    });
    return defaultCurrencyCode ? findAdditionalProperty(defaultCurrencyCode.AdditionalProperties, 'default_preferred_tax_reporting_currency').Value : null;
};

export const consentsForCountry = (country, consentsByBU) => {
    const matchedConsents = [];
    consentsByBU.forEach(c => {
        let flag = false;
        const consent = {
            description: '',
            id: c.Value,
            isAccepted: undefined, // *undefined* helps identify whether the CSR intentionally rejected an optional consent.
            isModifiedAfterAcceptance: false,
            isRequired: false,
            modifiedDate: '',
            title: ''
        };
        c.AdditionalProperties.forEach(prop => {
            switch (prop.Key) {
                case CONSENT_KEYS.COUNTRIES:
                    if ((country && prop.Value.match(country)) || !country) {
                        flag = true;
                    }
                    break;
                case CONSENT_KEYS.NAME:
                    consent.title = prop.Value;
                    break;
                case CONSENT_KEYS.MODIFIED_DATE:
                    consent.modifiedDate = prop.Value;
                    break;
                case CONSENT_KEYS.REQUIRED:
                    consent.isRequired = BoolHelper.getBoolOrDefault(prop.Value, true);
                    break;
                case CONSENT_KEYS.TERMS:
                    consent.description = prop.Value;
                    break;
                default:
                    break;
            }
        });
        if (flag) {
            matchedConsents.push(consent);
        }
    });
    return matchedConsents;
};

function findAdditionalProperty(additionalProperties, targetProperty) {
    const findPropertyByKey = (property) => {
        return property.Key === targetProperty;
    };
    return additionalProperties.find(findPropertyByKey);
}


export const currencyValuesForCountry = (countryCode, currencies) => {
    return currencies && MetadataHelpers.codes.currenciesForCountry(countryCode, currencies) || [];
};

export const currencyValueOptionsForCountry = (countryCode, currencies) => {
    const countryCurrencies = currencies && MetadataHelpers.codes.currenciesForCountry(countryCode, currencies) || [];
    return countryCurrencies.length && MetadataHelpers.codes.codeValueOptionsWithoutPlaceholder(countryCurrencies) || null;
};

export const getCustomerCurrencyName = (customer, SubscriberCurrencies) => {
    const selectedCurrency = SubscriberCurrencies.find((currency) => {
        return currency.Value === customer.SubscriberCurrency;
    });
    return pathOr(customer.SubscriberCurrency, ['Name'], selectedCurrency);
};

export const getCustomerHomeCountryName = (customer, homeCountries) => {
    const selectedCountry = homeCountries.find((country) => {
        return country.Value === customer.HomeCountry;
    });
    return pathOr(customer.HomeCountry, ['Name'], selectedCountry);
};

export const getCustomerInvoiceDetailStatus = (customer, invoiceDetailLevels) => {
    let currentLevel = path(['InvoiceConfiguration', 'InvoiceDetailLevel'], customer);
    currentLevel = currentLevel && `${currentLevel}`;
    return path(['Name'], find(propEq(currentLevel, 'Value'))(invoiceDetailLevels));
};

export const getCustomerAdditionalPropertiesSortedByDisplayOrder = (customerAdditionalProperties, subscriberAdditionalPropertyFields) => {
    const selectedCustomerAdditionalProps = customerAdditionalProperties.asMutable({
        deep: true
    });
    const findPropertyById = function(prop) {
        return prop.id === this.Id;
    };
    const sortByDisplayOrder = (a, b) => {
        return subscriberAdditionalPropertyFields.findIndex(findPropertyById, a) - subscriberAdditionalPropertyFields.findIndex(findPropertyById, b);
    };

    return selectedCustomerAdditionalProps.length > 1 && subscriberAdditionalPropertyFields.length > 1
        ? selectedCustomerAdditionalProps.sort(sortByDisplayOrder)
        : selectedCustomerAdditionalProps;
};
