import {createSelector} from 'reselect';
import pathOr from 'ramda/src/pathOr';
import {CurrentBusinessUnitSelector} from 'invision-core/src/components/session/businessunit.selectors';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {hasAccess} from 'invision-core/src/components/security/permission.service';
import {
    MetadataCodeSelector,
    MetadataCodeTypeDictionarySelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {TRANSFER_SERVICES} from '../../security.attributes';
import {SelectedCustomerActiveProductsSelector} from '../../reducers/selectors/customer.selectors';
import {
    createTableViewModel,
    getFormattedServiceAttributeValue
} from './services.list.selectors.helper';
import CustomerCareLocaleKeys from '../../locales/keys';
import {TYPE_OF_SERVICE} from '../../components/customer/servicesAndUsage/servicesAndSharedEntitlements/services/services.list.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {
    ConvergentBillerOrderDetailsSelector,
    CurrentAccountSummarySelector
} from './customer.convergent.biller.selectors';
import {
    EMPTY_ARRAY,
    EMPTY_OBJECT
} from '../constants/common.constants';
import {
    ORDER_CHANGE_TYPE_CODE,
    ORDER_TYPE_CODE,
    REASON_ADDITIONAL_PROPERTY_KEYS
} from '../../customercare.constants';
import {OrdersSelector} from './order.history.selectors';
import {
    HasSubscriberBillingAndNetworkSuspensionAccessSelector,
    HasSubscriberNetworkSuspensionAccessSelector,
    HasUpdateServiceLifecycleStatusAccessSelector
} from './permissions.selectors';
import {SubscriberDeviceFinancingDetailsSelector} from './customer.devices.selectors';
import {addDeviceFinancingToServiceThumbnail} from '../helpers/customer.convergent.biller.selectors.helper';
import {
    IsFullDisconnect
} from './offering.disconnect.selectors';

const BULK_SERVICE_STATUSES = {
    ACTIVE: 1,
    PENDING_ACTIVE: 3,
    PENDING_REMOVED: 4,
    REMOVED: 2
};

export const SERVICE_ACTION_OPTIONS = {
    SUSPEND: 1,
    RESUME: 2,
    USAGE_CAPS: 3,
    TRANSFER: 4
};

const recoverableUIStateSelector = (state) => {
    if (state.customercare.recoverableUiState) {
        return state.customercare.recoverableUiState.bulkServices;
    }
};

export const selectedCustomerBulkItemsSelector = (state) => {
    return pathOr(null, ['customercare', 'customer', 'selectedCustomer', 'bulkItems'], state);
};

export const BulkServicesPageSizeSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageSize;
    }
);

export const BulkServicesSearchStringSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return pathOr(null, ['filterData', 'searchString'], uiState);
    }
);

export const BulkServicesPageNumberSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        return uiState.pageNumber;
    }
);

export const IncludeStatusFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        if (uiState) {
            return pathOr(null, ['filterData', 'includeStatusFilter'], uiState);
        }
    }
);

export const IsFetchingBulkServices = createSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return bulkItems.isFetchingBulkItems;
    }
);

export const IsSuspendingOrResumingServicesSelector = createImmutableSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return bulkItems.isSuspendingOrResumingServices;
    }
);

export const SuspendResumeErrorSelector = createImmutableSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return bulkItems.suspendResumeError;
    }
);

export const BulkServicesStatusesSelector = createSelector(
    [
        IncludeStatusFilterSelector
    ],
    (selectedStatus) => {
        const codes = [
            {
                selected: !selectedStatus || !selectedStatus.value,
                text: i18n.translate(CustomerCareLocaleKeys.ALL),
                value: null
            },
            {
                selected: selectedStatus && selectedStatus.value === BULK_SERVICE_STATUSES.ACTIVE,
                text: i18n.translate(CustomerCareLocaleKeys.ACTIVE),
                value: BULK_SERVICE_STATUSES.ACTIVE
            },
            {
                selected: selectedStatus && selectedStatus.value === BULK_SERVICE_STATUSES.PENDING_ACTIVE,
                text: i18n.translate(CustomerCareLocaleKeys.PENDING_ACTIVE),
                value: BULK_SERVICE_STATUSES.PENDING_ACTIVE
            },
            {
                selected: selectedStatus && selectedStatus.value === BULK_SERVICE_STATUSES.PENDING_REMOVED,
                text: i18n.translate(CustomerCareLocaleKeys.PENDING_REMOVED),
                value: BULK_SERVICE_STATUSES.PENDING_REMOVED
            },
            {
                selected: selectedStatus && selectedStatus.value === BULK_SERVICE_STATUSES.REMOVED,
                text: i18n.translate(CustomerCareLocaleKeys.REMOVED),
                value: BULK_SERVICE_STATUSES.REMOVED
            }
        ];
        return codes;
    }
);

export const BulkServicesSelector = createSelector(
    [
        selectedCustomerBulkItemsSelector
    ],
    (currentCustomerBulkItems) => {
        return currentCustomerBulkItems ? currentCustomerBulkItems.data : null;
    }
);

export const BulkServicesCurrentPageNumberSelector = createSelector(
    [BulkServicesSelector],
    (bulkData) => {
        return bulkData ? bulkData.pageNumber : 0;
    }
);

export const BulkServicesCurrentPageCountSelector = createSelector(
    [BulkServicesSelector],
    (bulkData) => {
        return bulkData ? bulkData.pageCount : 0;
    }
);

export const BulkServicesRecordCountSelector = createSelector(
    [BulkServicesSelector],
    (bulkData) => {
        return bulkData ? bulkData.recordCount : 0;
    }
);

export const ServicesWithFormattedServiceAttributeSelector = createImmutableSelector(
    [
        BulkServicesSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression),
        MetadataCodeSelector(CODES.ServiceLifeCycleStatus)
    ],
    (services, serviceAttributes, regularExpressions, serviceLifeCycleStatusDetails) => {
        if (!services) {
            return EMPTY_OBJECT;
        }

        return Object.assign({}, services, {
            serviceThumbnails: (services.serviceThumbnails || EMPTY_ARRAY).map((serviceThumbnail) => {
                return Object.assign({}, serviceThumbnail, {
                    ServiceAttributeValues: serviceThumbnail.ServiceAttributeValues && serviceThumbnail.ServiceAttributeValues.map((serviceAttribute) => {
                        return Object.assign({}, serviceAttribute, {
                            FormattedValue: getFormattedServiceAttributeValue(serviceAttribute.ServiceAttributeId,
                                serviceAttribute.Value, serviceAttributes, regularExpressions)
                        });
                    }),
                    ServiceLifeCycleStatusDetails: serviceThumbnail.ServiceLifeCycleStatus ?
                        pathOr(EMPTY_ARRAY, ['items'], serviceLifeCycleStatusDetails).find((lifeCycleStatus) => {
                            return lifeCycleStatus.Value === serviceThumbnail.ServiceLifeCycleStatus;
                        }) : undefined,
                    ServiceIdentifierFormatted: getFormattedServiceAttributeValue(serviceThumbnail.ServiceIdentifierAttributeId,
                        serviceThumbnail.ServiceIdentifier, serviceAttributes, regularExpressions)
                });
            })
        });
    }
);

export const ServicesWithDeviceFinancingInfoSelector = createImmutableSelector(
    [
        ServicesWithFormattedServiceAttributeSelector,
        SubscriberDeviceFinancingDetailsSelector
    ],
    (servicesData, deviceFinancingInfo) => {
        if (deviceFinancingInfo && deviceFinancingInfo.length) {
            return Object.assign({}, servicesData, {
                serviceThumbnails: addDeviceFinancingToServiceThumbnail(servicesData.serviceThumbnails, deviceFinancingInfo)
            });
        }
        return servicesData;
    }
);

export const BulkServicesTableDataSelector = createSelector(
    [
        IsFetchingBulkServices,
        ServicesWithDeviceFinancingInfoSelector,
        CurrentBusinessUnitSelector,
        SelectedCustomerActiveProductsSelector,
        CurrentAccountSummarySelector,
        OrdersSelector,
        MetadataCodeSelector(CODES.UnitsOfMeasure)
    ],
    (isFetchingBulkServices, services, currentBusinessUnit, selectedCustomerActiveProducts, currentAccountSummary, ordersSelector, units) => {
        return isFetchingBulkServices === false && units
            ? createTableViewModel(services, currentBusinessUnit.currencyCode, selectedCustomerActiveProducts, (currentAccountSummary || {}).Services, ordersSelector || [], units)
            : EMPTY_ARRAY;
    }
);

export const ReasonCodesSelector = createImmutableSelector(
    [MetadataCodeSelector(CODES.ReasonCodes)],
    (serviceReasonCodes) => {
        return (serviceReasonCodes && serviceReasonCodes.items) ? serviceReasonCodes.items : EMPTY_ARRAY;
    }
);

export const SuspendServiceReasonCodesSelector = createImmutableSelector(
    [ReasonCodesSelector],
    (reasonCodes) => {
        if (reasonCodes) {
            return reasonCodes.filter((reason) => {
                if (reason.AdditionalProperties.find(item => {
                    return item.Key === 'order_type_code';
                }).Value === ORDER_TYPE_CODE.SUSPEND &&
                    (reason.AdditionalProperties.find(item => {
                        return item.Key === 'order_change_type_code';
                    }).Value === ORDER_CHANGE_TYPE_CODE.VOLUNTARY_SUSPENSION ||
                    reason.AdditionalProperties.find(item => {
                        return item.Key === 'order_change_type_code';
                    }).Value === ORDER_CHANGE_TYPE_CODE.NON_PAY_SUSPENSION)) {
                    return reason;
                }
            });
        }
    }
);

export const ResumeServiceReasonCodesSelector = createImmutableSelector(
    [ReasonCodesSelector],
    (reasonCodes) => {
        if (reasonCodes) {
            return reasonCodes.filter((reason) => {
                if (reason.AdditionalProperties.find(item => {
                    return  item.Key === 'order_type_code';
                }).Value === ORDER_TYPE_CODE.RESTORE &&
                    (reason.AdditionalProperties.find(item => {
                        return  item.Key === 'order_change_type_code';
                    }).Value === ORDER_CHANGE_TYPE_CODE.VOLUNTARY_SUSPENSION ||
                    reason.AdditionalProperties.find(item => {
                        return  item.Key === 'order_change_type_code';
                    }).Value === ORDER_CHANGE_TYPE_CODE.NON_PAY_SUSPENSION)) {
                    return reason;
                }
            });
        }
    }
);

export const DisconnectAndRemoveOfferCustomerReasonCodesSelector = createImmutableSelector(
    [ReasonCodesSelector, IsFullDisconnect],
    (reasonCodes, IsFullDisconnect) => {
        const disconnectAndRemoveOfferReasons = (reasonCodes || EMPTY_ARRAY).filter((reason) => {
            return reason.AdditionalProperties.find((reasonAdditionalProperty) => {
                if (IsFullDisconnect) {
                    if (reasonAdditionalProperty.Key === REASON_ADDITIONAL_PROPERTY_KEYS.ORDER_CHANGE_TYPE_CODE
                        && reasonAdditionalProperty.Value === ORDER_CHANGE_TYPE_CODE.DISCONNECT) {
                        return reason;
                    }
                } else {
                    if (reasonAdditionalProperty.Key === REASON_ADDITIONAL_PROPERTY_KEYS.ORDER_TYPE_CODE
                            && reasonAdditionalProperty.Value === ORDER_TYPE_CODE.CHANGE_OF_SERVICE) {
                        return reason;
                    }
                }
            });
        });
        return disconnectAndRemoveOfferReasons || EMPTY_ARRAY;
    }
);

export const DisconnectReasonForOrderDetailsSelector = createImmutableSelector(
    [ConvergentBillerOrderDetailsSelector, ReasonCodesSelector],
    (convergentBillerOrderDetails, disconnectCustomerReasons) => {
        const disconnectReason = (disconnectCustomerReasons || []).find((item) => {
            return convergentBillerOrderDetails && convergentBillerOrderDetails.ReasonCode &&
            convergentBillerOrderDetails.ReasonCode.toString() === item.Value;
        });
        return disconnectReason && disconnectReason.Name;
    }
);

export const ActiveServicesSelector = createImmutableSelector(
    [BulkServicesTableDataSelector],
    (bulkServicesTableData) => {
        return bulkServicesTableData.filter((service) => {
            return service.ServiceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE;
        });
    }
);

export const SuspendedServicesSelector = createImmutableSelector(
    [BulkServicesTableDataSelector],
    (bulkServicesTableData) => {
        return bulkServicesTableData.filter((service) => {
            return service.ServiceStatus === NON_BULK_SERVICE_STATUSES.SUSPENDED;
        });
    }
);

export const HasOffCycleChargesSelector = createImmutableSelector(
    [BulkServicesTableDataSelector],
    (bulkServicesTableData) => {
        return bulkServicesTableData.some((service) => {
            return service.HasOffCycleCharges;
        });
    }
);

export const HasActiveServicesSelector = createImmutableSelector(
    [ActiveServicesSelector],
    (activeServices) => {
        return !!activeServices.length;
    }
);

export const HasSuspendedServicesSelector = createImmutableSelector(
    [SuspendedServicesSelector],
    (suspendedServices) => {
        return !!suspendedServices.length;
    }
);

export const HasSuspendResumePermissionSelector = createImmutableSelector(
    [HasSubscriberBillingAndNetworkSuspensionAccessSelector, HasSubscriberNetworkSuspensionAccessSelector],
    (networkAndBillingSuspensionPermission, networkOnlyBillingSuspensionPermission) => {
        return networkAndBillingSuspensionPermission || networkOnlyBillingSuspensionPermission;
    }
);

export const ServicesSuspendResumeMenuOptionsSelector = createSelector(
    [HasActiveServicesSelector, HasSuspendedServicesSelector, UserSecurityAttributesSelector, HasSuspendResumePermissionSelector],
    (hasActiveServices, hasSuspendedServices, userSecurityAttributes, hasSuspendResumeAccess) => {
        const menuOptions = [];
        if (hasSuspendResumeAccess) {
            if (hasActiveServices) {
                menuOptions.push({
                    title: i18n.translate(CustomerCareLocaleKeys.SUSPEND_SERVICES),
                    id: SERVICE_ACTION_OPTIONS.SUSPEND
                });
            }
            if (hasSuspendedServices) {
                menuOptions.push({
                    title: i18n.translate(CustomerCareLocaleKeys.RESUME_SERVICES),
                    id: SERVICE_ACTION_OPTIONS.RESUME
                });
            }
        }

        menuOptions.push({
            title: i18n.translate(CustomerCareLocaleKeys.USAGE_CAPS.USAGE_CONTROLS_LABEL),
            id: SERVICE_ACTION_OPTIONS.USAGE_CAPS
        });

        if ((hasActiveServices || hasSuspendedServices) && hasAccess(userSecurityAttributes, TRANSFER_SERVICES)) {
            menuOptions.push({
                title: i18n.translate(CustomerCareLocaleKeys.TRANSFER.TRANSFER_SERVICES),
                id: SERVICE_ACTION_OPTIONS.TRANSFER
            });
        }
        return menuOptions;
    }
);

export const BulkServicesSearchErrorSelector = createSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return bulkItems.bulkItemError;
    }
);

export const IncludeTypeOfServiceFilterSelector = createSelector(
    [recoverableUIStateSelector],
    (uiState) => {
        if (uiState) {
            return pathOr(null, ['filterData', 'includeTypeOfServiceFilter'], uiState);
        }
    }
);

export const ServicesTypeSelector = createSelector(
    [
        IncludeTypeOfServiceFilterSelector
    ],
    (selectedTypeOfService) => {
        const codes = [
            {
                selected: !selectedTypeOfService || !selectedTypeOfService.value,
                text: i18n.translate(CustomerCareLocaleKeys.ALL),
                value: null
            },
            {
                selected: selectedTypeOfService && selectedTypeOfService.value === TYPE_OF_SERVICE.BULK,
                text: i18n.translate(CustomerCareLocaleKeys.SERVICES_LIST.TYPES.BULK),
                value: TYPE_OF_SERVICE.BULK
            },
            {
                selected: selectedTypeOfService && selectedTypeOfService.value === TYPE_OF_SERVICE.NON_BULK,
                text: i18n.translate(CustomerCareLocaleKeys.SERVICES_LIST.TYPES.NON_BULK),
                value: TYPE_OF_SERVICE.NON_BULK
            }
        ];
        return codes;
    }
);

export const IsServiceLifeCycleStatusUpdatableSelector = createImmutableSelector(
    [
        HasUpdateServiceLifecycleStatusAccessSelector,
        MetadataCodeSelector(CODES.ServiceLifeCycleStatus)
    ],
    (hasUpdateServiceLifecycleStatusAccess, serviceLifecycleStatusCodes) => {
        return hasUpdateServiceLifecycleStatusAccess && !!serviceLifecycleStatusCodes?.items?.length;
    }
);

export const IsUpdatingServiceTaxSelector = createImmutableSelector(
    [selectedCustomerBulkItemsSelector],
    (bulkItems) => {
        return bulkItems.isUpdatingServiceTax;
    }
);
