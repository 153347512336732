import Immutable from 'seamless-immutable';
import cloneDeep from 'lodash/cloneDeep';
import {createSelector} from 'reselect';
import addIndex from 'ramda/src/addIndex';
import any from 'ramda/src/any';
import clone from 'ramda/src/clone';
import comparator from 'ramda/src/comparator';
import compose from 'ramda/src/compose';
import curry from 'ramda/src/curry';
import descend from 'ramda/src/descend';
import filter from 'ramda/src/filter';
import find from 'ramda/src/find';
import findIndex from 'ramda/src/findIndex';
import flatten from 'ramda/src/flatten';
import forEachObjIndexed from 'ramda/src/forEachObjIndexed';
import groupBy from 'ramda/src/groupBy';
import head from 'ramda/src/head';
import isEmpty from 'ramda/src/isEmpty';
import isNil from 'ramda/src/isNil';
import map from 'ramda/src/map';
import memoize from 'lodash/memoize';
import pathOr from 'ramda/src/pathOr';
import pipe from 'ramda/src/pipe';
import pluck from 'ramda/src/pluck';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import sort from 'ramda/src/sort';
import uniq from 'ramda/src/uniq';
import values from 'ramda/src/values';
import moment from 'moment';

import i18n from 'invision-core/src/components/i18n/i18n';
import {convertStringToNumber} from 'invision-core/src/components/helpers/conversion.helper';
import {BRI_ACTIVATION_STATUS} from 'invision-core/src/constants/pricing.plan.constants';
import {CODES} from 'invision-core/src/components/metadata/codes/codes.constants';
import {createImmutableSelector} from 'invision-core/src/utilities/create.immutable.selector';
import {getFilterService} from 'invision-core/src/components/injectables/injector.helper';
import {IsDbss} from 'invision-core/src/components/session/businessunit.selectors';
import {OFFER_STATUS_INDICATOR_STATUS} from 'invision-core/src/constants/status.constants';
import {parseFilenameFromPath} from 'invision-core/src/components/upload/upload.helper';
import {PRODUCT_CLASSIFICATIONS} from 'invision-core/src/constants/product.constants';
import {UserSecurityAttributesSelector} from 'invision-core/src/components/session/session.selectors';
import {
    getUpperLimitAccess,
    hasAccess
} from 'invision-core/src/components/security/permission.service';
import {
    MetadataCodeLoadedSelector,
    MetadataCodeTypeDictionarySelector,
    MetadataCodeTypeSelector,
    MetadataOptionsForCodeValuesSelector
} from 'invision-core/src/components/metadata/codes/codes.selectors';
import {
    CANCEL_ORDER_ACCESS,
    ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT,
    ORDER_ACCESS,
    REFUND_LIMIT_AND_ACCESS,
    RESUBMIT_ORDER_ACCESS,
    SEND_GIFT_ORDER_ACCESS
} from '../../security.attributes';
import {
    CurrentAccountNumberSelector,
    CurrentCustomerIdSelector,
    CurrentOrderIdSelector,
    RouteParams,
    SelectedCustomerSelector
} from './customer.selectors';
import {WorkflowStateEnum} from '../../utilities/workflow.state.enum';
import CustomerCareLocaleKeys from '../../locales/keys';
import {TREATMENT_STATUS} from '../constants/convergent.biller.constants';
import {AvailableProductsInstancePropertiesLabelSelector} from './products.order.selectors';
import {getFriendlyName} from './billing.payments.invoice.selectors.helper';
import {NA} from '../constants/billing.payments.constants';
import {
    ACTIONS_ITEMS,
    GIFT_REDEMPTION_STATUS,
    IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES,
    ORDER_STATUS,
    ORDER_TYPES,
    PAYMENT_STATUS
} from '../../components/customer/orderHistory/orderDetails/order.details.constants';
import {
    BILLER_RULE_QUANTITY_PRICE_TYPE,
    ENTITLEMENT_SHARE_STATUS
} from './../../customercare.constants';
import {PERIOD_TYPES} from '../helpers/customer.convergent.biller.helper';
import {
    AvailableTypesMapSelector,
    IsAllPaymentInstrumentTypesAvailableSelector
} from './customer.ewallet.selectors';
import {isPaymentInstrumentTypeAvailable} from './customer.ewallet.selectors.helpers';
import {SUPPORTED_PAYMENT_INSTRUMENT_TYPES} from '../../components/customer/ewallet/ewallet.constants';
import {
    getFormattedServiceAttributeValue,
    getSuspensionTypeByCode
} from './services.list.selectors.helper';
import {getCustomerIdentifier} from '../helpers/customer.helper';
import {SubscriberDeviceFinancingDetailsSelector} from './customer.devices.selectors';
import {addDeviceFinancingToServiceThumbnail} from '../helpers/customer.convergent.biller.selectors.helper';
import {IsCoolingPeriodEnabledSelector} from './customer.offerings.selectors';
import {entitlementsSelector} from './shared.entitlements.selectors';

export const ORDER_DETAILS_MORE_MENU_CONSTANTS = {
    CANCEL_ORDER: 1,
    COMPLETE_ORDER: 2,
    CREDIT_ORDER: 3,
    RESEND_GIFT_NOTIFICATION: 4,
    RESUBMIT_ORDER: 5,
    REVOKE_GIFT: 6
};

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = Immutable({});
const ConvergentBillerSelector = createSelector(
    [SelectedCustomerSelector],
    (selectedCustomer) => {
        return selectedCustomer.convergentBiller;
    }
);

export const IsFetchingOrderServiceDetailsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller.isFetchingOrderServiceDetails;
    }
);

export const OrderServiceDetailsErrorSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller.orderServiceDetailsError || null;
    }
);

export const IsUpdatingOrderServiceDetailsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller.isUpdatingOrderServiceDetails;
    }
);

export const UpdateOrderServiceDetailsErrorSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller.updateOrderServiceDetailsError || null;
    }
);

export const IsApplyCreditAvailableSelector = createImmutableSelector(
    [
        AvailableTypesMapSelector,
        IsAllPaymentInstrumentTypesAvailableSelector,
        IsDbss,
        MetadataCodeTypeSelector(CODES.PaymentInstrumentType),
        UserSecurityAttributesSelector
    ],
    (
        availablePaymentInstrumentTypes,
        isAllPaymentInstrumentTypesAvailable,
        isDbss,
        paymentInstrumentTypes,
        userSecurityAttributes
    ) => {
        const isCheckRefundAvailable = isDbss ?
            isPaymentInstrumentTypeAvailable(
                SUPPORTED_PAYMENT_INSTRUMENT_TYPES.CHECK_REFUND,
                paymentInstrumentTypes,
                isAllPaymentInstrumentTypesAvailable,
                availablePaymentInstrumentTypes
            ) : false;
        return  isCheckRefundAvailable && hasAccess(userSecurityAttributes, REFUND_LIMIT_AND_ACCESS) ;
    }
);

export const CurrentConvergentBillerSelector = createSelector(
    [CurrentCustomerIdSelector, ConvergentBillerSelector],
    (currentCustomerId, convergentBillers) => {
        return convergentBillers ? convergentBillers.data[currentCustomerId] : null;
    }
);

export const ConvergentBillerSubscriberSummarySelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.subscriberSummary : null;
    }
);

export const ConvergentBillerSubscriberOfferingSummariesSelector = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (subscriberSummary) => {
        const offeringSummaries = pathOr(null, ['OfferingSummaries'], subscriberSummary);
        return offeringSummaries && !isEmpty(offeringSummaries) ? offeringSummaries : null;
    }
);

export const ConvergentBillerSubscriberSummaryAccountsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return pathOr(null, ['subscriberSummary', 'AccountSummaries'], convergentBiller);
    }
);

export const ConvergentBillerSubscriberRemainingWriteOffAmountSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return pathOr(null, ['subscriberSummary', 'RemainingWriteOffAmount'], convergentBiller);
    }
);

export const ConvergentBillerSubscriberSummaryServicesSelector = createSelector(
    [
        ConvergentBillerSubscriberSummarySelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (subscriberSummary, serviceAttributes, regularExpressions) => {
        if (!subscriberSummary || !subscriberSummary.AccountSummaries) {
            return undefined;
        }
        return pipe(
            pluck('Services'),
            flatten(),
            pluck('ServiceIdentifier'),
            map((service) => {
                return {
                    serviceFriendlyName: service.FriendlyName || service.Value,
                    serviceValue: service.Value,
                    serviceValueFormatted: getFormattedServiceAttributeValue(service.ServiceAttributeId, service.Value, serviceAttributes, regularExpressions)
                };
            })
        )(subscriberSummary.AccountSummaries);
    }
);

export const ConvergentBillerSubscriberSummaryServicesAvailableSelector = createSelector(
    [ConvergentBillerSubscriberSummaryAccountsSelector, RouteParams],
    (subscriberSummaryAccounts, routeParams) => {
        if (!subscriberSummaryAccounts) {
            return undefined;
        }

        const services = [];
        subscriberSummaryAccounts.forEach((account) => {
            if (account.Services) {
                account.Services.forEach((service) => {
                    const hasIndividualEntitlements = service.ServiceIdentifier && getFriendlyName(service.ServiceIdentifier) !== NA;
                    const hasSharedEntitlements = !service.ServiceIdentifier && service.EntitlementBalances.length > 0;
                    if (hasIndividualEntitlements || hasSharedEntitlements) {
                        services.push(service);
                    }
                });
            }
        });
        if (services) {
            const availableServices = services.map((service, index) => {
                return {
                    text: service.ServiceIdentifier ? getFriendlyName(service.ServiceIdentifier) : i18n.translate(CustomerCareLocaleKeys.HOUSEHOLD.SHARED_ENTITLEMENTS),
                    ServiceIdentifier: service.ServiceIdentifier,
                    selected: routeParams.serviceIdentifierValue === service.ServiceIdentifier.Value,
                    service,
                    index
                };
            });
            return availableServices;
        }
        return null;
    }
);

export const ConvergentBillerSubscriberSummaryPostPaidAccountSelector = createSelector(
    [ConvergentBillerSubscriberSummaryAccountsSelector],
    (accountSummaries) => {
        if (!accountSummaries) {
            return EMPTY_ARRAY;
        }

        return accountSummaries.filter((acct) => {
            return acct.PostpaidDetails;
        });
    }
);

export const ConvergentBillerSubscriberSummaryPrePaidAccountSelector = createSelector(
    [ConvergentBillerSubscriberSummaryAccountsSelector],
    (accountSummaries) => {
        if (!accountSummaries) {
            return EMPTY_ARRAY;
        }

        return accountSummaries.filter((acct) => {
            return acct.PrepaidDetails;
        });
    }
);

export const CurrentAccountSummarySelector = createSelector(
    [ConvergentBillerSubscriberSummarySelector, CurrentAccountNumberSelector],
    (subscriberSummary, currentAccountNumber) => {
        if (pathOr(0, ['AccountSummaries', 'length'], subscriberSummary) === 0) {
            return null;
        }

        let currentAccountSummary = null;
        if (currentAccountNumber) {
            currentAccountSummary = find(propEq(currentAccountNumber, 'AccountNumber'))(subscriberSummary.AccountSummaries);
        } else {
            currentAccountSummary = subscriberSummary.AccountSummaries[0];
        }

        if (currentAccountSummary && currentAccountSummary.PostpaidDetails) {
            currentAccountSummary = currentAccountSummary.merge({
                currencyCode: pathOr('', ['PostpaidDetails', 'CurrencyCode'], currentAccountSummary).substring(0, 3)
            }, {
                deep: true
            });
        }
        return currentAccountSummary;
    }
);

export const CurrentPostPaidAccountSummarySelector = createSelector(
    [ConvergentBillerSelector, ConvergentBillerSubscriberSummaryPostPaidAccountSelector, CurrentAccountNumberSelector],
    (convergentBiller, postPaidAccounts, currentAccountNumber) => {
        if (postPaidAccounts.length === 0) {
            return null;
        }

        if (currentAccountNumber) {
            return find(propEq(currentAccountNumber, 'AccountNumber'), postPaidAccounts);
        }

        return postPaidAccounts[0];
    }
);

export const CurrentAccountSummaryTotalSelector = createSelector(
    [CurrentAccountSummarySelector],
    (currentAccountSummary) => {
        return pathOr(null, ['PostpaidDetails', 'Total'], currentAccountSummary);
    }
);

export const CurrentPostPaidAccountSummaryTotalSelector = createSelector(
    [CurrentPostPaidAccountSummarySelector],
    (currentPostpaidAccount) => {
        return pathOr(0, ['PostpaidDetails', 'Balance'], currentPostpaidAccount);
    }
);

export const AccountsNumberSelector = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (subscriberSummary) => {
        const prepaidItems = [];
        const postpaidItems = [];
        if (subscriberSummary && subscriberSummary.AccountSummaries && subscriberSummary.AccountSummaries.length) {
            subscriberSummary.AccountSummaries.forEach((account) => {
                (account.PostpaidDetails ? postpaidItems : prepaidItems).push({
                    title: account.AccountNumber,
                    number: account.AccountNumber
                });
            });
        }

        return [{
            items: prepaidItems,
            title: i18n.translate(CustomerCareLocaleKeys.OFFERING_CHARGE_TYPES.PREPAID)
        }, {
            items: postpaidItems,
            title: i18n.translate(CustomerCareLocaleKeys.OFFERING_CHARGE_TYPES.POSTPAID)
        }];
    }
);

export const NumberOfAccountsSelector = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (subscriberSummary) => {
        if (subscriberSummary && subscriberSummary.AccountSummaries && subscriberSummary.AccountSummaries.length) {
            return subscriberSummary.AccountSummaries.length;
        }
        return 0;
    }
);

export const HasPrepaidAndPostpaidOffersSelector = createSelector(
    [ConvergentBillerSubscriberSummaryAccountsSelector],
    (accounts) => {
        if (accounts && accounts.length > 1) {
            const prepaidAccountServices = accounts.some((account) => {
                return account.PrepaidDetails && account.Services.length > 0;
            });

            const postpaidAccountServices = accounts.some((account) => {
                return account.PostpaidDetails && account.Services.length > 0;
            });
            return (prepaidAccountServices && postpaidAccountServices);
        }
        return false;
    }
);

export const IsCurrentAccountPostpaidSelector = createSelector(
    [CurrentAccountSummarySelector],
    (currentAccount) => {
        return !!(currentAccount && currentAccount.PostpaidDetails);
    }
);

export const CurrentAccountServicesSelectorHelper = createSelector([
    CurrentAccountSummarySelector
], (currentAccountSummary) => {
    return currentAccountSummary && currentAccountSummary.Services ? currentAccountSummary.Services.map((service) => {
        return Object.assign({}, service, {
            SuspendType: getSuspensionTypeByCode(service.SuspendTypeCode)
        });
    }) : [];
});

export const CurrentAccountCurrencyCodeSelector = createSelector([
    CurrentAccountSummarySelector
], (currentAccountSummary) => {
    return pathOr('', ['PostpaidDetails', 'CurrencyCode'], currentAccountSummary).substring(0, 3);
});

export const ConvergentBillerCurrencyCodeSelector = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (convergentBiller) => {
        return convergentBiller && convergentBiller.Currency;
    }
);

export const CurrentSharedEntitlementBalancesSelector = createSelector([
    entitlementsSelector,
    MetadataCodeTypeSelector(CODES.UnitsOfMeasure),
    MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
    MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
], (entitlements, unitsOfMeasure, serviceAttributes, regularExpressions) => {
    if (entitlements && unitsOfMeasure && unitsOfMeasure.length && Object.keys(serviceAttributes).length && Object.keys(regularExpressions).length) {
        return getMappedEntitlementBalances(entitlements || [], unitsOfMeasure, serviceAttributes, regularExpressions);
    } else {
        return null;
    }
});

export const IsARCSubscriptionMgmtFeatureToggleSelector = createSelector([
    CurrentAccountSummarySelector, MetadataCodeTypeSelector(CODES.MicroserviceConfiguration)], (currentAccount, ARCSubscriptionMgmtFeature) => {
    if (currentAccount && currentAccount.PrepaidDetails && ARCSubscriptionMgmtFeature.length) {
        const SubscriptionMgmtFeatures = ARCSubscriptionMgmtFeature[0];
        const ARCSubscriptionMgmtFeaturesToggle = SubscriptionMgmtFeatures ? SubscriptionMgmtFeatures.AdditionalProperties.find((item) => {
            return item.Key === 'is_active';
        }) : [];
        return pathOr(null, ['Value'], ARCSubscriptionMgmtFeaturesToggle);
    } else {
        return false;
    }
});

const getRoundedUsageAmount = (value = 0) => {
    if (value === null) {
        value = 0;
    }
    return value.toFixed(Number.isInteger(value) ? 0 : 2);
};

const getMappedEntitlementBalances = (entitlements, unitsOfMeasure, serviceAttributes, regularExpressions) => {
    return (entitlements || []).map((entitlementBalance) => {
        const abbreviationPropertyKey = 'short_name';
        const unitOfMeasure = unitsOfMeasure.find((unit) => {
            return unit.Value === entitlementBalance.BalanceUnitCode;
        });
        const shortName = unitOfMeasure ? unitOfMeasure.AdditionalProperties.find((prop) => {
            return prop.Key === abbreviationPropertyKey;
        }).Value : undefined;

        if (entitlementBalance.EntitlementIdentifier && entitlementBalance.EntitlementIdentifier.OwningServiceIdentifier) {
            const owningService = entitlementBalance.EntitlementIdentifier.OwningServiceIdentifier;
            entitlementBalance = entitlementBalance
                .setIn(
                    ['EntitlementIdentifier', 'OwningServiceIdentifier', 'FormattedValue'],
                    getFormattedServiceAttributeValue(owningService.ServiceAttributeId, owningService.Value, serviceAttributes, regularExpressions)
                );
        }

        return entitlementBalance
            .set('ShareAction', i18n.translate(entitlementBalance.SharingStatus === ENTITLEMENT_SHARE_STATUS.DISABLED
                ? CustomerCareLocaleKeys.SHARING_ACTIONS.START_SHARING
                : CustomerCareLocaleKeys.SHARING_ACTIONS.STOP_SHARING))
            .set('BalanceConsumed', getRoundedUsageAmount(entitlementBalance.BalanceConsumed || 0))
            .set('BalanceRemaining', getRoundedUsageAmount(entitlementBalance.BalanceRemaining))
            .set('InitialBalance', getRoundedUsageAmount(entitlementBalance.InitialBalance || 0))
            .set('unitOfMeasure', shortName);
    });
};
const getCurrentAccountServices = (currentAccountServices, unitsOfMeasure, currencyCode, serviceAttributeValues, serviceFeatures, serviceAttributes, regularExpressions) => {
    return currentAccountServices.map((service) => {
        const serviceUsageTotal = (service.UsageSubTotals || []).reduce((prev, curr) => {
            return prev + curr.ChargeAmount;
        }, 0);
        const usageCharges = currencyCode && getFilterService()('invCurrency')(serviceUsageTotal, currencyCode);
        const serviceAttribute = serviceAttributeValues && serviceAttributeValues.length ?
            serviceAttributeValues.find(attribute => {
                return attribute && attribute.Value && attribute.ServiceId ? attribute.Value === service.ServiceIdentifier.Value : null;
            }) : null;
        const currentServiceFeatures = serviceFeatures && serviceFeatures.length ? serviceFeatures.filter(feature => {
            return feature.ServiceIdentifierValue === service.ServiceIdentifier.Value;
        }) : null;

        return service.set('EntitlementBalances', getMappedEntitlementBalances(service.EntitlementBalances, unitsOfMeasure, serviceAttributes, regularExpressions))
            .set('usageCharges', usageCharges)
            .set('ServiceFeatures', currentServiceFeatures)
            .setIn(['ServiceIdentifier', 'FormattedValue'], serviceAttribute && getFormattedServiceAttributeValue(serviceAttribute.ServiceAttributeId, serviceAttribute.Value, serviceAttributes, regularExpressions))
            .setIn(['ServiceIdentifier', 'ServiceId'], serviceAttribute && serviceAttribute.ServiceId ? serviceAttribute.ServiceId : null)
            .setIn(['ServiceIdentifier', 'SubscriberProductId'], serviceAttribute && serviceAttribute.SubscriberProductId ? serviceAttribute.SubscriberProductId : null);
    });
};


const removePastInstallments = filter(({InstallmentDueDate}) => {
    return moment(InstallmentDueDate).isAfter(moment());
});
const PROMISE_STATUS = {
    OPEN: 0,
    COMPLETED: 1
};
const removeCompletedInstallments = filter(({PromiseStatus}) => {
    return PromiseStatus === PROMISE_STATUS.OPEN;
});
const dateStringComparator = comparator((a, b) => {
    return moment(a.InstallmentDueDate).isBefore(b.InstallmentDueDate);
});
const getEarliestDueDateInstallment = sort(dateStringComparator);
const assignPeriodToInstallment = curry((periodTypes, result) => {
    const period = periodTypes.find((type) => {
        return parseInt(type.Value, 10) === result.PeriodType;
    }) || {};
    return result
        .set('period', period.Name || i18n.translate(CustomerCareLocaleKeys.TREATMENT_DETAILS.NA));
});

const getMappedPromiseToPayDetails = (promiseToPayDetails, periodTypes) => {
    const validDetails = compose(removeCompletedInstallments, removePastInstallments)(promiseToPayDetails);
    return validDetails.length > 0 ? compose(assignPeriodToInstallment(periodTypes), head, getEarliestDueDateInstallment)(validDetails) : null;
};

export const ConvergentBillerInvoicesSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.invoices : null;
    }
);

export const ConvergentBillerDepositsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.deposits : null;
    }
);

export const ConvergentBillerTreatmentDetailsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.treatmentDetails : null;
    }
);

export const ConvergentBillerOrderServiceDetailsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.orderServiceDetails : null;
    }
);

export const ConvergentBillerOrderServiceDetailsFormattedSelector = createImmutableSelector(
    [
        ConvergentBillerOrderServiceDetailsSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (orderServiceDetails, serviceAttributes, regularExpressions) => {
        const orderServiceDetailsClone = clone(orderServiceDetails);
        orderServiceDetailsClone && Object.values(orderServiceDetailsClone).forEach((orderServiceDetails) => {
            orderServiceDetails.forEach((orderServiceDetail) => {
                (orderServiceDetail.ServiceAttributeValues || EMPTY_ARRAY).forEach((serviceAttribute) => {
                    serviceAttribute.FormattedValue = getFormattedServiceAttributeValue(serviceAttribute.ServiceAttributeId, serviceAttribute.Value, serviceAttributes, regularExpressions);
                });
            });
        });
        return orderServiceDetailsClone;
    }
);

export const ConvergentBillerIsInPromiseToPay = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (subscriberSummary) => {
        return !!subscriberSummary && subscriberSummary.TreatmentStatus === TREATMENT_STATUS.IN_TREATMENT_WITH_P2P;
    }
);

export const ConvergentBillerIsInTreatment = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (subscriberSummary) => {
        return !!subscriberSummary && subscriberSummary.TreatmentStatus !== TREATMENT_STATUS.NOT_IN_TREATMENT;
    }
);

export const ConvergentBillerCurrentPromiseToPayInstallment = createSelector(
    [ConvergentBillerIsInPromiseToPay, ConvergentBillerTreatmentDetailsSelector, MetadataCodeLoadedSelector(CODES.PeriodType), MetadataCodeTypeSelector(CODES.PeriodType)],
    (isInPromiseToPay, treatmentDetails, periodLoaded, periods = []) => {
        return isInPromiseToPay && periodLoaded && treatmentDetails ? getMappedPromiseToPayDetails(treatmentDetails.PromiseToPayDetails, periods) : null;
    }
);

const startDateComparator = (a, b) => {
    return moment(a.InstallmentStartDate).diff(b.InstallmentStartDate, 'seconds');
};
const mapInstallment = (installment, index) => {
    return installment.set('isPaid', installment.PromiseStatus === PROMISE_STATUS.COMPLETED ? i18n.translate(CustomerCareLocaleKeys.YES) : i18n.translate(CustomerCareLocaleKeys.NO))
        .set('installmentNumber', index + 1); // Want it to be non-zero based
};

export const ConvergentBillerCurrentPromiseToPaySchedule = createSelector(
    [ConvergentBillerTreatmentDetailsSelector],
    (treatmentDetails) => {
        return treatmentDetails && treatmentDetails.PromiseToPayDetails ?
            compose(addIndex(map)(mapInstallment), sort(startDateComparator))(treatmentDetails.PromiseToPayDetails) : null;
    }
);

export const PromiseToPayCreationErrorSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.promiseToPayCreationError : null;
    }
);

export const UpdateSubscriberTreatmentErrorSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.updateSubscriberTreatmentError : null;
    }
);

export const MostRecentInvoiceSelector = createSelector(
    [CurrentAccountSummarySelector, ConvergentBillerInvoicesSelector],
    (currentAccountSummary, invoices) => {
        if (currentAccountSummary && currentAccountSummary.PostpaidDetails && invoices) {
            const invoiceId = currentAccountSummary.PostpaidDetails.LastInvoiceId;
            return invoiceId ? find(propEq(invoiceId, 'InvoiceId'))(invoices) : null;
        }
        return null;
    }
);

export const MostRecentInvoiceBalanceSelector = createSelector(
    [MostRecentInvoiceSelector],
    (mostRecentInvoice) => {
        return mostRecentInvoice ? mostRecentInvoice.Balance : null;
    }
);

export const ConvergentBillerAccountDetailsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.accountDetails : null;
    }
);

export const ConvergentBillerAccountHasBulkProducts = createSelector(
    [ConvergentBillerAccountDetailsSelector],
    (accountDetails) => {
        let hasBulkProducts = false;
        if (accountDetails && accountDetails.offerings) {
            hasBulkProducts = accountDetails.offerings.some((offer) => {
                return offer.Options && offer.Options.length ? offer.Options.some((option) => {
                    return option.IsBulkWithServices;
                }) : false;
            });
        }
        return hasBulkProducts;
    }
);

export const ConvergentBillerAccountOfferingsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return pathOr(false, ['accountDetails', 'offerings', 'length'], convergentBiller)
            ? convergentBiller.accountDetails.offerings.asMutable({
                deep: true
            })
            : null;
    }
);

export const ConvergentBillerAccountServiceFeaturesSelector = createSelector(
    [ConvergentBillerAccountOfferingsSelector],
    (offerings) => {
        if (offerings && offerings.length) {
            const serviceFeatures = [];
            offerings.forEach(offer => {
                if (offer.ServiceFeatures && offer.ServiceFeatures.length) {
                    offer.ServiceFeatures.forEach(feature => {
                        if (feature.Status !== OFFER_STATUS_INDICATOR_STATUS.REMOVED) {
                            serviceFeatures.push(feature);
                        }
                    });
                }
            });
            return serviceFeatures;
        }
        return EMPTY_ARRAY;
    }
);

export const ConvergentBillerAccountOptionsServiceAttributeValuesSelector = createSelector(
    [ConvergentBillerAccountOfferingsSelector],
    (offerings) => {
        if (offerings && offerings.length) {
            const serviceAttributeValues = [];
            offerings.forEach(offer => {
                if (offer && offer.Options && offer.Options.length) {
                    offer.Options.forEach(option => {
                        if (option && option.ServiceAttributeValues) {
                            option.ServiceAttributeValues.forEach(serviceAttribute => {
                                return serviceAttributeValues.push(Object.assign({}, serviceAttribute, {
                                    SubscriberProductId: option.SubscriberProductId
                                }));
                            });
                        }
                    });
                }
            });
            return serviceAttributeValues;
        }

        return EMPTY_ARRAY;
    }
);

export const CurrentAccountServicesSelector = createSelector(
    [
        CurrentAccountServicesSelectorHelper,
        MetadataCodeTypeSelector(CODES.UnitsOfMeasure),
        CurrentAccountCurrencyCodeSelector,
        ConvergentBillerAccountOptionsServiceAttributeValuesSelector,
        ConvergentBillerAccountServiceFeaturesSelector,
        MetadataCodeTypeDictionarySelector(CODES.ServiceAttribute),
        MetadataCodeTypeDictionarySelector(CODES.RegularExpression)
    ],
    (currentAccountServices, unitsOfMeasure, currencyCode, serviceAttributeValues, serviceFeatures, serviceAttributes, regularExpressions) => {
        return (unitsOfMeasure && unitsOfMeasure.length) > 0 ? getCurrentAccountServices(currentAccountServices, unitsOfMeasure, currencyCode, serviceAttributeValues, serviceFeatures, serviceAttributes, regularExpressions) : EMPTY_ARRAY;
    }
);

export const CurrentAccountServicesWithDeviceFinancingInfoSelector = createImmutableSelector(
    [
        CurrentAccountServicesSelector,
        SubscriberDeviceFinancingDetailsSelector
    ],
    (services, deviceFinancingInfo) => {
        return addDeviceFinancingToServiceThumbnail(services, deviceFinancingInfo);
    }
);

export const CurrentAccountServiceUsageChargeSelector = createSelector(
    [CurrentAccountServicesSelector, RouteParams],
    (currentAccountServices, routeParams) => {
        if (currentAccountServices) {
            const foundService = currentAccountServices.find((account) => {
                return account.ServiceIdentifier.Value === routeParams.serviceIdentifierValue;
            });
            if (foundService) {
                return foundService.usageCharges;
            }
        }
        return null;
    }
);


const sortedBillerType = (option) => {
    if (option.BillerTypeAmounts && option.BillerTypeAmounts.length > 1) {
        option.BillerTypeAmounts.sort((a, b) => {
            return b.TotalAmount - a.TotalAmount;
        });
    }
    return option;
};

export const ConvergentBillerTypeAmountSortedSelector = createSelector(
    [ConvergentBillerAccountOfferingsSelector],
    (offerings) => {
        const clonedOfferings = offerings ? offerings.slice() : null;

        if (clonedOfferings) {
            clonedOfferings.forEach((offer) => {
                offer.Options.forEach((option) => {
                    sortedBillerType(option);
                });
            });
        }
        return clonedOfferings || null;
    }
);

export const OfferingIsOffCycleMapSelector = createImmutableSelector(
    [ConvergentBillerAccountOfferingsSelector],
    (offerings) => {
        return (offerings || []).reduce((acc, offer) => {
            acc[offer.OfferingInstanceId] = offer.HasOffCycleCharges;
            return acc;
        }, {});
    }
);

export const ConvergentBillerSortedAccountOfferingsSelector = createSelector(
    [ConvergentBillerTypeAmountSortedSelector],
    (offerings) => {
        let sortedOffers = offerings ? offerings.slice() : null;
        if (sortedOffers && sortedOffers.length > 1) {
            const offerTotalAmount = descend(prop('TotalAmount'));
            sortedOffers = sort(offerTotalAmount, offerings);
        }
        return sortedOffers;
    }
);

const paymentAccountNumberMatches = (accountNumbers, currentAccount) => {
    return (option) => {

        // NOTE: `AccountNumber` is not defined for usage and entitlements
        return (accountNumbers.includes(option.AccountNumber) &&
            option.AccountNumber === currentAccount.PaymentAccountNumber) ||
        (option.AccountNumber === undefined ||
            option.Status === OFFER_STATUS_INDICATOR_STATUS.PENDING_ACTIVE) &&
        option.Status !== OFFER_STATUS_INDICATOR_STATUS.REMOVED ?
            option : null;
    };
};

export const ConvergentBillerAccountOfferingsForSelectedAccountSelector = createSelector(
    [
        CurrentAccountSummarySelector,
        ConvergentBillerSubscriberSummarySelector,
        ConvergentBillerSortedAccountOfferingsSelector,
        ConvergentBillerSubscriberOfferingSummariesSelector,
        IsCoolingPeriodEnabledSelector
    ],
    (currentAccount, subscriberSummary, offerings, offeringSummaries, isCoolingPeriodEnabled) => {
        const validOffers = [];
        if (offerings && subscriberSummary) {
            const accountNumbers = subscriberSummary.AccountSummaries.map((account) => {
                return account.PaymentAccountNumber;
            });
            offerings.forEach((offer) => {
                const newOfferOptions = filter(paymentAccountNumberMatches(accountNumbers, currentAccount), offer.Options);
                const orderContractInstance = offeringSummaries && offeringSummaries.length ? offeringSummaries.find((offeringSummary) => {
                    return offeringSummary.OfferingId === offer.OfferingId;
                }) : null;
                const currentPaymentInstrument = pathOr(null, ['PaymentInstrument'], (subscriberSummary.OfferingSummaries || []).find((offerSummary) => {
                    return offerSummary.OfferingInstanceId === offer.OfferingInstanceId;
                }));
                const offeringInstancePaymentInstrument = currentPaymentInstrument ? {
                    PaymentInstrument: currentPaymentInstrument
                } : null;

                if (newOfferOptions.length || (offer.IsCoolingOff && isCoolingPeriodEnabled)) {
                    if (orderContractInstance && orderContractInstance.OrderContractInstanceThumbnail) {
                        validOffers.push(Object.assign({}, offer, {
                            Options: newOfferOptions,
                            OrderContractInstance: orderContractInstance.OrderContractInstanceThumbnail
                        }, offeringInstancePaymentInstrument));
                    } else {
                        validOffers.push(Object.assign({}, offer, {
                            Options: newOfferOptions
                        }, offeringInstancePaymentInstrument));
                    }
                }
            });
        }
        return validOffers.length ? validOffers : null;
    }
);

export const CombinedEntitlementTypesSelector = createSelector(
    ConvergentBillerAccountOfferingsForSelectedAccountSelector,
    CurrentAccountServicesWithDeviceFinancingInfoSelector,
    CurrentSharedEntitlementBalancesSelector,
    (acc, currentAccountServices, currentSharedEntitlementBalances) => {
        const connectedDates = {};
        const HasOffCycleCharges = {};
        const opts = flatten(pluck('Options', acc || EMPTY_ARRAY));
        opts.forEach((el) => {
            if ( el && el.ServiceIdentifierValue && el.ConnectedDate) {
                connectedDates[el.ServiceIdentifierValue] = moment.utc(el.ConnectedDate).toISOString();
            }
            if (el && el.ServiceIdentifierValue) {
                HasOffCycleCharges[el.ServiceIdentifierValue.toString()] = acc.find(item => {
                    return item.OfferingInstanceId === el.OfferingInstanceId;
                }).HasOffCycleCharges;
            }
        });
        return Immutable([
            ...(currentAccountServices || EMPTY_ARRAY),
            ...( currentSharedEntitlementBalances ? [Immutable({
                EntitlementBalances: currentSharedEntitlementBalances
            })] : EMPTY_ARRAY)
        ].map(element => {
            let el = cloneDeep(element);
            el.HasOffCycleCharges = el.ServiceIdentifier && el.ServiceIdentifier.Value ? HasOffCycleCharges[el.ServiceIdentifier.Value] : null;
            el = Immutable(el);
            if ( el && el.EntitlementBalances && el.EntitlementBalances.length) {
                return el.set('EntitlementBalances', el.EntitlementBalances.asMutable().map((entitlement) => {
                    if (entitlement.EntitlementIdentifier && entitlement.EntitlementIdentifier.GroupCode) {
                        return entitlement;
                    }
                    if (entitlement.EntitlementIdentifier && entitlement.EntitlementIdentifier.OwningServiceIdentifier) {
                        return entitlement.set('ConnectedDate', connectedDates[pathOr( -1, ['EntitlementIdentifier', 'OwningServiceIdentifier', 'Value'], entitlement )] || null);
                    }
                    if ( entitlement.ServiceIdentifier ) {
                        return entitlement.set('ConnectedDate', connectedDates[pathOr( -1, ['ServiceIdentifier', 'Value'], entitlement )] || null);
                    }
                }));
            } else if ( el && el.ServiceIdentifier ) {
                return el.set('ConnectedDate', connectedDates[pathOr( -1, ['ServiceIdentifier', 'Value'], el )] || null);
            }
            return el;
        }));
    }
);

export const IsFetchingAccountDetailsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isFetchingAccountDetails : false;
    }
);

export const ConvergentBillerAccountUsageDetailsSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.usageDetails : null;
    }
);

export const ConvergentBillerSelectedServiceIdSelector = createSelector(
    [CurrentConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.selectedServiceId : null;
    }
);

export const ConvergentBillerAccountUsageDetailsByServiceIdSelector = createSelector(
    [ConvergentBillerAccountUsageDetailsSelector, ConvergentBillerSelectedServiceIdSelector],
    (convergentBillerAccountUsageDetails, convergentBillerSelectedServiceId) => {
        return pathOr(null, [convergentBillerSelectedServiceId], convergentBillerAccountUsageDetails);
    }
);

export const IsFetchingAccountUsageDetailsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isFetchingAccountUsageDetails : false;
    }
);

export const IsFetchingInvoicesSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isFetchingInvoices : false;
    }
);

export const IsFetchingDepositsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isFetchingDeposits : false;
    }
);

export const IsFetchingSubscriberSummarySelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isFetchingSubscriberSummary : false;
    }
);

export const IsFetchingTreatmentDetailsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isFetchingTreatmentDetails : false;
    }
);

export const IsCreatingConvergentBillerAdjustmentSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isCreatingConvergentBillerAdjustment : false;
    }
);

export const IsFetchingOrderDetailsSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return !!(convergentBiller && convergentBiller.isFetchingOrderDetails);
    }
);

export const ConvergentBillerOrderDetailsSelector = createSelector(
    [CurrentConvergentBillerSelector, CurrentOrderIdSelector],
    (convergentBiller, currentOrderId) => {
        return pathOr(null, ['orderDetails', currentOrderId], convergentBiller);
    }
);

export const CurrentOrderDetailsPaymentInstrumentSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector, CurrentOrderIdSelector],
    (convergentBiller, currentOrderId) => {
        let paymentInstruments = pathOr(null, ['PaymentInstruments'], convergentBiller);
        return paymentInstruments = (paymentInstruments || []).filter((paymentInstrument) => {
            return paymentInstrument.PaymentStatus !== PAYMENT_STATUS.PENDING;
        });
    }
);

export const ConvergentBillerOrderSubscribersSelector = createImmutableSelector(
    [CurrentConvergentBillerSelector, CurrentOrderIdSelector],
    (convergentBiller, currentOrderId) => {
        return pathOr(null, ['orderSubscribers', currentOrderId], convergentBiller);
    }
);

export const ConvergentBillerOrderTransferredSubscriberSelector = createImmutableSelector(
    [ConvergentBillerOrderDetailsSelector, ConvergentBillerOrderSubscribersSelector, CurrentCustomerIdSelector],
    (currentOrder, currentSubscribers, currentCustomerId) => {
        if (currentOrder && currentOrder.TransferDetail && currentSubscribers && currentSubscribers.length) {
            const isTransferredFrom = currentOrder.TransferDetail.FromSubscriberId === convertStringToNumber(currentCustomerId);

            const trasferedSubscriberId = isTransferredFrom ?
                currentOrder.TransferDetail.ToSubscriberId : currentOrder.TransferDetail.FromSubscriberId;

            const transferedSubscriber = currentSubscribers.find((subscriber) => {
                return subscriber.Id === trasferedSubscriberId;
            });

            return transferedSubscriber ? {
                isTransferredFrom: isTransferredFrom,
                id: transferedSubscriber.Id,
                displayName: getCustomerIdentifier(transferedSubscriber)
            } : null;
        }

        return null;
    }
);

export const OrderStoresSelector = createImmutableSelector(
    [
        ConvergentBillerOrderDetailsSelector,
        MetadataCodeTypeSelector(CODES.Stores)
    ],
    (orderDetails, stores) => {
        if (orderDetails && orderDetails.Items.length) {
            const storeIds = [];
            orderDetails.Items.forEach((item) => {
                if (pathOr(0, ['InventoryItems', 'length'], item)) {
                    item.InventoryItems.forEach((inventoryItem) => {
                        if (inventoryItem.StoreId) {
                            storeIds.push(inventoryItem.StoreId);
                        }
                    });
                }
            });

            if (storeIds.length) {
                return uniq(storeIds).map((storeId) => {
                    const stringStoreId = storeId.toString();
                    const store = stores.find((store) => {
                        return store.Value === stringStoreId;
                    });
                    return {
                        storeId,
                        storeName: store.Name
                    };
                });
            } else {
                return EMPTY_ARRAY;
            }
        } else {
            return EMPTY_ARRAY;
        }
    }
);

export const OrderDetailsPurchaseOrderNumberSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return pathOr(null,
            ['Items', '0', 'PurchaseOrderNumber'], currentOrderDetails);
    }
);

export const HasOrderDetailTotalsSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (orderDetails) => {
        return !!(orderDetails &&
            (orderDetails.Totals &&
                (orderDetails.Totals.SubTotalAmount > 0 ||
                    orderDetails.Totals.DiscountAmount > 0 ||
                    orderDetails.Totals.LateFee > 0 ||
                    orderDetails.Totals.TerminationFee > 0 ||
                    orderDetails.Totals.TaxAmount > 0 ||
                    orderDetails.Totals.TotalAmount > 0 ||
                    orderDetails.Totals.ReturnAmount > 0) ||
                orderDetails.ShippingMethod));
    }
);

export const ConvergentBillerOrderProductIdsSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return isNil(currentOrderDetails) ? EMPTY_ARRAY : currentOrderDetails.Items.map((item) => {
            return item.Product.Id;
        });
    }
);

export const ConvergentBillerOrderPaymentInstruments = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (details) => {
        return details && details.PaymentInstruments ? details.PaymentInstruments.map(({PaymentInstrument}) => {
            return PaymentInstrument;
        }).asMutable({
            deep: true
        }) : EMPTY_ARRAY;
    }
);

export const CurrentOrderItemTransactionSelector = createSelector(
    [CurrentConvergentBillerSelector, CurrentOrderIdSelector],
    (convergentBiller, currentOrderId) => {
        return pathOr(null, ['orderTransactions', currentOrderId], convergentBiller);
    }
);

const giftIsNotReedemed = ({GiftRedemption}) => {
    return GiftRedemption && GiftRedemption.Status === GIFT_REDEMPTION_STATUS.AVAILABLE;
};

const orderCanBeCredited = (orderTransaction, paymentInstruments, userSecurityAttributes) => {
    const totalAmount = pathOr(0, ['PurchaseOrder', 'Totals', 'TotalCreditableAmount'], orderTransaction);
    return totalAmount > 0 && paymentInstruments.length && getUpperLimitAccess(userSecurityAttributes, ISSUE_CREDIT_STANDARD_CURRENCY_LIMIT.id) > 0;
};

const orderCanBeCompleted = ({OrderStatus}, userSecurityAttributes) => {
    return hasAccess(userSecurityAttributes, ORDER_ACCESS) && OrderStatus === ORDER_STATUS.PENDING;
};

const canResendGiftNotification = ({Type, GiftRedemption}, userSecurityAttributes) => {
    return hasAccess(userSecurityAttributes, SEND_GIFT_ORDER_ACCESS)
        && Type === ORDER_TYPES.GIFT && GiftRedemption && !GiftRedemption.RedemptionOrderId && !GiftRedemption.Revoked;
};

const canResubmitOrder = ({Items = [], OrderStatus}, userSecurityAttributes) => {
    const isBulkWithServices = any(item => {
        return item.IsBulkWithServices;
    }, Items);
    return hasAccess(userSecurityAttributes, RESUBMIT_ORDER_ACCESS)
        && !isBulkWithServices && OrderStatus === ORDER_STATUS.OPEN;
};

const canCancelOrder = ({CanBeCanceled}, userSecurityAttributes) => {
    return hasAccess(userSecurityAttributes, CANCEL_ORDER_ACCESS) && CanBeCanceled;
};

export const OrderDetailsMoreMenuItemsSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector, CurrentOrderItemTransactionSelector, ConvergentBillerOrderPaymentInstruments, IsDbss, UserSecurityAttributesSelector],
    (orderDetails, orderTransaction, paymentInstruments, isDbss, userSecurityAttributes) => {
        const items = [];
        if (orderDetails) {
            if (canCancelOrder(orderDetails, userSecurityAttributes)) {
                items.push({
                    id: ORDER_DETAILS_MORE_MENU_CONSTANTS.CANCEL_ORDER,
                    title: i18n.translate(CustomerCareLocaleKeys.CANCEL_ORDER)
                });
            }
            if (orderCanBeCompleted(orderDetails, userSecurityAttributes)) {
                items.push({
                    id: ORDER_DETAILS_MORE_MENU_CONSTANTS.COMPLETE_ORDER,
                    title: i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.COMPLETE_ORDER)
                });
            }
            if (!isDbss && orderCanBeCredited(orderTransaction, paymentInstruments, userSecurityAttributes)) {
                items.push({
                    id: ORDER_DETAILS_MORE_MENU_CONSTANTS.CREDIT_ORDER,
                    title: i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.CREDIT.APPLY)
                });
            }
            if (canResendGiftNotification(orderDetails, userSecurityAttributes)) {
                items.push({
                    id: ORDER_DETAILS_MORE_MENU_CONSTANTS.RESEND_GIFT_NOTIFICATION,
                    title: i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.RESEND_NOTIFICATION)
                });
            }
            if (canResubmitOrder(orderDetails, userSecurityAttributes) && !orderDetails.FutureDatedOrder) {
                items.push({
                    id: ORDER_DETAILS_MORE_MENU_CONSTANTS.RESUBMIT_ORDER,
                    title: i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.RESUBMIT_ORDER)
                });
            }
            if (giftIsNotReedemed(orderDetails)) {
                items.push({
                    id: ORDER_DETAILS_MORE_MENU_CONSTANTS.REVOKE_GIFT,
                    title: i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.REVOKE_GIFT)
                });
            }
        }
        return items.length ? items : null;
    }
);

export const OrderDetailsNotificationHistorySelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (orderDetails) => {
        const history = pathOr([], ['GiftRedemption', 'Notifications'], orderDetails);
        const reversed = Immutable.asMutable(history).reverse();
        return Immutable(reversed);
    }
);

const getMappedBRIs = (bris, bulkDetails, periodTypes, bulkChargeTypes) => {
    const activeBrisWithAmount = bris.filter((bri) => {
        //The recurring charge are allowed to be 0, in that case, we need to display the priceData, hence checking for null priceData value
        return bri.Amount !== null && bri.DefaultActivationStatus !== BRI_ACTIVATION_STATUS.INACTIVE;
    });
    const pricingMap = groupBy(prop('BillerRuleConfigurationId'))(bulkDetails);
    return activeBrisWithAmount.map((briWithAmount) => {
        const {Name, BillerRuleConfigurationId, Amount, CurrencyCode, DiscountAmount, Type, RecurringPeriodType,
            BulkChargeTypeCode, QuantityPricingTypeCode, StartQuantity, EndQuantity, FullUpfrontPayment} = briWithAmount;
        const schedule = pricingMap[BillerRuleConfigurationId] || [];
        const pricingSchedule = schedule.map((pricingEntry) => {
            const isAfterStartDate = moment(pricingEntry.StartDate).isBefore(new Date()) || !pricingEntry.StartDate;
            const isBeforeEndDate = pricingEntry.EndDate && moment(pricingEntry.EndDate).isAfter(new Date());
            return pricingEntry
                .set('isActive', isAfterStartDate && (!pricingEntry.EndDate || isBeforeEndDate))
                .set('CurrencyCode', CurrencyCode);
        }).sort((a, b) => {
            return moment(a.StartDate).isBefore(b.StartDate) ? -1 : 1; // Note: StartDates cannot be the same, so no need to have a 0 case
        });
        let recurringDescription = (periodTypes.find((type) => {
            return RecurringPeriodType === parseInt(type.Value, 10);
        }));
        const bulkChargeType = bulkChargeTypes.find((chargeType) => {
            return BulkChargeTypeCode === parseInt(chargeType.Value, 10);
        });
        if (recurringDescription) {
            recurringDescription = `${i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.RECURRING)} ${recurringDescription.Name}`;
        } else if (FullUpfrontPayment) {
            recurringDescription = i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.FULL_PRICE_PLUS_TAX);
        } else {
            recurringDescription = i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.ONE_TIME);
        }
        const nameAddition = bulkChargeType ? ` (${bulkChargeType.Name})` : '';
        const name = `${Name}${nameAddition}`;
        return {
            Amount,
            BillerRuleConfigurationId,
            CurrencyCode,
            FullUpfrontPayment,
            Type,
            DiscountAmount,
            bulkChargeType,
            name,
            pricingSchedule,
            recurringDescription,
            QuantityPricingTypeCode,
            StartQuantity,
            EndQuantity
        };
    });
};

const getBriAmountTotal = (Amount, itemTotalQuantity, itemIncrementalQuantity, bulkDetails, isBulk, QuantityPricingTypeCode, StartQuantity, EndQuantity, BillerRuleConfigurationId, adHocDetails, isDiscount = false) => {
    if (isBulk && !isDiscount) {
        return pathOr(0, ['Amount'], find(propEq(BillerRuleConfigurationId, 'BillerRuleConfigurationId'), bulkDetails));
    } else if (QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TIERED) {
        return (itemIncrementalQuantity >= StartQuantity && itemIncrementalQuantity <= EndQuantity) ? Amount : 0;
    } else if (QuantityPricingTypeCode === BILLER_RULE_QUANTITY_PRICE_TYPE.TAPERED) {
        return (itemTotalQuantity >= StartQuantity && itemTotalQuantity <= EndQuantity) ? Amount : 0;
    } else if (adHocDetails && adHocDetails.Amount && !isDiscount) {
        return adHocDetails.Amount;
    }

    return Amount || 0;
};

const getPaymentTypeMap = (bris, defaultCurrencyCode, itemTotalQuantity, itemIncrementalQuantity, bulkDetails, adHocDetails) => {
    const paymentTypeMap = groupBy(prop('recurringDescription'))(bris);
    Object.keys(paymentTypeMap).forEach((key) => {
        paymentTypeMap[key] = paymentTypeMap[key].reduce((prev, {CurrencyCode, DiscountAmount, Amount, bulkChargeType, QuantityPricingTypeCode, StartQuantity, EndQuantity, BillerRuleConfigurationId}) => {
            const amount = getBriAmountTotal(
                Amount,
                itemTotalQuantity,
                itemIncrementalQuantity,
                bulkDetails,
                !!bulkChargeType,
                QuantityPricingTypeCode,
                StartQuantity,
                EndQuantity,
                BillerRuleConfigurationId,
                adHocDetails
            );

            const discountAmount = getBriAmountTotal(
                DiscountAmount,
                itemTotalQuantity,
                itemIncrementalQuantity,
                bulkDetails,
                !!bulkChargeType,
                QuantityPricingTypeCode,
                StartQuantity,
                EndQuantity,
                BillerRuleConfigurationId,
                adHocDetails,
                true
            );

            return Object.assign(prev, {
                CurrencyCode: CurrencyCode || defaultCurrencyCode,
                sum: prev.sum + amount,
                discount: prev.discount + discountAmount,
                bulkChargeType: prev.bulkChargeType || bulkChargeType
            });
        }, {
            sum: 0,
            discount: 0,
            CurrencyCode: defaultCurrencyCode,
            bulkChargeType: null
        });
    });
    return paymentTypeMap;
};
const getDepositInformation = (deposits, defaultCurrencyCode) => {
    return deposits.reduce((prev, {TotalAmount}) => {
        return Object.assign(prev, {
            sum: prev.sum + TotalAmount
        });
    }, {
        sum: 0,
        CurrencyCode: defaultCurrencyCode
    });
};
const getPricingSchedules = (bris) => {
    return bris.reduce((previous, {name, pricingSchedule}) => {
        return Object.assign(previous, {
            [name]: pricingSchedule
        });
    }, {});
};
const hasPricingSchedule = (bulkDetails) => {
    return bulkDetails.some((details) => {
        return details.StartDate || details.EndDate;
    });
};
const getOrderItemDisplayItems = (orderId, {Items}, periodTypes, bulkChargeTypes, instancePropertiesLabels, orderServiceDetails) => {
    const dbssOfferItems = Items.filter(({PricingPlan: {BillerRuleInstanceThumbnails = []}, Product}) => {
        return BillerRuleInstanceThumbnails.length && Product.ProductClassification !== PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE;
    });
    const dbssServiceFeatures = Items.filter(({PricingPlan: {BillerRuleInstanceThumbnails = []}, Product}) => {
        return BillerRuleInstanceThumbnails.length && Product.ProductClassification === PRODUCT_CLASSIFICATIONS.SERVICE_FEATURE;
    });
    const ott = Items.filter(({PricingPlan: {BillerRuleInstanceThumbnails = []}}) => {
        return !BillerRuleInstanceThumbnails.length;
    });
    const getActionItems = memoize((item) => {
        const actionsItems = [];

        if (item.IsBulkWithServices) {
            actionsItems.push({
                id: ACTIONS_ITEMS.VIEW_SERVICES_ID,
                title: i18n.translate(CustomerCareLocaleKeys.ORDER_HISTORY.ACTION_ITEMS.VIEW_SERVICES)
            });
        }
        if (item.hasAttributes && !item.IsBulkWithServices) {
            actionsItems.push({
                id: ACTIONS_ITEMS.VIEW_SERVICES_ATTRIBUTES_ID,
                title: i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.VIEW_SERVICE_ATTRIBUTES)
            });
        }
        if (item.IsBulkWithServices) {
            actionsItems.push({
                id: ACTIONS_ITEMS.IMPORT_SERVICE_ATTRIBUTES_ID,
                title: i18n.translate(CustomerCareLocaleKeys.ORDER_HISTORY.ACTION_ITEMS.IMPORT_SERVICE_ATTRIBUTES)
            }, {
                id: ACTIONS_ITEMS.EXPORT_SERVICES_TEMPLATE_ID,
                title: i18n.translate(CustomerCareLocaleKeys.ORDER_HISTORY.ACTION_ITEMS.EXPORT_SERVICES_TEMPLATE)
            });
        }
        if (item.hasPricingSchedule) {
            actionsItems.push({
                id: ACTIONS_ITEMS.VIEW_PRICING_SCHEDULE_ID,
                title: i18n.translate(CustomerCareLocaleKeys.ORDER_HISTORY.ACTION_ITEMS.VIEW_PRICING_SCHEDULE)
            });
        }

        return actionsItems;
    });
    const getServiceDetails = (itemId) => {
        return find(propEq(itemId, 'OrderItemId'))(orderServiceDetails[orderId]) || null;
    };
    const mappedOTT = ott.map(({BulkExtractItemDetail, Id, IsBulkWithServices, OfferingName, OrderedOfferingId, OfferingInstanceId, OfferingId, Product, PricingPlan, GrossAmount, Amount, ReturnAmount, ServiceAttributeValues, WorkflowInstance, WorkflowInstanceId, InstanceProperties}) => {
        const instanceProperties = {};
        forEachObjIndexed((value, key) => {
            if (!isNil(instancePropertiesLabels) && !isNil(instancePropertiesLabels[key])) {
                instanceProperties[instancePropertiesLabels[key]] = value;
            } else {
                instanceProperties[key] = value;
            }
        }, InstanceProperties || {});

        const details = getServiceDetails(Id);
        let numAttributes = 0;
        if (details) {
            numAttributes = details.AvailableServiceAttributes && details.AvailableServiceAttributes.length + details.ServiceAttributeValues.length;
        }
        const mappedItem = {
            Amount,
            BulkExtractItemDetail,
            CurrencyCode: PricingPlan.Currency,
            displayName: `${Product.Name}: ${PricingPlan.Name}`,
            GrossAmount,
            hasAttributes: !!numAttributes,
            Id,
            instanceProperties: InstanceProperties ? instanceProperties : undefined,
            IsBulkWithServices,
            isOTT: true,
            OfferingId,
            OfferingInstanceId,
            OfferingName,
            OrderedOfferingId,
            pricingPlanId: PricingPlan.Id,
            pricingPlanName: PricingPlan.Name,
            productId: Product.Id,
            productName: Product.Name,
            quantity: 1,
            ReturnAmount,
            ServiceAttributeValues,
            WorkflowInstance,
            WorkflowInstanceId
        };

        return Object.assign({}, mappedItem, {
            actionItems: getActionItems(mappedItem)
        });
    });

    const getOfferingOptionTotalQuantity = (OfferingOptionPriceId) => {
        const items = filter(propEq(OfferingOptionPriceId, 'OfferingOptionPriceId'))(dbssOfferItems);
        return items.length;
    };
    const getOfferingOptionIncrementalQuantity = (OfferingOptionPriceId, Id) => {
        return findIndex(propEq(Id, 'Id'), filter(propEq(OfferingOptionPriceId, 'OfferingOptionPriceId'))(dbssOfferItems)) + 1;
    };
    const getMappedDBSS = (dbssItem) => {
        const {AdHocOverrideDetails, BulkExtractItemDetail, Discounts, Id, IsBulkWithServices, Deposits = [],
            OfferingName, OrderedOfferingId, OfferingId, OfferingInstanceId, Product, PricingPlan, ServiceAttributeValues,
            OrderItemBillerRuleDetails = [], WorkflowInstance, WorkflowInstanceId, OfferingOptionPriceId, ServiceFeatureProductAssociation, Status} = dbssItem;
        const details = getServiceDetails(Id);
        let numAttributes = 0;
        if (details) {
            numAttributes = details.AvailableServiceAttributes && details.AvailableServiceAttributes.length + details.ServiceAttributeValues.length;
        }
        const dbssBRIs = PricingPlan && PricingPlan.PricingPlanBillerRuleInstances ?
            [...PricingPlan.PricingPlanBillerRuleInstances.RecurringBillerRuleInstances,
                ...PricingPlan.PricingPlanBillerRuleInstances.OneTimeBillerRuleInstances] : [];
        const mergedDBSSbris = PricingPlan.BillerRuleInstanceThumbnails.map((bri) => {
            const foundBRI = dbssBRIs.find((dbssBRI) => {
                return dbssBRI.Id === bri.Id;
            }) || EMPTY_OBJECT;
            return {
                ...foundBRI,
                ...bri
            };
        });
        const bris = getMappedBRIs(mergedDBSSbris, OrderItemBillerRuleDetails, periodTypes, bulkChargeTypes);
        const itemTotalQuantity = getOfferingOptionTotalQuantity(OfferingOptionPriceId);
        const itemIncrementalQuantity = getOfferingOptionIncrementalQuantity(OfferingOptionPriceId, Id);
        const mappedItem = {
            BulkExtractItemDetail,
            depositInformation: getDepositInformation(Deposits, PricingPlan.Currency),
            Discounts,
            displayName: `${Product.Name}: ${PricingPlan.Name}`,
            hasAttributes: !!numAttributes,
            hasPricingSchedule: hasPricingSchedule(OrderItemBillerRuleDetails),
            Id,
            LifeCycleId: dbssItem.LifeCycleId || '',
            IsBulkWithServices,
            OfferingId,
            OfferingInstanceId,
            OfferingName,
            OrderedOfferingId,
            paymentTypeMap: getPaymentTypeMap(bris, PricingPlan.Currency, itemTotalQuantity, itemIncrementalQuantity, OrderItemBillerRuleDetails, AdHocOverrideDetails),
            pricingPlanId: PricingPlan.Id,
            pricingPlanName: pathOr(PricingPlan.Name, ['InvoiceText'], AdHocOverrideDetails),
            pricingSchedules: getPricingSchedules(bris),
            productId: Product.Id,
            productName: Product.Name,
            quantity: OrderItemBillerRuleDetails.length ? OrderItemBillerRuleDetails[0].Quantity : 1,
            ServiceAttributeValues,
            ServiceFeatureProductAssociation,
            Status,
            WorkflowInstance,
            WorkflowInstanceId
        };

        return Object.assign({}, mappedItem, {
            actionItems: getActionItems(mappedItem)
        });
    };

    const mappedDBSSOfferItems = dbssOfferItems.map(getMappedDBSS);
    const mappedDBSSServiceFeatures = dbssServiceFeatures.length ?
        groupBy((serviceFeature) => {
            return serviceFeature.ServiceFeatureProductAssociation.ServiceIdentifierValue;
        })(dbssServiceFeatures.map(getMappedDBSS)) : null;
    const groupedMappedItems = mappedOTT.length || mappedDBSSOfferItems.length ?
        groupBy((item) => {
            return item.OfferingInstanceId || item.OrderedOfferingId;
        }, ([...mappedOTT, ...mappedDBSSOfferItems])) : null;


    return {
        Items: groupedMappedItems,
        ServiceFeatures: mappedDBSSServiceFeatures
    };
};

export const CurrentOrderItemDisplayItemsSelector = createSelector(
    [
        ConvergentBillerOrderDetailsSelector,
        MetadataCodeLoadedSelector(CODES.PeriodType),
        MetadataCodeTypeSelector(CODES.PeriodType),
        MetadataCodeLoadedSelector(CODES.BulkChargeType),
        MetadataCodeTypeSelector(CODES.BulkChargeType),
        AvailableProductsInstancePropertiesLabelSelector,
        ConvergentBillerOrderServiceDetailsSelector
    ],
    (currentOrder, arePeriodTypesLoaded, periodTypes, areBulkTypesLoaded, bulkTypes, instancePropertiesLabels, orderServiceDetails) => {
        return currentOrder && arePeriodTypesLoaded && areBulkTypesLoaded && orderServiceDetails ? clone(getOrderItemDisplayItems(currentOrder.Id, currentOrder, periodTypes, bulkTypes, instancePropertiesLabels, orderServiceDetails)) : {};
    }
);

export const CurrentOrderItemsBulkStatusViewModelSelector = createSelector(
    [CurrentOrderItemDisplayItemsSelector],
    (currentOrderItemDisplayItems) => {
        const orderItemsBulkStatuses = {
            statuses: [],
            summary: {}
        };
        const mapBulkServices = (currentOrderOffers) => {
            currentOrderOffers.forEach((offerItems) => {
                offerItems.forEach((item) => {
                    if (item.IsBulkWithServices && item.BulkExtractItemDetail &&
                        IMPORT_BULK_SERVICE_ATTRIBUTES_JOB_STATUSES.NOT_STARTED !==
                        item.BulkExtractItemDetail.BulkExportStatus) {
                        orderItemsBulkStatuses.statuses.push({
                            glyph: 'file-text',
                            path: item.BulkExtractItemDetail.FileLocation ?
                                parseFilenameFromPath(item.BulkExtractItemDetail.FileLocation) : '',
                            pricingPlanId: item.pricingPlanId,
                            pricingPlanName: item.displayName,
                            statusId: item.BulkExtractItemDetail.BulkExportStatus,
                            title: item.displayName
                        });
                    }
                });
            });
        };

        if (currentOrderItemDisplayItems.Items) {
            mapBulkServices(values(currentOrderItemDisplayItems.Items));
        }
        if (currentOrderItemDisplayItems.ServiceFeatures) {
            mapBulkServices(values(currentOrderItemDisplayItems.ServiceFeatures));
        }

        orderItemsBulkStatuses.summary = groupBy((itemStatus) => {
            return itemStatus.statusId;
        }, orderItemsBulkStatuses.statuses);

        return orderItemsBulkStatuses;
    }
);

const OrderItemSelectedToDisplayServiceAttributesFor = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller.orderItemToDisplayServiceAttributesFor;
    }
);
const getKeyValueObjectForServiceAttributes = (orderItems, orderItemId) => {
    const response = {};
    const order = orderItems.find((item) => {
        return item.Id === orderItemId;
    }) || {};
    (order.ServiceAttributeValues || []).forEach(({ServiceAttributeName, Value}) => {
        response[ServiceAttributeName] = Value;
    });
    return response;
};
export const OrderItemToDisplayServiceAttributesFor = createSelector(
    [OrderItemSelectedToDisplayServiceAttributesFor, ConvergentBillerOrderDetailsSelector],
    (orderItemId, currentOrderDetails) => {
        return orderItemId && currentOrderDetails ? getKeyValueObjectForServiceAttributes(currentOrderDetails.Items, orderItemId) : null;
    }
);

const getKeyValueObjectForAdditionalProperties = (properties) => {
    const response = {};
    properties.forEach((property) => {
        response[property.Name] = property.Values.join('; ');
    });
    return response;
};

export const AdditionalAttributesForOrder = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return currentOrderDetails && currentOrderDetails.AdditionalProperties ? getKeyValueObjectForAdditionalProperties(currentOrderDetails.AdditionalProperties) : null;
    }
);
export const OrderWorkflowSummarySelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return currentOrderDetails && currentOrderDetails.Workflows ? currentOrderDetails.Workflows.map((item) => {
            return item
                .set('DeliveryCapabilityName', item.DeliveryCapabilityName || i18n.translate(CustomerCareLocaleKeys.ORDER_DETAILS.DEFAULT_DELIVERY_CAPABILITY_NAME))
                .set('workflowState', WorkflowStateEnum[item.CurrentStateCode]);
        }).asMutable({
            deep: true
        }) : EMPTY_ARRAY;
    });
export const IsApplyingCreditSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.isApplyingCredit : false;
    }
);

export const ApplyCreditErrorSelector = createSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller ? convergentBiller.applyCreditError : null;
    }
);

export const CurrentOrderServiceIdentifiersSelector = createSelector(
    [
        ConvergentBillerOrderServiceDetailsSelector,
        CurrentOrderIdSelector
    ],
    (convergentBillerOrderServiceDetails, currentOrderId) => {
        let serviceAttributeValues = [];
        const orderItems = pathOr(null, [currentOrderId], convergentBillerOrderServiceDetails);
        if (orderItems) {
            serviceAttributeValues = flatten(pluck('ServiceAttributeValues')(orderItems));
        }

        return serviceAttributeValues.filter((serviceAttributeValue) => {
            return serviceAttributeValue && serviceAttributeValue.IsServiceIdentifier;
        });
    }
);

export const OrderContractDetailsSelector = createSelector(
    [ConvergentBillerOrderDetailsSelector],
    (currentOrderDetails) => {
        return (currentOrderDetails && currentOrderDetails.Items || EMPTY_ARRAY).reduce((orderContractDetails, item) => {
            return item.OrderContractInstance ? item.OrderContractInstance : orderContractDetails;
        }, null);
    }
);

export const OrderContractExternalContractIdSelector = createSelector(
    [OrderContractDetailsSelector],
    (orderContractDetails) => {
        return pathOr(i18n.translate(CustomerCareLocaleKeys.EMPTY_VALUE_NA),
            ['ExternalContractId'], orderContractDetails);
    }
);

export const CurrentCappedServicesSelector = createSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (subscriberSummary) => {
        if (subscriberSummary && 'CappedServices' in subscriberSummary) {
            const {CappedServices: cappedServices} = subscriberSummary;
            if (cappedServices) {
                return cappedServices;
            }
        }

        return EMPTY_OBJECT;
    }
);

export const CurrentCappedAccountSelector = createSelector(
    ConvergentBillerSubscriberSummarySelector,
    (subscriberSummary) => {

        if (!subscriberSummary || !subscriberSummary.AccountSummaries) {
            return EMPTY_ARRAY;
        }
        const caps = pluck('UsageCaps')(subscriberSummary.AccountSummaries.asMutable({
            deep: true
        }));

        return caps && caps[0] ? caps[0].filter((cap) => {
            return cap && cap.Breached;
        }) : null;
    }
);

export const SelectedOfferAccountDetailsSelector = createSelector(
    [CurrentConvergentBillerSelector, RouteParams],
    (convergentBiller, routeParams) => {
        const offerings = pathOr([], ['accountDetails', 'offerings'], convergentBiller);
        return offerings.find((offering) => {
            return offering.OfferingId === routeParams.offeringId;
        });
    }
);

export const PeriodTypeOptionsSelector = createImmutableSelector(
    [MetadataOptionsForCodeValuesSelector(CODES.PeriodType)],
    (codeTableValues) => {
        return codeTableValues.filter((pt) => {
            return pt.value !== PERIOD_TYPES.HOURLY && pt.value !== PERIOD_TYPES.YEARLY;
        });
    }
);

export const SelectedOfferType = createSelector(
    [SelectedOfferAccountDetailsSelector],
    (selectedOfferAccountDetailsSelector) => {
        return selectedOfferAccountDetailsSelector ? selectedOfferAccountDetailsSelector.OfferType : null;
    }
);

export const CreditLimitSummarySelector = createImmutableSelector(
    [ConvergentBillerSubscriberSummarySelector],
    (convergentBillerSubscriberSummary) => {
        return convergentBillerSubscriberSummary ? convergentBillerSubscriberSummary.CreditLimitSummary : EMPTY_OBJECT;
    }
);

export const CreditLimitBreachedSelector = createImmutableSelector(
    [CreditLimitSummarySelector],
    (creditLimitSummary) => {
        return creditLimitSummary ? creditLimitSummary.CreditLimitBreached : false;
    }
);

export const IsUpdatingDashboardEntitlementStatusSelector = createImmutableSelector(
    [ConvergentBillerSelector],
    (convergentBiller) => {
        return convergentBiller.isUpdatingEntitlementStatus;
    }
);

export const SelectedOfferingAssignedPaymentInstrumentSelector = createImmutableSelector(
    [ConvergentBillerSubscriberOfferingSummariesSelector, RouteParams],
    (offeringSummaries, routeParams) => {
        if (routeParams.offeringInstanceId) {
            const offering = (offeringSummaries || []).find((offeringSummary) => {
                return offeringSummary.OfferingInstanceId === routeParams.offeringInstanceId;
            });
            return pathOr(false ['PaymentInstrument', 'Id'], offering) ? offering.PaymentInstrument : null;
        }
        return null;
    }
);
