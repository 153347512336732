import Immutable from 'seamless-immutable';
import {ACCEPTING_FOR} from '../components/createCustomerPopup/create.customer.popup.constants';

export const INITIAL_STATE = Immutable({
    AdditionalProperties: [],
    AccessCountry: null,
    BillCycle: null,
    BirthDate: null,
    BusinessPhone: null,
    DriversLicenseNumber: null,
    DriversLicenseState: null,
    Email: null,
    ExternalReference: null,
    FirstName: null,
    Gender: null,
    HierarchyName: null,
    HomeCountry: null,
    HomePhone: null,
    IncomeLevelType: null,
    IsMinor: null,
    Language: null,
    LastName: null,
    LeadSourceType: null,
    Login: null,
    MiddleName: null,
    MobilePhone: null,
    NotificationCategoryPreferences: [],
    PreferredName: null,
    Race: null,
    SecurityAnswer: null,
    SecurityQuestion: null,
    SecurityQuestionCustom: null,
    setEditAdditionalProp:[],
    shoppingCart: null,
    shoppingCartError: null,
    Ssn: null,
    Status: '1', /* active */
    SubscriberCurrency: null,
    SubscriberTypeCode: null,
    SubscriberTypeDetails: {
        AccountingMethod: null,
        AdjustmentDueDays: null,
        PaymentDueDays: null,
        SuppressLatePaymentFees: false
    },
    SubtenantId: null,
    SuffixName: null,
    TaxExempt: null,
    TextScrubber: null,
    TermsAndConditionsAccepted: new Date(),
    Title: null,
    wholesaleFlag: false,
    /* address section */
    addressLine1: null,
    addressLine2: null,
    city: null,
    country: null,
    postalCode: null,
    stateRegionProvince: null,
    ConsentAcceptingFor: ACCEPTING_FOR.MYSELF,
    ConsentLegalGuardianEmail: null,
    ConsentSendCopyOfTerms: null,
    ConsentAcceptingMandatoryTerms: {
        IsAccepted: null,
        Terms: []
    },
    ConsentAcceptingOptionalTerms: [],
    candidateBillCycles: [],
});
