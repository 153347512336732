import {BRI_ACTIVATION_STATUS} from 'invision-core/src/constants/pricing.plan.constants';
import {NON_BULK_SERVICE_STATUSES} from 'invision-core/src/constants/status.constants';
import {EMPTY_ARRAY} from 'invision-core/src/constants/common.constants';
import i18n from 'invision-core/src/components/i18n/i18n';
import {BILLER_RULE_INSTANCE_TYPE} from '../../customercare.constants';
import {getDiscountTooltipModel} from '../../components/shared/tooltipTemplates/discount.tooltip.helper';
import CustomerCareKeys from '../../locales/keys';
import moment from 'moment';
import pathOr from 'ramda/src/pathOr';

export const getActiveBillerTypeAmounts = (billerTypeAmounts=[]) => {
    return billerTypeAmounts.filter((billerTypeAmount) => {
        return (billerTypeAmount.Status === BRI_ACTIVATION_STATUS.ACTIVE &&
            (billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.RECURRING ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT ||
                billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.ONETIME)) ||
            billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.TRIGGER ||
            billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.EARLY_TERMINATION_FEE ||
            billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.CUSTOM;
    });
};

export const getActiveBillerTypePrices = (billerTypeAmounts=[]) => {
    return billerTypeAmounts.filter((billerTypeAmount) => {
        return ((billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.RECURRING ||
                    billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.ENTITLEMENT ||
                    billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.ONETIME)) ||
            billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.TRIGGER ||
            billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.EARLY_TERMINATION_FEE ||
            billerTypeAmount.Type === BILLER_RULE_INSTANCE_TYPE.CUSTOM;
    });
};

export const getFormattedBillerRulePrices = (billerTypeAmounts=[], billerRulePrices=[]) => {
    const billerRulerPricesDictionary = billerRulePrices.reduce((acc, brp) => {
        acc[brp.BillerRuleConfigurationId] = brp;
        return acc;
    }, {});

    return billerTypeAmounts.map((bta) => {
        if (billerRulerPricesDictionary[bta.BillerRuleConfigurationId]) {
            const {TotalAmount, BulkType, Quantity, ChargesFromRateSheet, StartDate} = billerRulerPricesDictionary[bta.BillerRuleConfigurationId];
            return {
                ...bta,
                TotalAmount,
                BulkType,
                Quantity,
                ChargesFromRateSheet,
                StartDate
            };
        }
        return bta;
    });
};

export const getDiscountAndBriDetails = (option, currencyCode, discountsMetadataMap) => {
    const discountAndBriInfo = [];
    const discountsInBri = [];
    const discountAndBriHasMap = {};
    let futureDiscountIndex = 0;
    let pastDiscountIndex = 0;
    const isBulk = option.BulkQuantity && option.BulkQuantity > 1;
    const activeBillerTypeAmounts = getActiveBillerTypeAmounts(option.BillerTypeAmounts);
    const activeBillerRulePrices = getActiveBillerTypePrices(option.BillerRulePrices);
    const amounts = isBulk ? getFormattedBillerRulePrices(activeBillerTypeAmounts, activeBillerRulePrices) : activeBillerTypeAmounts;
    const discounts = option.Discounts ? option.Discounts.filter((discount) => {
        return discount.IsActive;
    }) : EMPTY_ARRAY ;

    let futureDiscountsLength = 0;
    let pastDiscountsLength = 0;
    discounts.forEach((discount) => {
        (discount.StartDate && moment().diff(discount.StartDate, 'minutes') < 0) ? ++futureDiscountsLength : ++pastDiscountsLength;
    });

    discounts.forEach((discount) => {
        const discountsBri = (discount.StartDate && moment().diff(discount.StartDate, 'minutes') < 0) ?
            getDiscountTooltipModel(discount, currencyCode, discountsMetadataMap,
                discount.BillerRuleConfigurationId, ++futureDiscountIndex, undefined, futureDiscountsLength, pastDiscountsLength) :
            getDiscountTooltipModel(discount, currencyCode, discountsMetadataMap,
                discount.BillerRuleConfigurationId, undefined, ++pastDiscountIndex, futureDiscountsLength, pastDiscountsLength);
        discountsInBri.push(discountsBri);
        if (!discountAndBriHasMap[discount.BillerRuleConfigurationId]) {
            discountAndBriHasMap[discount.BillerRuleConfigurationId] = [];
            discountAndBriHasMap[discount.BillerRuleConfigurationId].push(discountsBri);
        } else {
            discountAndBriHasMap[discount.BillerRuleConfigurationId].push(discountsBri);
        }
    });

    discountAndBriInfo.push({
        briDetails: amounts,
        discountDetails: discountsInBri,
        pricingPlanId: option.PricingPlanId,
        discountAndBriHasMap,
        hasBillerTypeAmounts: !!amounts.length
    });
    return discountAndBriInfo;
};

export const getServiceMoreActionMenuItems = (serviceStatus, isEditable, hasSuspendResumeAccess, hasUpdateServiceLifecycleStatusAccess) => {
    const moreMenuItems = {};
    const isServiceSuspended = serviceStatus === NON_BULK_SERVICE_STATUSES.SUSPENDED;
    const isPendingSuspension = serviceStatus === NON_BULK_SERVICE_STATUSES.PENDING_SUSPENSION;

    moreMenuItems[CustomerCareKeys.MANAGE_ADD_ONS] = {
        id: CustomerCareKeys.MANAGE_ADD_ONS,
        title: i18n.translate(CustomerCareKeys.MANAGE_ADD_ONS),
        isLocked: isServiceSuspended || !isEditable,
        disabled: isServiceSuspended || !isEditable
    };

    moreMenuItems[CustomerCareKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE] = {
        id: CustomerCareKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE,
        title: i18n.translate(CustomerCareKeys.CUSTOMER_DASHBOARD.FRIENDLY_NAME.MODAL_TITLE)
    };

    if (hasSuspendResumeAccess) {
        if (serviceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            moreMenuItems[CustomerCareKeys.SUSPEND_SERVICE] = {
                id: CustomerCareKeys.SUSPEND_SERVICE,
                title: i18n.translate(CustomerCareKeys.SUSPEND_SERVICE),
                isLocked: !isEditable,
                disabled: !isEditable
            };
        }

        if (isServiceSuspended || isPendingSuspension) {
            moreMenuItems[CustomerCareKeys.RESUME_SERVICE] = {
                id: CustomerCareKeys.RESUME_SERVICE,
                title: i18n.translate(CustomerCareKeys.RESUME_SERVICE)
            };
        }
    }

    if (hasUpdateServiceLifecycleStatusAccess) {
        if (serviceStatus === NON_BULK_SERVICE_STATUSES.ACTIVE) {
            moreMenuItems[CustomerCareKeys.UPDATE_SERVICE_LIFECYCLE_STATUS] = {
                id: CustomerCareKeys.UPDATE_SERVICE_LIFECYCLE_STATUS,
                title: i18n.translate(CustomerCareKeys.UPDATE_SERVICE_LIFECYCLE_STATUS),
                isLocked: !isEditable,
                disabled: !isEditable
            };
        }
    }

    return moreMenuItems;
};
